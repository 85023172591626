import {gql} from "@apollo/client";
import {
	CONTEST_FRAGMENT,
	CUSTOM_FIELD_FRAGMENT,
	GAME_INFO_FRAGMENT,
	LEAGUE_FRAGMENT,
	SEASON_FRAGMENT,
	USER_FRAGMENT,
	RANKS_FRAGMENT,
	LEAGUES_TO_JOIN_FRAGMENT,
	RANK_USER_FRAGMENT,
	QUESTION_FRAGMENT,
	ANSWER_FRAGMENT,
	LEAGUE_USERS_FRAGMENT,
} from "data/graphql/fragments";
import {PublishStatus} from "data/enums";

export const GET_SEASONS = gql`
	${SEASON_FRAGMENT}

	query GetSeasons($gameID: Int!) {
		seasons(where: {game: {id: {_eq: $gameID}}}) {
			...Season
		}
	}
`;

export const GET_CONTESTS = gql`
	${CONTEST_FRAGMENT}

	query GetContests($seasonID: Int!) {
		contestsCached(
			where: {
				season: { id: {_eq: $seasonID} } 
				publishStatus: {_eq: "${PublishStatus.PUBLISHED}"}
			}
			sort: {
				dateStart: ASC
			}
		) {
			...Contest
		}
	}
`;

export const GET_QUESTIONS = gql`
	${QUESTION_FRAGMENT}

	query GetQuestions($contestID: Int!) {
		questionsByContestId(contestId: $contestID) {
			...Question
		}
	}
`;

export const GET_ANSWERS = gql`
	${ANSWER_FRAGMENT}

	query GetAnswers($contestID: Int!) {
		answers(where: {question: {contest: {id: {_eq: $contestID}}}}) {
			...QuestionAnswer
		}
	}
`;

export const GET_REGISTERED_USER = gql`
	${USER_FRAGMENT}

	query GetRegisteredUser($gameID: Int!) {
		login(gameId: $gameID) {
			...User
		}
	}
`;

export const GET_CUSTOM_FIELDS = gql`
	${CUSTOM_FIELD_FRAGMENT}

	query GetCustomFields($gameID: Int!) {
		customFields(where: {game: {_eq: $gameID}}) {
			...CustomField
		}
	}
`;

export const CHECK_USERNAME = gql`
	query CheckUsername($gameID: Int!, $username: String!) {
		checkUsername(gameId: $gameID, username: $username)
	}
`;

export const CHECK_USERNAME_AUTHORIZED = gql`
	query CheckUsernameAuthorized($gameID: Int!, $username: String!) {
		checkUsernameAuthorized(gameId: $gameID, username: $username)
	}
`;

export const GET_GAME_INFO = gql`
	${GAME_INFO_FRAGMENT}

	query GetGameInfo($gameID: Int!) {
		game(id: $gameID) {
			...GameInfo
		}
	}
`;

/**
 * Leagues
 */
export const GET_MY_LEAGUES = gql`
	${LEAGUE_FRAGMENT}
	query GetMyLeagues {
		myPredictorLeagues {
			...League
		}
	}
`;

export const GET_LEAGUES_TO_JOIN = gql`
	${LEAGUES_TO_JOIN_FRAGMENT}
	query GetLeaguesToJoin($pageNumber: Int!, $pageSize: Int!, $query: String!) {
		showForJoinPredictorLeague(
			where: {query: $query, pageNumber: $pageNumber, pageSize: $pageSize}
		) {
			...LeaguesToJoin
		}
	}
`;

export const GET_LEAGUE = gql`
	${LEAGUE_FRAGMENT}
	query getPredictorLeague($leagueId: Int!) {
		getPredictorLeague(leagueId: $leagueId) {
			...League
		}
	}
`;

export const GET_LEAGUE_USERS = gql`
	${LEAGUE_USERS_FRAGMENT}
	query getPredictorLeagueUsers($leagueId: Int!, $pageSize: Int!, $pageNumber: Int!) {
		getPredictorLeagueUsers(
			where: {leagueId: $leagueId, pageSize: $pageSize, pageNumber: $pageNumber}
		) {
			...LeagueUsers
		}
	}
`;

export const GET_LEAGUE_LADDER = gql`
	${RANKS_FRAGMENT}

	query getLeagueLadder($leagueId: Int, $contestId: Int, $pageNumber: Int!, $pageSize: Int!) {
		getLeagueLadder(
			where: {
				leagueId: $leagueId
				pageNumber: $pageNumber
				pageSize: $pageSize
				contestId: $contestId
			}
		) {
			...Ranks
		}
	}
`;

export const GET_USER_RANK = gql`
	${RANK_USER_FRAGMENT}

	query getUserLeagueRank($leagueId: Int, $contestId: Int) {
		getUserLeagueRank(where: {leagueId: $leagueId, contestId: $contestId}) {
			...RankUser
		}
	}
`;
