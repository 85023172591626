import React, {useCallback, useState} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {FormControl, TextField} from "@mui/material";
import {ICustomFieldProps} from "data/types/global";
import {Bindings} from "data/constants/bindings";
import {FORM_VALIDATION_ELEMENT_CLASSNAME} from "data/constants";

export const Numeric: React.FC<ICustomFieldProps> = (props) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const {id, type, description, data, name, code, onChange, error, defaultValue, disabled} =
		props;
	const elementID = `${type}-custom-field-${id}`;
	const isRequired = data?.required || false;
	const fieldName = description ? i18n.findValueByLocale(name) : "";
	const fieldDescription = description ? i18n.findValueByLocale(description) : "";
	const [value, setValue] = useState(defaultValue);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value.replace(/[^0-9]/g, "");
			setValue(value);
			onChange({id, value, name: code});
		},
		[id, code, onChange]
	);

	return (
		<FormControl>
			<TextField
				id={elementID}
				disabled={disabled}
				onInput={handleChange}
				value={value}
				label={fieldName}
				placeholder={fieldName}
				type="number"
				name={code}
				inputProps={{
					min: data.from,
					max: data.to,
					pattern: `[0-9]{${data.from || 0},${data.to || ""}}`,
					inputMode: "numeric",
					className: FORM_VALIDATION_ELEMENT_CLASSNAME,
				}}
				required={isRequired}
				error={Boolean(error)}
				helperText={error ? error : fieldDescription}
			/>
		</FormControl>
	);
};
