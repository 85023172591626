import {ApolloError} from "@apollo/client";
import {ModalType, RequestState} from "data/enums";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, action, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

interface IInit {
	leagueId: number;
}

export interface ISettingsLeagueFormController extends ViewController<IInit> {
	i18n: ILocalizationStore;

	get league(): ILeagueFragment | undefined;
	get startContest(): number | undefined;
	get allContests(): IContestFragment[];
	get isUpdateDisabled(): boolean;
	get isLoadedContests(): boolean;
	get getUpdateState(): RequestState;
	get getLeaveState(): RequestState;

	setStartRound(round: number): void;
	updateLeague(payload: IUpdatePredictorLeagueMutationVariables): Promise<void>;
	markHasChanges(): void;
}

@injectable()
export class SettingsLeagueFormController implements ISettingsLeagueFormController {
	private _league!: ILeagueFragment | undefined;
	private _startContest: number | undefined;

	@observable _hasChanges: boolean = false;
	@observable _requestStateUpdate: RequestState = RequestState.IDLE;
	@observable _requestStateLeave: RequestState = RequestState.IDLE;
	@observable _requestStateContests: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.GameplayStore) private _gamePlayStore: IGameplayStore
	) {
		makeAutoObservable(this);
	}

	get league() {
		return this._league;
	}

	get isUpdateDisabled() {
		return !this._hasChanges;
	}

	get getUpdateState(): RequestState {
		return this._requestStateUpdate;
	}

	get isLoadedContests(): boolean {
		return this._requestStateContests === RequestState.SUCCESS;
	}

	get getLeaveState(): RequestState {
		return this._requestStateLeave;
	}

	get startContest() {
		return this._startContest;
	}

	get allContests() {
		return this._gamePlayStore.allContests;
	}

	@action
	async updateLeague(payload: IUpdatePredictorLeagueMutationVariables): Promise<void> {
		this._requestStateUpdate = RequestState.PENDING;

		await this._leaguesStore
			.updatePredictorLeague(payload)
			.then((_) => {
				this._requestStateUpdate = RequestState.SUCCESS;
				this._hasChanges = false;
			})
			.catch((err) => {
				trackSentryErrors(err, {}, "league update settings");
				this._requestStateUpdate = RequestState.ERROR;
				this._modalsStore.showModal(ModalType.ERROR, {
					message: (err as ApolloError).message,
				});
			});
	}

	setStartRound(round: number) {
		this.markHasChanges();
		this._startContest = round;
	}

	markHasChanges() {
		this._hasChanges = true;
	}

	async init(params: IInit): Promise<void> {
		this._league = this._leaguesStore.getLeagueById(params.leagueId);

		this._startContest = this._league?.startContest?.id;
		await this._gamePlayStore.requestContestsSafety();

		runInAction(() => {
			this._requestStateContests = RequestState.SUCCESS;
		});
	}

	dispose(): void {
		return;
	}
}
