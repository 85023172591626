import React from "react";
import {ButtonPrimary, ModalCloseButton} from "views/components/buttons/buttons.component";
import CloseIcon from "@mui/icons-material/Close";
import {IconSuccess} from "views/components/icons/icon_success";
import {Modal} from "@mui/material";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {Bindings} from "data/constants/bindings";
import {isEqual} from "lodash";
import {ModalType} from "data/enums";
import styled from "@emotion/styled";
import {ModalBox, ModalContent} from "views/components/modals/modal_common.component";
import {observer} from "mobx-react";

const Title = styled.h2`
	font-family: var(--fontFamilyBase);
	color: var(--textColor3);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.2px;
`;

const Text = styled.p`
	font-family: var(--fontFamilyBase);
	color: var(--textColor3);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.02em;
`;

const SubmitButton = styled(ButtonPrimary)`
	height: 52px;
	text-transform: uppercase;
	margin: 14px 0;
	width: 100%;
`;

const SModalBox = styled(ModalBox)`
	max-width: 600px;
`;

const SModalContent = styled(ModalContent)`
	margin: 0 auto;
	max-width: 360px;
	padding: 28px 28px 20px 28px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
`;

const IconWrapper = styled.div`
	margin-top: 32px;
	margin-bottom: 8px;
`;

export const ModalAccountUpdatedComponent: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {visibleModal, i18n, close} = controller;
	const isVisible = isEqual(visibleModal, ModalType.ACCOUNT_UPDATED);

	if (!isVisible) {
		return null;
	}

	return (
		<Modal open={isVisible} onClose={close}>
			<SModalBox>
				<SModalContent>
					<ModalCloseButton onClick={close}>
						<CloseIcon />
					</ModalCloseButton>
					<IconWrapper>
						<IconSuccess />
					</IconWrapper>
					<Title>{i18n.t("account.updated.title", "Account Updated!")}</Title>
					<Text>
						{i18n.t(
							"account.updated.text",
							"You have successfully updated your account."
						)}
					</Text>
					<SubmitButton onClick={close}>
						{i18n.t("password.updated.back", "Back to my account")}
					</SubmitButton>
				</SModalContent>
			</SModalBox>
		</Modal>
	);
});
