import styled from "@emotion/styled";
import {css, Link} from "@mui/material";
import {NavLink} from "react-router-dom";

export const linkStyles = css`
	text-decoration: none;
	outline: none;
	background-image: linear-gradient(currentcolor, currentcolor);
	background-position: 0 100%;
	background-repeat: no-repeat;
	transition: background-size 0.5s ease 0s;
	background-size: 0 1px;
	cursor: pointer;

	&:hover {
		background-size: 100% 1px;
	}

	&[target="_blank"] {
		background-size: 100% 1px;

		&:hover {
			background-size: 0 1px;
		}
	}
`;

export const LinkStyled = styled(Link)`
	${linkStyles};
` as typeof Link;

export const InternalLink = styled(NavLink)`
	${linkStyles};
`;
