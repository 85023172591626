import {retryFailLoad} from "data/utils";
import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {FooterOnlyLayout} from "views/components/layouts/footer_only_layout.component";

const LandingPage = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));

export const SecretGateRootRoutes: React.FC = () => (
	<Routes>
		<Route element={<FooterOnlyLayout />}>
			<Route path="*" element={<LandingPage />} />
		</Route>
	</Routes>
);

export default SecretGateRootRoutes;
