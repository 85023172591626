import {gql} from "@apollo/client";
import {LEAGUE_FRAGMENT, QUESTION_FRAGMENT, USER_FRAGMENT} from "data/graphql/fragments";

export const REGISTER_USER = gql`
	${USER_FRAGMENT}

	mutation registerUser(
		$email: String!
		$gameId: Int!
		$firstName: String!
		$lastName: String!
		$username: String!
		$customFields: [UserCustomFieldAdd!]
		$locale: String!
	) {
		registerUser(
			params: {
				email: $email
				gameId: $gameId
				firstName: $firstName
				lastName: $lastName
				username: $username
				locale: $locale
			}
			customFields: $customFields
		) {
			...User
		}
	}
`;

export const UPDATE_USER = gql`
	${USER_FRAGMENT}

	mutation updateUser(
		$email: String!
		$firstName: String!
		$lastName: String!
		$username: String!
		$customFields: [UserCustomFieldAdd!]
		$isNotificationEnabled: Boolean
	) {
		updateUser(
			params: {
				email: $email
				firstName: $firstName
				lastName: $lastName
				username: $username
				isNotificationEnabled: $isNotificationEnabled
			}
			customFields: $customFields
		) {
			...User
		}
	}
`;

/**
 * Leagues
 */
export const CREATE_LEAGUE = gql`
	${LEAGUE_FRAGMENT}

	mutation addPredictorLeague(
		$privacy: PredictorLeaguePrivacy!
		$leagueName: String!
		$startContest: Int
	) {
		addPredictorLeague(
			params: {startContestId: $startContest, name: $leagueName, privacy: $privacy}
		) {
			...League
		}
	}
`;

export const UPDATE_LEAGUE = gql`
	${LEAGUE_FRAGMENT}

	mutation updatePredictorLeague(
		$leagueId: Int!
		$startContest: Int!
		$privacy: PredictorLeaguePrivacy!
		$leagueName: String!
	) {
		updatePredictorLeague(
			params: {
				id: $leagueId
				startContestId: $startContest
				name: $leagueName
				privacy: $privacy
			}
		) {
			...League
		}
	}
`;

export const LEAVE_LEAGUE = gql`
	mutation leavePredictorLeague($leagueId: Int!) {
		leavePredictorLeague(params: {leagueId: $leagueId}) {
			id
		}
	}
`;

export const JOIN_LEAGUE = gql`
	${LEAGUE_FRAGMENT}
	mutation joinPredictorLeague($leagueCode: String!) {
		joinPredictorLeague(params: {code: $leagueCode}) {
			...League
		}
	}
`;

export const INVITE_TO_LEAGUE = gql`
	mutation invitePredictorLeague($leagueId: Int!, $emails: [PredictorLeagueInvite!]!) {
		invitePredictorLeague(leagueId: $leagueId, params: $emails) {
			id
		}
	}
`;

export const REMOVE_LEAGUE_USER = gql`
	mutation removeUserFromPredictorLeague($leagueId: Int!, $userId: String!) {
		removeUserFromPredictorLeague(params: {leagueId: $leagueId, userId: $userId}) {
			id
		}
	}
`;

export const REMOVE_LEAGUE = gql`
	mutation deletePredictorLeague($id: Int!) {
		deletePredictorLeague(id: $id)
	}
`;

export const SAVE_ANSWER = gql`
	${QUESTION_FRAGMENT}

	mutation saveAnswer($params: [QuestionAnswerAdd!]!) {
		answerQuestions(params: $params) {
			question {
				...Question
			}
		}
	}
`;
