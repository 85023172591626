import {ApolloQueryResult} from "@apollo/client";
import gql from "data/graphql";
import type {IGraphQLClient} from "data/services/graphql/grapql_client_factory.service";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";

export interface IStandingsApiProvider {
	fetch: (
		variables: IGetLeagueLadderQueryVariables
	) => Promise<ApolloQueryResult<IGetLeagueLadderQuery>>;

	fetchUserRank: (
		variables: IGetUserLeagueRankQueryVariables
	) => Promise<ApolloQueryResult<IGetUserLeagueRankQuery>>;
}

@injectable()
export class StandingsApiProvider implements IStandingsApiProvider {
	constructor(@inject(Bindings.GraphQLClient) private _client: IGraphQLClient) {}

	fetch = (variables: IGetLeagueLadderQueryVariables) =>
		this._client.query<IGetLeagueLadderQuery, IGetLeagueLadderQueryVariables>({
			query: gql.queries.GET_LEAGUE_LADDER,
			variables,
		});

	fetchUserRank = (variables: IGetUserLeagueRankQueryVariables) =>
		this._client.query<IGetUserLeagueRankQuery, IGetUserLeagueRankQueryVariables>({
			query: gql.queries.GET_USER_RANK,
			variables,
		});
}
