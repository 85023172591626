import React, {useCallback, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {useViewController} from "data/services/locator/locator_hook.service";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {FormControl, TextField} from "@mui/material";
import {ICustomFieldProps} from "data/types/global";
import {Bindings} from "data/constants/bindings";
import {FORM_VALIDATION_ELEMENT_CLASSNAME} from "data/constants";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const DATE_FORMAT = "MM/DD/YYYY";

export const DateTimeComponent: React.FC<ICustomFieldProps> = (props) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const {id, type, description, data, name, code, onChange, error, disabled} = props;
	const elementID = `${type}-custom-field-${id}`;
	const isRequired = data?.required || false;
	const fieldName = description ? i18n.findValueByLocale(name) : "";
	const fieldDescription = description ? i18n.findValueByLocale(description) : "";
	const [value, setValue] = useState<null | Dayjs>(null);

	const handleChange = useCallback(
		(newDate: Dayjs | null) => {
			setValue(newDate);

			const allowedAgeDate = dayjs().add(-Number(data.from), "years");
			const isValidDate = newDate && newDate.isValid() && newDate.isBefore(allowedAgeDate);

			onChange({
				id,
				value: isValidDate ? newDate?.utc(true).toISOString() : "",
				name: code,
			});
		},
		[id, code, data, onChange]
	);

	const maxDate = dayjs();
	const minDate = maxDate.clone().add(-100, "years");

	return (
		<FormControl>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					label={fieldName}
					value={value}
					maxDate={maxDate}
					minDate={minDate}
					inputFormat={DATE_FORMAT}
					views={["year", "month", "day"]}
					disabled={disabled}
					onChange={handleChange}
					renderInput={(params) => (
						<TextField
							{...params}
							id={elementID}
							name={code}
							required={isRequired}
							error={Boolean(error)}
							helperText={error ? error : fieldDescription}
							inputProps={{
								"data-datepicker": true,
								"data-min-age": data.from,
								"data-min-date": minDate.toISOString(),
								"data-max-date": maxDate.toISOString(),
								"data-date-format": DATE_FORMAT,
								className: FORM_VALIDATION_ELEMENT_CLASSNAME,
								...params.inputProps,
							}}
						/>
					)}
				/>
			</LocalizationProvider>
		</FormControl>
	);
};
