import {ApolloError} from "@apollo/client";
import {RequestState, ModalType, LeagueType} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface IMyLeaguesController extends ViewController<IInit> {
	i18n: ILocalizationStore;

	isLoaded: boolean;
	isEmptyLeagues: boolean;
	myLeagues: ILeagueFragment[];

	filterByType(type: LeagueType): void;

	goToCreateLeague(): void;

	goToJoinLeague(): void;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _filterByType: LeagueType = LeagueType.ALL;

	private _navigate: IInit["navigate"];

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	get myLeagues() {
		if (this._filterByType === LeagueType.ALL) {
			return this._leaguesStore.userLeagues;
		}

		return this._leaguesStore.userLeagues.filter((it) => {
			return it.privacy === this._filterByType;
		});
	}

	get isEmptyLeagues() {
		return !this._leaguesStore.userLeagues.length;
	}

	filterByType(type: LeagueType) {
		this._filterByType = type;
	}

	goToCreateLeague() {
		this._navigate!("/leagues/create");
	}

	goToJoinLeague() {
		this._navigate!("/leagues/join");
	}

	async init(params: IInit) {
		this._navigate = params.navigate;

		this._requestState = RequestState.PENDING;
		try {
			await this._leaguesStore.fetchLeagues();
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "my leagues init");
			this._modalsStore.showModal(ModalType.ERROR, {
				message: (err as ApolloError).message,
			});
			this._requestState = RequestState.ERROR;
		}
	}

	dispose(): void {
		return;
	}
}
