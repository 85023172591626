import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IGameplayApiProvider} from "data/providers/api/gameplay.api.provider";
import {GAME_ID} from "data/constants";
import {filter, find, groupBy, last} from "lodash";
import {ContestUtils} from "data/utils/contest_utils";
import {PublishStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";

export interface IQuestionWithAnswers extends IQuestionFragment {
	answers: IQuestionAnswerFragment[];
}

export interface IGameplayStore {
	get currentSeason(): ISeasonFragment | null;
	get allContests(): IContestFragment[];
	get scheduledContests(): IContestFragment[];
	get actualContests(): IContestFragment[];
	get activeContests(): IContestFragment[];
	get pastContests(): IContestFragment[];
	get currentContests(): IContestFragment[];
	get upcomingContests(): IContestFragment[];

	requestSeasons(): Promise<ISeasonFragment[]>;
	requestContests(): Promise<IContestFragment[]>;
	requestContestsSafety(): Promise<IContestFragment[]>;
	getQuestionsByContestID(contestID: number): IQuestionWithAnswers[];
	requestQuestions(params: IGetQuestionsQueryVariables): Promise<IQuestionWithAnswers[]>;
	setContestComplete(contestID: number): void;
	saveAnswers(params: ISaveAnswerMutationVariables): Promise<IQuestionFragment | null>;
}

@injectable()
export class GameplayStore implements IGameplayStore {
	@observable _seasons: ISeasonFragment[] = [];
	@observable _contests: IContestFragment[] = [];
	@observable _questionsByContestID: Record<number, IQuestionWithAnswers[]> = {};

	get currentSeason() {
		return last(this._seasons) ?? null;
	}

	get allContests() {
		return this._contests;
	}

	@computed get scheduledContests() {
		return filter(this._contests, ContestUtils.isScheduled);
	}

	@computed get actualContests() {
		return filter(this._contests, ContestUtils.isActual);
	}

	@computed get activeContests() {
		return filter(this._contests, ContestUtils.isActive);
	}

	@computed get pastContests() {
		return filter(this._contests, ContestUtils.isPast);
	}

	@computed get currentContests() {
		return filter(this._contests, ContestUtils.isCurrent);
	}

	@computed get upcomingContests() {
		return filter(this._contests, ContestUtils.isUpcoming);
	}

	constructor(@inject(Bindings.GameplayApiProvider) private _gameplayApi: IGameplayApiProvider) {
		makeAutoObservable(this);
	}

	getQuestionsByContestID(contestID: number) {
		return this._questionsByContestID[contestID] ?? [];
	}

	@action setContestComplete(contestID: number): void {
		const contest = find(this.allContests, {id: contestID})!;
		contest.status = ContestUtils.status.COMPLETE;
	}

	@action requestSeasons() {
		return this._gameplayApi.requestSeasonsList({gameID: GAME_ID}).then((resp) => {
			const seasons = resp.data.seasons;
			runInAction(() => (this._seasons = seasons));
			return seasons;
		});
	}

	@action requestContests() {
		if (!this.currentSeason) {
			throw new Error("Season not found. Contests can't be fetched without season ID");
		}

		return this._gameplayApi
			.requestContestsList({seasonID: this.currentSeason.id})
			.then((resp) => {
				const contests = resp.data.contestsCached;
				runInAction(() => (this._contests = contests));
				return contests;
			});
	}

	@action async requestContestsSafety() {
		if (!this.currentSeason) {
			await this.requestSeasons();
		}

		return this.requestContests();
	}

	@action requestQuestions(params: IGetQuestionsQueryVariables) {
		return Promise.all([
			this._gameplayApi.requestQuestionsList(params),
			this._gameplayApi.requestAnswersList(params),
		]).then(([questionsResponse, answersResponse]) => {
			const answersByQuestionID = groupBy(answersResponse.data.answers, "question.id");

			const questions = filter(questionsResponse.data.questionsByContestId, {
				publishStatus: PublishStatus.PUBLISHED,
			}).map((question) => ({
				...question,
				answers: answersByQuestionID[question.id] ?? [],
			}));

			runInAction(() => (this._questionsByContestID[params.contestID] = questions));

			return questions;
		});
	}

	async saveAnswers(params: ISaveAnswerMutationVariables) {
		const response = await this._gameplayApi.saveAnswers(params);
		return response.data?.answerQuestions[0].question || null;
	}
}
