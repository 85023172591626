import {ViewController} from "data/types/structure";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, reaction, when} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGameplayStore, IQuestionWithAnswers} from "data/stores/gameplay/gameplay.store";
import {ApolloError} from "@apollo/client";
import {find, isEmpty, isEqual, size, toNumber, isArray, isString, throttle} from "lodash";
import GeniusSportsMessageBus, {
	IGeniusSportsMessageBus,
	IGeniusSportsMessageBusQuestionEvent,
} from "@geniussports/gsm-web-core-library/lib/genius-sports-message-bus";
import {type IStepper, Stepper} from "data/utils/stepper/stepper.utility";
import {QuestionUtils} from "data/utils/question_utils";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

interface IControllerProps {
	contestID: number;
}

export interface IContestController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;

	get requestState(): RequestState;

	get allContests(): IContestFragment[];

	get activeContests(): IContestFragment[];

	get currentContest(): IContestFragment | null;

	get contestQuestions(): IQuestionWithAnswers[];

	get isLoading(): boolean;

	get isQuestionsLoading(): boolean;

	readonly questionAnswerEventName: string;
	readonly messageBus: IGeniusSportsMessageBus;
	updateContestID: (contestID: number) => void;
	setContestComplete: (contestID: number) => void;
	stepper: IStepper;
	onCompleteClick: () => void;
}

@injectable()
export class ContestController implements IContestController {
	@observable private _contestID!: number;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable public stepper = new Stepper();
	private _questionsReactionDisposer!: ReturnType<typeof when>;
	private _contestIdReactionDisposer!: ReturnType<typeof when>;

	public readonly questionAnswerEventName = "contest:question:answer";

	get requestState() {
		return this._requestState;
	}

	get allContests() {
		return this._gameplayStore.allContests;
	}

	get activeContests() {
		return this._gameplayStore.actualContests;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isQuestionsLoading() {
		return this.isLoading && isEmpty(this.contestQuestions);
	}

	get contestQuestions() {
		return this._gameplayStore.getQuestionsByContestID(this._contestID);
	}

	@computed get currentContest() {
		return find(this._gameplayStore.allContests, {id: this._contestID}) || null;
	}

	constructor(
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore,
		@inject(Bindings.UserStore) private readonly _userStore: IUserStore,
		@inject(GeniusSportsMessageBus) readonly messageBus: IGeniusSportsMessageBus
	) {
		makeAutoObservable(this);
	}

	@action private handleQuestionAnswer = throttle(
		({body}: IGeniusSportsMessageBusQuestionEvent) => {
			const questionID = Number(body.questionId);
			const question = find(this.contestQuestions, {id: questionID})!;

			if (!QuestionUtils.isScheduled(question)) {
				return;
			}

			if (QuestionUtils.isRangeQuestion(question) && isString(body.selectedValues)) {
				const answer = QuestionUtils.putAnswerInRange(question, body.selectedValues);

				question.answers = [
					{
						value: answer,
					},
				];
				this._gameplayStore
					.saveAnswers({
						params: {
							questionId: question.id,
							answers: [
								{
									value: answer,
								},
							],
						},
					})
					.catch(this.onError);
			}

			if (isArray(body.selectedValues)) {
				const answeredIDs = body.selectedValues.map(toNumber);

				question.answers = question.options
					.filter(({id}) => answeredIDs.includes(id))
					.map(({id}) => ({option: {id}}));

				this._gameplayStore
					.saveAnswers({
						params: {
							questionId: question.id,
							answers: answeredIDs.map((id) => ({optionId: id})),
						},
					})
					.catch(this.onError);
			}
		},
		300
	);

	@action updateContestID = (contestID: number) => {
		this._contestID = contestID;
	};

	@action setContestComplete = (contestID: number) => {
		this._gameplayStore.setContestComplete(contestID);
	};

	@action private requestQuestions() {
		this._requestState = RequestState.PENDING;

		return this._gameplayStore.requestQuestions({
			contestID: this._contestID,
		});
	}

	@action
	private requestData() {
		this._requestState = RequestState.PENDING;

		const requests: Promise<unknown>[] = [this.requestQuestions()];

		if (isEmpty(this.allContests)) {
			requests.push(this._gameplayStore.requestContestsSafety());
		}

		Promise.all(requests).then(this.onSuccess).catch(this.onError);
	}

	@action onError = (error: ApolloError) => {
		trackSentryErrors(error, {}, "contest - error handler");
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {message: error.message});
	};

	@action onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action init({contestID}: IControllerProps) {
		this._contestID = contestID;

		this._questionsReactionDisposer = reaction(
			() => this.contestQuestions,
			(questions) => {
				this.stepper = new Stepper(size(questions));
			},
			{fireImmediately: true}
		);

		this._contestIdReactionDisposer = reaction(
			() => this._contestID,
			() => void this.requestQuestions().then(this.onSuccess).catch(this.onError)
		);

		this.messageBus.addEventListener<IGeniusSportsMessageBusQuestionEvent>(
			this.questionAnswerEventName,
			this.handleQuestionAnswer
		);

		this.requestData();
	}

	dispose(): void {
		this._questionsReactionDisposer();
		this._contestIdReactionDisposer();
		this.messageBus.removeEventListener(
			this.questionAnswerEventName,
			this.handleQuestionAnswer
		);
	}

	onCompleteClick = () => {
		createConnextraScriptTag(ConnextraType.PICKS_CONFIRM);
	};
}
