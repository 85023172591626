import React, {Fragment} from "react";
import styled from "@emotion/styled";

import {Footer} from "views/components/footer/footer.component";
import {Outlet} from "react-router-dom";
import {Header} from "views/components/header/header.component";

export const Main = styled.main`
	display: flex;
	flex-flow: column;
	flex: 1;
`;

export const MainLayout: React.FC = () => (
	<Fragment>
		<Header />
		<Main>
			<Outlet />
		</Main>
		<Footer />
	</Fragment>
);
