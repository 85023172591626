export enum ConnextraType {
	HOME = "f2p_blitzpicks_homepage",
	REGISTRATION_START = "f2p_blitzpicks_regstart",
	REGISTRATION_CONFIRM = "f2p_blitzpicks_regconfirm",
	LOGIN = "f2p_blitzpicks_loggedin",
	PICKS_CONFIRM = "f2p_blitzpicks_pickconfirm",
	LANDING = "f2p_blitzpicks_landingpage",
	SHARE = "f2p_blitzpicks_shareclick",
}

const CONNEXTRA_URL = `https://zz.connextra.com/dcs/tagController/tag/8ce716f59752/`;
const IS_ENABLED = process.env.REACT_APP_ENABLE_CONNEXTRA === "true";

export const createConnextraScriptTag = (type: ConnextraType, user?: IUserFragment) => {
	if (!IS_ENABLED) {
		return;
	}
	const accountId = user?.id;
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (accountId) {
		url.searchParams.append("AccountID", accountId);
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
