import React from "react";
import {Outlet} from "react-router-dom";
import {Footer} from "views/components/footer/footer.component";
import {Main} from "views/components/layouts/main_layout.component";

export const FooterOnlyLayout: React.FC = () => {
	return (
		<React.Fragment>
			<Main>
				<Outlet />
			</Main>
			<Footer />
		</React.Fragment>
	);
};
