import {createTheme} from "@mui/material";
import arrows from "assets/img/icons/Arrows.svg";

export const theme = createTheme({
	typography: {
		fontFamily: "var(--fontFamilyBase)",
		h2: {
			fontSize: "28px",
		},
		h3: {
			fontSize: "24px",
		},
		h4: {
			fontSize: "20px",
		},
	},

	palette: {
		primary: {
			main: "#003594",
		},
		secondary: {
			main: "#FFD100",
		},
		grey: {
			"50": "#F2F2F5",
			"100": "#E5E5EA",
			"200": "#C7C7CC",
			"300": "#AEAEB2",
			"400": "#8E8E93",
			"500": "#636366",
			"600": "#48484A",
			"700": "#3A3A3C",
			"800": "#2C2C2E",
			"900": "#1C1C1E",
			A100: "#f5f5f5",
			A200: "#eeeeee",
			A400: "#bdbdbd",
			A700: "#616161",
		},
		background: {
			default: "#FFFFFF",
			paper: "#FFFFFF",
			surface3: "var(--secondaryColor)",
			widget: "#f0f3f7",
		},
		error: {
			contrastText: "#fff",
			dark: "rgb(170, 46, 37)",
			light: "rgb(246, 104, 94)",
			main: "#f44336",
		},
		success: {
			contrastText: "rgba(0, 0, 0, 0.87)",
			dark: "rgb(16, 140, 79)",
			light: "rgb(70, 211, 141)",
			main: "#18C971",
		},
		text: {
			disabled: "rgba(0, 0, 0, 0.38)",
			primary: "#101010",
			secondary: "#8E8E93",
		},
	},
	components: {
		QuestionCardComponent: {
			styleOverrides: {
				QuestionHeaderRoot: {
					paddingTop: 0,
					gridGap: 2,
				},
			},
		},
		QuestionCardAnswerSlider: {
			styleOverrides: {
				sliderRail: {
					background: "#CCCCCC",
				},
				sliderTrack: {
					background: "#003594",
				},
				sliderContainer: {
					backgroundColor: "transparent",
				},
				sliderThumb: {
					width: "36px",
					height: "36px",
					backgroundColor: "#003594",
					background: `url(${arrows}) center/contain no-repeat`,
					"&.Mui-disabled": {
						background: `#AEAEB2 url(${arrows}) center/contain no-repeat !important`,
					},
				},
				inputContainer: {
					"& .MuiInput-root": {
						border: " 1px solid #CCCCCC",
						borderRadius: "4px",
						borderWidth: "1px !important",
					},
					"& [data-status=correct]": {
						borderColor: "green",
					},
					"& [data-status=incorrect]": {
						borderColor: "red",
					},
				},
			},
		},
		QuestionCardButton: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				BorderStatusCorrect: {
					borderRadius: 0,
				},
				BorderStatusWasCorrect: {
					borderRadius: 0,
				},
				BorderStatusIncorrect: {
					borderRadius: 0,
				},
				BorderStatusSelected: {
					borderRadius: 0,
				},
				BorderStatusDisabled: {
					borderRadius: 0,
				},
				BorderStatusDefault: {
					borderRadius: 0,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					fontSize: "11px",
					fontFamily: "var(--fontFamilyBase)",
					color: "#AEAEB2",
					fontWeight: 500,
				},
				colorPrimary: {
					backgroundColor: "rgba(33, 150, 243, .1)",
					color: "#2196F3",
				},
				colorSuccess: {
					color: "#18C971",
					backgroundColor: "rgba(24, 201, 113, .1)",
				},
				colorError: {
					color: "#F44336",
					backgroundColor: "rgba(197, 26, 32, .1)",
				},
				colorInfo: {
					color: "#2196F3",
					backgroundColor: "rgba(33, 150, 243, .1)",
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					textTransform: "none",
					borderRadius: 0,
					fontWeight: 700,
					":disabled": {
						color: "#fff",
						background: "#003594",
						opacity: 0.3,
					},
				},
				contained: {
					textTransform: "uppercase",
					color: "#FFD100",
					border: "1px solid #003594",
					":hover": {
						color: "#003594",
						background: "transparent",
					},
					":disabled": {
						color: "#003594",
						background: "transparent",
						opacity: 0.3,
					},
				},
				containedSecondary: {
					textTransform: "uppercase",
					background: "#FFD100",
					color: "#003594",
					border: 0,
					":hover": {
						background: "#EEC300",
					},
					":disabled": {
						color: "#003594",
						background: "#EEC300",
						opacity: 0.3,
					},
				},
				containedError: {
					textTransform: "uppercase",
					background: "#003594",
					color: "#FFD100",
					border: "1px solid #003594",
					":hover": {
						color: "#003594",
						background: "transparent",
					},
					":disabled": {
						color: "#003594",
						background: "transparent",
						opacity: 0.3,
					},
				},
				outlined: {
					textTransform: "uppercase",
					border: "1px solid #FFF",
					color: "#FFF",
					":hover": {
						border: "1px solid #FFF",
						opacity: 0.7,
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				colorSuccess: {
					color: "#18C971",
				},
				colorError: {
					color: "#F44336",
				},
				colorInfo: {
					color: "#2196F3",
				},
				colorAction: {
					color: "#000",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					textTransform: "none",
				},
				flexContainer: {
					borderBottom: "2px solid gray",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					background: "#FFF",
					color: "var(--primaryColor)",
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					background: "var(--primaryColor)",
					color: "var(--textColor4)",
					"& th": {
						color: "white",
						padding: "10px 16px 10px 20px",
						backgroundColor: "#003594",
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&.own-user": {
						background: "#003594",
						"&: hover": {
							background: "#01236c",
						},
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					padding: "5px 16px 5px 20px",
					".own-user &": {
						color: "#fff",
						"&: hover": {
							color: "#fff",
						},
					},
				},
			},
		},
		MuiSnackbar: {
			defaultProps: {
				anchorOrigin: {
					horizontal: "right",
					vertical: "bottom",
				},
				autoHideDuration: 5000,
			},
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					background: "#fff",
					color: "#000",
				},
			},
		},
	},
});
