import React from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ModalType} from "data/enums";
import {Modal} from "@mui/material";
import {ModalBox, ModalContent} from "views/components/modals/modal_common.component";
import {ModalCloseButton} from "views/components/buttons/buttons.component";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {FormResetPasswordComponent} from "views/components/forms/reset_password/form_reset_password.component";
import {Bindings} from "data/constants/bindings";

const SModalContent = styled(ModalContent)`
	form {
		max-width: 340px;
		margin: 0 auto;
		padding-top: 60px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 12px;
	}
`;

export const ModalResetPassword: React.FC = observer(() => {
	const {visibleModal, close} = useViewController<IModalsController>(Bindings.ModalsController);
	const isVisible = ModalType.RESET_PASSWORD === visibleModal;

	return (
		<Modal open={isVisible} onClose={close}>
			<ModalBox>
				<SModalContent>
					<ModalCloseButton onClick={close}>
						<CloseIcon />
					</ModalCloseButton>
					<FormResetPasswordComponent />
				</SModalContent>
			</ModalBox>
		</Modal>
	);
});
