import {first, isEqual, property} from "lodash";
import QuestionCard from "@geniussports/fep-widgets/dist/esm/QuestionCardComponent";

import type {IQuestionWithAnswers} from "data/stores/gameplay/gameplay.store";

type TRangeStatus = Exclude<Parameters<typeof QuestionCard>[0]["answerCard"]["status"], undefined>;
type TRangeStatusKey = "ENABLED" | "DISABLED";

type TOptionStatusMultiple = Extract<
	Parameters<typeof QuestionCard>[0]["answerCard"]["answers"],
	Array<{isFavorite: boolean}>
>;
type TOptionStatus = TOptionStatusMultiple[number]["status"];

type TQuestionStatusKey = "SCHEDULED" | "LOCKED" | "COMPLETE";
type TOptionStatusKey =
	| "DEFAULT"
	| "CORRECT"
	| "WAS_CORRECT"
	| "INCORRECT"
	| "SELECTED"
	| "DISABLED";

enum QuestionType {
	RANGE = "Range",
}

const QuestionsStatus: Record<TQuestionStatusKey, QuestionStatus> = {
	SCHEDULED: "Scheduled",
	LOCKED: "Locked",
	COMPLETE: "Completed",
};

const OptionStatus: Record<TOptionStatusKey, Exclude<TOptionStatus, undefined>> = {
	DEFAULT: "default",
	CORRECT: "correct",
	WAS_CORRECT: "wascorrect",
	INCORRECT: "incorrect",
	SELECTED: "selected",
	DISABLED: "disabled",
};

const RangeStatus: Record<TRangeStatusKey, Exclude<TRangeStatus, undefined>> = {
	ENABLED: "enabled",
	DISABLED: "disabled",
};

const {SCHEDULED, LOCKED, COMPLETE} = QuestionsStatus;

export abstract class QuestionUtils {
	static questionStatus = QuestionsStatus;
	static optionStatus = OptionStatus;

	static isScheduled = ({status}: IQuestionFragment) => isEqual(SCHEDULED, status);

	static isLocked = ({status}: IQuestionFragment) => isEqual(LOCKED, status);

	static isComplete = ({status}: IQuestionFragment) => isEqual(COMPLETE, status);

	static getPointsValue = (question: IQuestionFragment) => {
		if (QuestionUtils.isRangeQuestion(question)) {
			return;
		}
		const points =
			first(question.scoringRules)?.points || first(question.contest.scoringRules)?.points;
		return points ? String(points) : undefined;
	};

	static isRangeQuestion = ({type}: IQuestionFragment) => {
		return type.logicType === QuestionType.RANGE;
	};

	static rangeQuestionStatus = (question: IQuestionFragment): TRangeStatus => {
		if (
			QuestionUtils.isRangeQuestion(question) &&
			(QuestionUtils.isLocked(question) || QuestionUtils.isComplete(question))
		) {
			return RangeStatus.DISABLED;
		}

		return RangeStatus.ENABLED;
	};

	static putAnswerInRange = (question: IQuestionFragment, answer: string) => {
		const value = Number(answer);
		const answerFrom = Number(question.rangeFrom);
		const answerTo = Number(question.rangeTo);

		if (value < answerFrom) {
			return String(answerFrom);
		}

		if (value > answerTo) {
			return String(answerTo);
		}

		return String(answer);
	};

	static getRangeAnswer = (question: IQuestionWithAnswers) => {
		return first(question.answers)?.value ?? "";
	};

	static getOptionStatus = (question: IQuestionWithAnswers, optionID: number) => {
		const answersIds = question.answers.map<number>(property("option.id"));
		const correctOptionIds = question.correctOptions.map<number>(property("option.id"));
		const isAnswered = answersIds.includes(optionID);
		const isCorrect = correctOptionIds.includes(optionID);

		if (QuestionUtils.isComplete(question)) {
			return QuestionUtils.getOptionStatusForCompleteState(isAnswered, isCorrect);
		}

		if (QuestionUtils.isLocked(question)) {
			return QuestionUtils.getOptionStatusForLockedState(isAnswered);
		}

		return QuestionUtils.getOptionStatusForScheduledState(isAnswered);
	};

	static hasCorrectAnswer(question: IQuestionWithAnswers) {
		return question.options.some(({id}) =>
			isEqual(QuestionUtils.getOptionStatus(question, id), OptionStatus.CORRECT)
		);
	}

	static hasIncorrectAnswer(question: IQuestionWithAnswers) {
		return question.options.some(({id}) =>
			isEqual(QuestionUtils.getOptionStatus(question, id), OptionStatus.INCORRECT)
		);
	}

	private static getOptionStatusForCompleteState(isAnswered: boolean, isCorrect: boolean) {
		if (isAnswered) {
			return isCorrect ? OptionStatus.CORRECT : OptionStatus.INCORRECT;
		}

		return isCorrect ? OptionStatus.WAS_CORRECT : OptionStatus.DISABLED;
	}

	private static getOptionStatusForLockedState(isAnswered: boolean) {
		return isAnswered ? OptionStatus.SELECTED : OptionStatus.DISABLED;
	}

	private static getOptionStatusForScheduledState(isAnswered: boolean) {
		return isAnswered ? OptionStatus.SELECTED : OptionStatus.DEFAULT;
	}
}
