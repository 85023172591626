import {inject, injectable} from "inversify";
import type {IGraphQLClient} from "data/services/graphql/grapql_client_factory.service";
import gql from "data/graphql";
import {ApolloQueryResult} from "@apollo/client";
import type {FetchResult} from "@apollo/client/link/core";
import {Bindings} from "data/constants/bindings";
import {CancellablePromise} from "data/types/global";

export interface IAuthApiProvider {
	login: (
		variables: IGetRegisteredUserQueryVariables
	) => Promise<ApolloQueryResult<IGetRegisteredUserQuery>>;
	register: (
		variables: IRegisterUserMutationVariables
	) => Promise<FetchResult<IRegisterUserMutation>>;
	customFields: (
		variables: IGetCustomFieldsQueryVariables
	) => Promise<ApolloQueryResult<IGetCustomFieldsQuery>>;
	updateUser: (
		variables: IUpdateUserMutationVariables
	) => Promise<FetchResult<IUpdateUserMutation>>;
	checkUsername: (
		variables: ICheckUsernameQueryVariables
	) => CancellablePromise<ApolloQueryResult<ICheckUsernameQuery>>;
	checkUsernameAuthorized: (
		variables: ICheckUsernameAuthorizedQueryVariables
	) => CancellablePromise<ApolloQueryResult<ICheckUsernameAuthorizedQuery>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.GraphQLClient) private _client: IGraphQLClient) {}

	login = (variables: IGetRegisteredUserQueryVariables) =>
		this._client.query<IGetRegisteredUserQuery, IGetRegisteredUserQueryVariables>({
			query: gql.queries.GET_REGISTERED_USER,
			variables,
		});

	register = (variables: IRegisterUserMutationVariables) =>
		this._client.mutate<IRegisterUserMutation, IRegisterUserMutationVariables>({
			mutation: gql.mutations.REGISTER_USER,
			variables,
		});

	updateUser = (variables: IUpdateUserMutationVariables) =>
		this._client.mutate<IUpdateUserMutation, IUpdateUserMutationVariables>({
			mutation: gql.mutations.UPDATE_USER,
			variables,
		});

	customFields = (variables: IGetCustomFieldsQueryVariables) =>
		this._client.query<IGetCustomFieldsQuery, IGetCustomFieldsQueryVariables>({
			query: gql.queries.GET_CUSTOM_FIELDS,
			variables,
		});

	checkUsername = (variables: ICheckUsernameQueryVariables) =>
		this._client.query<ICheckUsernameQuery, ICheckUsernameQueryVariables>({
			query: gql.queries.CHECK_USERNAME,
			variables,
		});

	checkUsernameAuthorized = (variables: ICheckUsernameAuthorizedQueryVariables) =>
		this._client.query<ICheckUsernameAuthorizedQuery, ICheckUsernameAuthorizedQueryVariables>({
			query: gql.queries.CHECK_USERNAME_AUTHORIZED,
			variables,
		});
}
