import type {IStandingsApiProvider} from "data/providers/api/standings.api.provider";
import {inject, injectable} from "inversify";
import {makeAutoObservable, action, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";

export interface IStandingsStore {
	getLadder(params: IGetLeagueLadderQueryVariables): Promise<void>;
	getUserRank(params: IGetUserLeagueRankQueryVariables): Promise<void>;
	clear(): void;
	get ladder(): IRanksFragment;
	get userRank(): IRankUserFragment | undefined;
}

@injectable()
export class StandingsStore implements IStandingsStore {
	@observable _ladder: IRanksFragment = {
		isNextPage: false,
		pages: 0,
		ranks: [],
	};

	@observable _userRank?: IRankUserFragment;

	constructor(
		@inject(Bindings.StandingsApiProvider) private _standingsApiProvider: IStandingsApiProvider
	) {
		makeAutoObservable(this);
	}

	get ladder(): IRanksFragment {
		return this._ladder;
	}

	get userRank(): IRankUserFragment | undefined {
		return this._userRank;
	}

	@action
	async getLadder(params: IGetLeagueLadderQueryVariables) {
		const {data} = await this._standingsApiProvider.fetch(params);
		const ladder = data.getLeagueLadder?.ranks || [];
		const previousRanks = this._ladder.ranks || [];

		runInAction(() => {
			if (data.getLeagueLadder) {
				this._ladder = {
					...data.getLeagueLadder,
					ranks: [...previousRanks, ...ladder],
				};
			}
		});
	}

	@action
	async getUserRank(params: IGetUserLeagueRankQueryVariables) {
		const {data} = await this._standingsApiProvider.fetchUserRank(params);
		const userRank = data.getUserLeagueRank;

		runInAction(() => {
			if (userRank) {
				this._userRank = userRank;
			}
		});
	}

	@action
	clear() {
		this._ladder = {
			isNextPage: false,
			pages: 0,
			ranks: [],
		};

		this._userRank = undefined;
	}
}
