import {
	ApolloClient,
	ApolloClientOptions,
	ApolloQueryResult,
	from,
	QueryOptions,
} from "@apollo/client";
import {NormalizedCacheObject} from "@apollo/client/cache/inmemory/types";
import {ENVIRONMENT} from "data/constants";
import {ApolloClientLinks} from "data/services/graphql/apollo_client.middlewares";
import {CancellablePromise} from "data/types/global";
import {OperationVariables} from "@apollo/client/core/types";

export type IGraphQLClient = ReturnType<typeof GraphQLClientFactory.createClient>;
export type IGraphQLClientParameters = Parameters<typeof GraphQLClientFactory.createClient>[0];

interface IApolloClient<T> extends ApolloClient<T> {
	query<T, TVariables = OperationVariables>(
		options: QueryOptions<TVariables, T>
	): CancellablePromise<ApolloQueryResult<T>>;
}

export abstract class GraphQLClientFactory {
	static createClient<T = NormalizedCacheObject>(
		options: ApolloClientOptions<T>
	): IApolloClient<T> {
		const client = new ApolloClient<T>({
			connectToDevTools: ENVIRONMENT !== "production",
			defaultOptions: {
				watchQuery: {
					/**
					 * Options list
					 * https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
					 */
					fetchPolicy: "cache-and-network",
					nextFetchPolicy: "cache-and-network",
				},
			},
			/**
			 * It's middlewares that added Bearer token, handle errors, etc...
			 * Please be careful, order is matter!
			 */
			link: from([
				ApolloClientLinks.createErrorLink(),
				ApolloClientLinks.createAuthLink(),
				ApolloClientLinks.createHttpLink(options),
			]),
			...options,
		});

		const originalQuery = client.query.bind(client);

		const newQuery = function <T, TVariables = OperationVariables>(
			options: QueryOptions<TVariables, T>
		): CancellablePromise<ApolloQueryResult<T>> {
			const abortController = new AbortController();

			const promise = originalQuery({
				...options,
				context: {
					fetchOptions: {signal: abortController.signal},
					queryDeduplication: false,
				},
			}) as CancellablePromise<ApolloQueryResult<T>>;

			promise.cancel = () => abortController.abort();

			return promise;
		};

		client.query = newQuery;

		return client as IApolloClient<T>;
	}
}
