import React from "react";
import {Button, Modal, Typography} from "@mui/material";
import {isEqual} from "lodash";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ModalType} from "data/enums";
import {observer} from "mobx-react";
import {ModalBox, ModalContent} from "views/components/modals/modal_common.component";
import {Exist} from "views/components/exist/exist.component";
import {Bindings} from "data/constants/bindings";

export const ModalError: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {visibleModal, modalContent, i18n, close} = controller;
	const isVisible = isEqual(visibleModal, ModalType.ERROR);

	if (!isVisible) {
		return null;
	}

	const title = modalContent?.title;
	const message = modalContent?.message;

	return (
		<Modal open={isVisible} onClose={close}>
			<ModalBox>
				<ModalContent>
					<Exist when={title}>
						<Typography align="center" variant="h5">
							{title}
						</Typography>
					</Exist>
					<Exist when={message}>
						<Typography sx={{mb: 3}}>{message}</Typography>
					</Exist>
					<Button fullWidth variant="contained" onClick={close}>
						{i18n.t("modal.error.button", "Close")}
					</Button>
				</ModalContent>
			</ModalBox>
		</Modal>
	);
});
