import {ApolloError} from "@apollo/client";
import {ModalType, RequestState} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {buildNameFromUser} from "data/utils/user";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {isEmpty, isEqual} from "lodash";
import {trackSentryErrors} from "data/utils";

interface IInit {
	leagueId: number;
}

export interface ILeagueUsersController extends ViewController<IInit> {
	i18n: ILocalizationStore;

	get league(): ILeagueFragment | undefined;
	get leagueUsers(): ILeagueUserFragment[];
	get isLoadMore(): boolean;
	get isPreloaderVisible(): boolean;
	get isLoading(): boolean;
	get pageNumber(): number;

	init(params: IInit): void;
	isOwnUser(userId: string): boolean;
	isUserCanBeRemoved(userId: string): boolean;
	increasePageNumber(): void;
	confirmRemoveUser(user: IUserFragment): Promise<void>;
	removeUser(userId: ILeagueUserFragment): void;
	dispose(): void;
}

@injectable()
export class LeagueUsersController implements ILeagueUsersController {
	private _leagueId!: number;
	private _pageSize = 20;
	@observable private _requestState: RequestState = RequestState.PENDING;
	@observable private _pageNumber = 1;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get league(): ILeagueFragment | undefined {
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	@computed
	get leagueUsers(): ILeagueUserFragment[] {
		return this._leaguesStore.leagueUsers.users;
	}

	@computed
	get isLoadMore(): boolean {
		return this._leaguesStore.leagueUsers.isNextPage;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isPreloaderVisible() {
		return isEmpty(this.leagueUsers) && this.isLoading;
	}

	get pageNumber(): number {
		return this._pageNumber;
	}

	isOwnUser(userId?: string): boolean {
		return this._userStore.user?.id === userId;
	}

	isUserCanBeRemoved(userId?: string): boolean {
		return this.league?.author?.id !== userId;
	}

	increasePageNumber(): void {
		this._pageNumber = this._pageNumber + 1;
	}

	confirmRemoveUser(user: IUserFragment): Promise<void> {
		return this._leaguesStore
			.removeLeagueUser({
				leagueId: this._leagueId,
				userId: user.id,
			})
			.catch((err) => {
				trackSentryErrors(err, {}, "league remove user");
				this._modalsStore.showModal(ModalType.ERROR, {
					message: (err as ApolloError).message,
				});
			});
	}

	@action removeUser(user: IUserFragment) {
		this._modalsStore.showModal(ModalType.LEAGUE_REMOVE_USER, {
			message: buildNameFromUser(user),
			callback: this.confirmRemoveUser.bind(this, user) as () => void,
		});
	}

	init({leagueId}: IInit): void {
		this._leagueId = leagueId;

		reaction(
			() => this._pageNumber,
			() => {
				runInAction(() => (this._requestState = RequestState.PENDING));

				void this._leaguesStore
					.fetchLeagueUsers(leagueId, this._pageSize, this._pageNumber - 1)
					.catch((err) => {
						trackSentryErrors(err, {}, "league fetch users");
						runInAction(() => (this._requestState = RequestState.ERROR));

						this._modalsStore.showModal(ModalType.ERROR, {
							message: (err as ApolloError).message,
						});
					})
					.then(() => runInAction(() => (this._requestState = RequestState.SUCCESS)));
			},
			{
				fireImmediately: true,
			}
		);
	}

	dispose(): void {
		this._leaguesStore.clearLeagueUsers();
	}
}
