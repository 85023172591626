import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {GAME_ID, SKIP_REDIRECT_BY_LOCALE, COMMON_LOCALE, GAME_LOCALE} from "data/constants";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {ApolloError} from "@apollo/client";
import {filter, find, first, includes, isEqual, some, toLower} from "lodash";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

export interface IGameGateController extends ViewController {
	get isSessionChecked(): boolean;
}

@injectable()
export class GameGateController implements IGameGateController {
	private _userLocale = GAME_LOCALE.length ? GAME_LOCALE : navigator.language;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {}

	get isSessionChecked(): boolean {
		return this._userStore.isSessionChecked;
	}

	dispose(): void {
		return;
	}

	private isUserLocaleHasOccurrences = (locale: string) => {
		return includes(toLower(locale), toLower(this._userLocale));
	};

	private findApplicableLocale(locales: string[]) {
		const userCroppedLocale = first(toLower(this._userLocale).split("-"))!;

		const potentialLocale = find(locales, (localeName) =>
			includes(toLower(localeName), userCroppedLocale)
		);

		const commonLocale = find(locales, (localeName) =>
			includes(toLower(localeName), COMMON_LOCALE)
		);

		return potentialLocale || commonLocale || first(locales)! || this._userLocale;
	}

	private async defineLocale(): Promise<string | undefined> {
		const {locales, urls} = await this._i18nStore.requestGameInfo({
			gameID: GAME_ID,
		});

		const isApplicationSupportCurrentLocale = some(locales, (locale) =>
			isEqual(toLower(locale), toLower(this._userLocale))
		);

		if (!isApplicationSupportCurrentLocale) {
			/**
			 * If browser's locale isn't in the list of available locales
			 * we're trying to find any locale that looks like user's locale, or any with the EN language,
			 * or the first one that is available, otherwise, use the current locale.
			 */
			this._userLocale = this.findApplicableLocale(locales);
		}

		/**
		 * Checking if the userLocale can be applied for the current domain
		 */
		const {hostname} = window.location;
		const domainConfig = filter(urls, ({url}) => includes(url, hostname));
		const isDomainSupportCurrentLocale = some(domainConfig, ({locale}) =>
			this.isUserLocaleHasOccurrences(locale)
		);

		if (!isDomainSupportCurrentLocale) {
			/**
			 * Look for available domain for the current userLocale (excluding the current domain),
			 * otherwise do nothing and keep using the same userLocale and domain.
			 */
			const localeConfig = first(
				filter(urls, ({url, locale}) => {
					const isDifferentDomain = !url.includes(hostname);
					const hasSameLocale = this.isUserLocaleHasOccurrences(locale);

					return isDifferentDomain && hasSameLocale;
				})
			);

			if (localeConfig?.url && !SKIP_REDIRECT_BY_LOCALE) {
				/**
				 * Redirect a user to the site with a supported locale
				 */
				return (window.location.href = window.location.href.replace(
					window.location.origin,
					localeConfig.url
				));
			}
		}

		return this._userLocale;
	}

	async init() {
		try {
			const locale = await this.defineLocale();
			/**
			 * The locale may be undefined only in case of redirect.
			 * For all other cases the locale will be defined
			 */
			if (!locale) return;

			/**
			 * If all is good, request the translations for the current locale
			 */
			await this._i18nStore.switchLocale({
				locale,
			});
		} catch (err) {
			trackSentryErrors(err, {}, "game gate - init");
			this._modalsStore.showModal(ModalType.ERROR, {
				message: (err as ApolloError).message,
			});
		}

		await this._userStore.restoreSession();
	}
}
