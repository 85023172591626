import {SHARE_PATH, SITE_URL} from "data/constants";
import {ShareType, SocialNetwork} from "data/enums";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import {identity, set} from "lodash";
import {trackSentryErrors} from "data/utils/index";

interface IContestShare {
	socialNetwork: SocialNetwork;
	type: ShareType.CONTEST;
	message?: string;
	locale: string | null;
	contestId?: number;
}

interface ILeagueShare extends Omit<IContestShare, "type"> {
	type: ShareType.LEAGUE;
	leagueId: number;
}

type IShare = IContestShare | ILeagueShare;

export abstract class ShareUtility {
	private static callbacks = {
		[SocialNetwork.TWITTER]: (data: IShare) => ShareUtility.shareTW(data),
		[SocialNetwork.FACEBOOK]: (data: IShare) => ShareUtility.shareFB(data),
		[SocialNetwork.MOBILE]: (data: IShare) => ShareUtility.shareMobile(data),
	} as const;

	public static share(data: IShare): boolean | void | Window | null {
		if (process.env.JEST_WORKER_ID === undefined) {
			createConnextraScriptTag(ConnextraType.SHARE);
		}
		const callback = ShareUtility.callbacks[data.socialNetwork];

		if (!callback || typeof callback !== "function") {
			throw new Error("Invalid share type");
		}

		return callback(data);
	}

	private static shareTW({message = "", ...params}: IShare): WindowProxy | null {
		return window.open(
			"https://twitter.com/share?url=" +
				encodeURIComponent(ShareUtility.getLinkForShare(params)) +
				"&text=" +
				encodeURIComponent(message),
			"twitter-share-dialog",
			"width=626,height=436"
		);
	}

	private static shareFB(data: IShare): void {
		if (!window.FB) {
			throw new Error("No Facebook share provider found");
		}
		return window.FB?.ui(
			{
				method: "share",
				display: "popup",
				href: ShareUtility.getLinkForShare(data),
			},
			identity
		);
	}

	private static shareMobile({message = "", ...params}: IShare) {
		try {
			void navigator.share({
				url: ShareUtility.getLinkForShare(params),
				text: message,
			});
		} catch (err) {
			trackSentryErrors(err, {}, "shareMobile");
			const error = err as Error;
			throw new Error(error.message);
		}
		return true;
	}

	private static getLinkForShare(data: IShare): string {
		const params = {
			time: this.getTimestamp(),
			locale: data.locale,
		};

		switch (data.type) {
			case ShareType.LEAGUE:
				set(params, "leagueId", data.leagueId);
				break;
			case ShareType.CONTEST:
				set(params, "contestId", data.contestId);
				break;
		}

		return `${SITE_URL}/${SHARE_PATH}/${data.type}/${ShareUtility.getEncodedJson(params)}`;
	}

	private static getTimestamp(): string {
		return Date.now().toString().substring(9);
	}

	private static getEncodedJson(object: Partial<IShare> & {time: string}): string {
		return window.btoa(JSON.stringify(object));
	}
}
