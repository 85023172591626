import {ViewController} from "data/types/structure";
import {ModalType} from "data/enums";
import {createConnextraScriptTag, ConnextraType} from "data/utils/connextra";
import {inject, injectable} from "inversify";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {matchPath} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {IS_SECRET_ENABLED} from "data/constants";

export interface ILandingController extends ViewController {
	i18n: ILocalizationStore;
	goToLogin: () => void;
	goToRegister: () => void;
	mapModalsToLocation: (pathname: string) => void;
	handleSecretClick: () => void;
}

@injectable()
export class LandingController implements ILandingController {
	private static modalPaths = {
		"/login": ModalType.LOGIN,
		"/registration": ModalType.REGISTRATION,
		"/verify": ModalType.RESET_PASSWORD,
		"/forgot-password": ModalType.FORGOT_PASSWORD,
	} as const;

	private static _count = 0;

	static get IS_SECRET_PASSED() {
		const isSecretePassed = sessionStorage.getItem("isSecretPassed");
		return isSecretePassed ? JSON.parse(isSecretePassed) === true : !IS_SECRET_ENABLED;
	}

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {}

	handleSecretClick = () => {
		if (LandingController.IS_SECRET_PASSED) {
			return;
		}

		LandingController._count += 1;

		if (LandingController._count >= 10) {
			sessionStorage.setItem("isSecretPassed", "true");
			window.location.reload();
		}
	};

	dispose(): void {
		return;
	}

	init(): void {
		this.mapModalsToLocation(window.location.pathname);
		createConnextraScriptTag(ConnextraType.LANDING);
	}

	mapModalsToLocation = (pathname: string) => {
		const keys = Object.keys(
			LandingController.modalPaths
		) as (keyof typeof LandingController.modalPaths)[];

		keys.some((pathPattern) => {
			const isMatch = matchPath(pathPattern, pathname);

			if (isMatch) {
				this._modalsStore.showModal(LandingController.modalPaths[pathPattern]);
			}

			return isMatch;
		});
	};

	goToRegister = () => {
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	goToLogin = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};
}
