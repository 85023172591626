import {Countdown as Timer} from "data/utils/countdown";
import React, {useEffect, useRef, useState} from "react";
import {Exist} from "views/components/exist/exist.component";
import {isEqual} from "lodash";
import {useViewController} from "data/services/locator/locator_hook.service";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import styled from "@emotion/styled";
import {grey} from "@mui/material/colors";
import {Bindings} from "data/constants/bindings";

export const ContestCountdownWrapper = styled.div`
	color: ${grey["400"]};
	font-weight: 500;
	font-size: 11px;
	line-height: 14px;
`;

interface IProps {
	date?: string;
	longLabel?: boolean;
	contestID: number;
	onComplete: (contestID: number) => void;
	children?: React.ReactNode;
}

export const ContestCountdown: React.FC<IProps> = (props) => {
	const {date = new Date().toISOString(), contestID, onComplete, children, longLabel} = props;
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const timerRef = useRef(new Timer(new Date(date)));

	const [{days, hours, minutes, seconds}, setTime] = useState({
		days: "00",
		hours: "00",
		minutes: "00",
		seconds: "00",
	});

	useEffect(() => {
		const timer = timerRef.current;

		timer
			.clear()
			.updateDate(new Date(date))
			.onTick((days, hours, minutes, seconds) =>
				setTime({
					days: days.toString().padStart(2, "0"),
					hours: hours.toString().padStart(2, "0"),
					minutes: minutes.toString().padStart(2, "0"),
					seconds: seconds.toString().padStart(2, "0"),
				})
			)
			.onComplete(() => onComplete(contestID))
			.run();

		return () => {
			timer.clear();
		};
	}, [timerRef, contestID, onComplete, date]);

	if (longLabel) {
		const onlySecondsLeft = [days, hours, minutes].every((str) => str === "00");

		const getPluralLabel = (key: string, defaultLabel: string, unit: string) => {
			const isPlural = !isEqual(unit, "01");
			return isPlural
				? i18n.t(`${key}_plural`, `${defaultLabel}s`)
				: i18n.t(key, defaultLabel);
		};

		return (
			<ContestCountdownWrapper>
				{children}
				<Exist when={!isEqual(days, "00")}>
					{days} {getPluralLabel("countdown.label.day_full", "day", days)}
					{", "}
				</Exist>
				{hours} {getPluralLabel("countdown.label.hours_full", "hour", hours)}
				<Exist when={!onlySecondsLeft}>{" and "}</Exist>
				<Exist when={onlySecondsLeft}>{", "}</Exist>
				{minutes} {getPluralLabel("countdown.label.minutes_full", "minute", minutes)}
				<Exist when={onlySecondsLeft}>
					{" and "}
					{seconds} {getPluralLabel("countdown.label.seconds_full", "second", seconds)}
				</Exist>
			</ContestCountdownWrapper>
		);
	}

	return (
		<ContestCountdownWrapper>
			{children}
			<Exist when={!isEqual(days, "00")}>
				{days}
				{i18n.t("countdown.label.days", "D")}:
			</Exist>
			{hours}
			{i18n.t("countdown.label.hours", "H")}:{minutes}
			{i18n.t("countdown.label.minutes", "M")}:{seconds}
			{i18n.t("countdown.label.seconds", "S")}
		</ContestCountdownWrapper>
	);
};
