import type {ILeaguesApiProvider} from "data/providers/api/leagues.api.provider";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, action, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";

export interface ILeaguesStore {
	get userLeagues(): ILeagueFragment[];
	get joinLeagues(): ILeaguesToJoinFragment;
	get joinedLeagueIds(): number[];
	get leagueUsers(): ILeagueUsersFragment;

	getLeagueById(leagueId: number): ILeagueFragment | undefined;
	getLeagueUserById(userId: string): ILeagueUserFragment | undefined;
	fetchLeagues(): Promise<ILeagueFragment[]>;
	fetchLeaguesToJoin(where: IPredictorLeagueShowForJoinWhere): Promise<void>;
	leaveLeague(leagueId: number): Promise<ILeagueFragment[]>;
	removeLeague(leagueId: number): Promise<void>;
	joinLeague(leagueCode: string): Promise<ILeagueFragment | undefined>;
	createLeague(
		params: IAddPredictorLeagueMutationVariables
	): Promise<ILeagueFragment | undefined>;
	updatePredictorLeague(
		params: IUpdatePredictorLeagueMutationVariables
	): Promise<ILeagueFragment | undefined>;
	inviteLeague(params: IInvitePredictorLeagueMutationVariables): Promise<number | undefined>;
	fetchLeague(leagueId: number): Promise<ILeagueFragment | undefined>;
	fetchLeagueUsers(leagueId: number, pageSize: number, pageNumber: number): Promise<void>;
	clearLeagueUsers(): void;
	clearJoinLeagues(): void;
	removeLeagueUser(params: IRemoveUserFromPredictorLeagueMutationVariables): Promise<void>;
	isLeagueJoined(leagueId: number): boolean;
}

@injectable()
export class LeaguesStore implements ILeaguesStore {
	@observable private _userLeagues: ILeagueFragment[] = [];
	@observable private _joinLeagues: ILeaguesToJoinFragment = {
		isNextPage: false,
		leagues: [],
		pages: 0,
	};
	@observable private _joinedLeagueIds: number[] = [];
	@observable private _leagueUsers: ILeagueUsersFragment = {
		pages: 0,
		users: [],
		isNextPage: false,
	};

	get userLeagues() {
		return this._userLeagues;
	}

	get joinedLeagueIds() {
		return this._joinedLeagueIds;
	}

	get joinLeagues() {
		return this._joinLeagues;
	}

	get leagueUsers(): ILeagueUsersFragment {
		return this._leagueUsers;
	}

	constructor(
		@inject(Bindings.LeaguesApiProvider) private _leaguesApiProvider: ILeaguesApiProvider
	) {
		makeAutoObservable(this);
	}

	clearLeagueUsers(): void {
		this._leagueUsers = {
			pages: 0,
			users: [],
			isNextPage: false,
		};
	}

	@action
	clearJoinLeagues(): void {
		this._joinedLeagueIds = [];
		this._joinLeagues = {
			pages: 0,
			leagues: [],
			isNextPage: false,
		};
	}

	getLeagueById(leagueId: number): ILeagueFragment | undefined {
		return this.userLeagues.find((league) => {
			return league?.id === leagueId;
		});
	}

	getLeagueUserById(userId: string): ILeagueUserFragment | undefined {
		return this.leagueUsers.users.find((user) => {
			return user?.id === userId;
		});
	}

	isLeagueJoined(id: number): boolean {
		return this.joinedLeagueIds.includes(id);
	}

	@action
	async fetchLeagues(): Promise<ILeagueFragment[]> {
		const {data} = await this._leaguesApiProvider.myPredictorLeagues();

		runInAction(() => {
			this._userLeagues = data.myPredictorLeagues;
		});

		return data.myPredictorLeagues;
	}

	@action
	async fetchLeaguesToJoin(where: IPredictorLeagueShowForJoinWhere) {
		const {data} = await this._leaguesApiProvider.showForJoinPredictorLeague({
			pageNumber: where.pageNumber || 0,
			pageSize: where.pageSize || 10,
			query: where.query || "",
		});

		const leagueToJoin = data.showForJoinPredictorLeague;

		if (leagueToJoin) {
			runInAction(
				() =>
					(this._joinLeagues = {
						...leagueToJoin,
						leagues: [...this._joinLeagues.leagues, ...leagueToJoin.leagues],
					})
			);
		}
	}

	@action
	async createLeague(
		params: IAddPredictorLeagueMutationVariables
	): Promise<ILeagueFragment | undefined> {
		const {data} = await this._leaguesApiProvider.create(params);

		runInAction(() => {
			if (data?.addPredictorLeague) {
				this._userLeagues.push(data.addPredictorLeague);
			}
		});

		return data?.addPredictorLeague;
	}

	@action
	async updatePredictorLeague(
		params: IUpdatePredictorLeagueMutationVariables
	): Promise<ILeagueFragment | undefined> {
		const {data} = await this._leaguesApiProvider.updatePredictorLeague(params);

		runInAction(() => {
			if (data?.updatePredictorLeague) {
				this._userLeagues = this._userLeagues.map((league) => {
					if (league.id === data.updatePredictorLeague.id) {
						return data.updatePredictorLeague;
					}
					return league;
				});
			}
		});

		return data?.updatePredictorLeague;
	}

	@action
	async leaveLeague(leagueId: number): Promise<ILeagueFragment[]> {
		await this._leaguesApiProvider.leavePredictorLeague({
			leagueId,
		});

		runInAction(() => {
			this._userLeagues = this._userLeagues.filter((league) => league.id !== leagueId);
			this._joinedLeagueIds = this._joinedLeagueIds.filter((id) => id !== leagueId);
		});

		return this._userLeagues;
	}

	@action
	async joinLeague(leagueCode: string): Promise<ILeagueFragment | undefined> {
		const {data} = await this._leaguesApiProvider.joinPredictorLeague({
			leagueCode,
		});

		runInAction(() => {
			if (data) {
				this._userLeagues.push(data.joinPredictorLeague);
				this._joinedLeagueIds.push(data.joinPredictorLeague.id);
			}
		});

		return data?.joinPredictorLeague;
	}

	@action
	async inviteLeague(
		params: IInvitePredictorLeagueMutationVariables
	): Promise<number | undefined> {
		const {data} = await this._leaguesApiProvider.invitePredictorLeague(params);

		return data?.invitePredictorLeague.id;
	}

	@action
	async fetchLeague(leagueId: number): Promise<ILeagueFragment | undefined> {
		const {data} = await this._leaguesApiProvider.getPredictorLeague({leagueId});

		runInAction(() => {
			if (data) {
				this._userLeagues = [...this._userLeagues, data.getPredictorLeague];
			}
		});

		return data.getPredictorLeague;
	}

	@action
	async fetchLeagueUsers(leagueId: number, pageSize: number, pageNumber: number) {
		const {data} = await this._leaguesApiProvider.getPredictorLeagueUsers({
			leagueId,
			pageSize,
			pageNumber,
		});

		runInAction(() => {
			if (data.getPredictorLeagueUsers?.users) {
				const users = this._leagueUsers?.users || [];
				this._leagueUsers = {
					...data.getPredictorLeagueUsers,
					users: pageNumber
						? [...users, ...data.getPredictorLeagueUsers.users]
						: data.getPredictorLeagueUsers.users,
				};
			}
		});
	}

	@action
	async removeLeagueUser(params: IRemoveUserFromPredictorLeagueMutationVariables) {
		await this._leaguesApiProvider.removeUserFromPredictorLeague(params);
		const users = this._leagueUsers?.users || [];
		runInAction(() => {
			if (this._leagueUsers) {
				this._leagueUsers.users = users.filter((user) => user.id !== params.userId);
			}
		});
	}

	@action
	async removeLeague(leagueId: number): Promise<void> {
		const {data} = await this._leaguesApiProvider.removeLeague({
			id: leagueId,
		});

		runInAction(() => {
			if (data?.deletePredictorLeague) {
				this._userLeagues = this._userLeagues.filter((it) => it.id !== leagueId);
			}
		});
	}
}
