import styled from "@emotion/styled";
import React from "react";
import {IconButton, InputAdornment, Typography} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {linkStyles} from "views/components/links/link_styled/link_styled.component";

export const Form = styled.form`
	display: flex;
	flex-flow: column;
	gap: 20px;
`;

interface IButtonViewPasswordProps {
	isVisible: boolean;
	onClick: () => void;
}

export const ButtonViewPassword: React.FC<IButtonViewPasswordProps> = ({isVisible, onClick}) => (
	<InputAdornment position="end">
		<IconButton onClick={onClick}>
			<Exist when={isVisible}>
				<Visibility />
			</Exist>
			<Exist when={!isVisible}>
				<VisibilityOff />
			</Exist>
		</IconButton>
	</InputAdornment>
);

export const CheckboxLabelTypography = styled(Typography)`
	font-size: 14px;

	a {
		${linkStyles};
		color: ${(props) => props.theme.palette.primary.main};
		font-weight: 500;
	}
` as typeof Typography;
