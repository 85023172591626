import {Container, ContainerModule, interfaces} from "inversify";
import {IUserStore, UserStore} from "data/stores/user/user.store";
import {HeaderController, IHeaderController} from "views/components/header/header.controller";

import {AuthController, IAuthController} from "views/controllers/auth/auth.controller";
import {
	CreateLeagueController,
	ICreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {
	ILeaguesToJoinController,
	LeaguesToJoinController,
} from "views/pages/leagues_to_join/leagues_to_join.controller";
import {AWS_AMPLIFY_CONFIG, GRAPHQL_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {InMemoryCache} from "@apollo/client/cache/inmemory/inMemoryCache";
import {
	GraphQLClientFactory,
	type IGraphQLClient,
} from "data/services/graphql/grapql_client_factory.service";
import {
	GameplayApiProvider,
	type IGameplayApiProvider,
} from "data/providers/api/gameplay.api.provider";
import {GameplayStore, type IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {
	FormLoginController,
	type IFormLoginController,
} from "views/components/forms/login/form_login.controller";
import {
	FormRegistrationController,
	type IFormRegistrationController,
} from "views/components/forms/registration/form_registration.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {type IModalsController, ModalsController} from "views/controllers/modals/modals.controller";
import {HomeController, type IHomeController} from "views/pages/home/home.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type ILocalizationApiProvider,
	LocalizationApiProvider,
} from "data/providers/api/localization.api.provider";
import {
	GameGateController,
	type IGameGateController,
} from "views/components/game_gate/game_gate.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	ContestController,
	type IContestController,
} from "views/controllers/contest/contest.controller";
import GeniusSportsMessageBus from "@geniussports/gsm-web-core-library/lib/genius-sports-message-bus";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type IInviteLeagueController,
	InviteLeagueController,
} from "views/components/leagues/league_invite_form/league.invite_form.controller";
import {
	type ISettingsLeagueController,
	SettingsLeagueController,
} from "views/pages/league_settings/league_settings.controller";
import {
	ContestBannerController,
	type IContestBannerController,
} from "views/components/contest/contest_banner/contest_banner.contoller";
import {
	type ILeagueUsersController,
	LeagueUsersController,
} from "views/components/leagues/league_users/league_users.controller";
import {
	type IMyAccountDetailsController,
	MyAccountDetailsController,
} from "views/components/my_account/my_account_details/my_account_details.controller";
import {
	type IMyAccountChangePasswordController,
	MyAccountChangePasswordController,
} from "views/components/my_account/my_account_change_password/my_account_change_password.controller";
import {
	type ISettingsLeagueFormController,
	SettingsLeagueFormController,
} from "views/components/leagues/league_settings_form/league_settings_form.controller";
import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {
	ContestQuestionCardController,
	type IContestQuestionCardController,
} from "views/components/contest/contest_question_card/contest_question_card.controller";
import {
	ForgotPasswordFormController,
	type IForgotPasswordFormController,
} from "views/components/forms/forgot_password_form/forgot_password_form.controller";
import {
	type IStandingsController,
	StandingsController,
} from "views/controllers/standings/standings.controller";
import {
	type IStandingsApiProvider,
	StandingsApiProvider,
} from "data/providers/api/standings.api.provider";
import {IStandingsStore, StandingsStore} from "data/stores/standings/standings.store";
import {
	FormResetPasswordController,
	type IFormResetPasswordController,
} from "views/components/forms/reset_password/form_reset_password.controller";
import {HelpController, IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {FormValidationHelper, type IFormValidationHelper} from "data/utils/form_validation_helper";
import {
	type ILeagueControlsController,
	LeagueControlsController,
} from "views/components/leagues/league_controls/league_controls.controller";
import {Bindings} from "data/constants/bindings";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {type ISsoProvider, SsoFactoryProvider} from "data/providers/sso/sso_factory.provider";
import {type IPrizesController, PrizesController} from "views/pages/prizes/prizes.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IGraphQLClient>(Bindings.GraphQLClient).toConstantValue(
		GraphQLClientFactory.createClient({
			uri: GRAPHQL_URL,
			cache: new InMemoryCache(),
		})
	);

	bind<GeniusSportsMessageBus>(GeniusSportsMessageBus).toConstantValue(
		new GeniusSportsMessageBus()
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<ISsoProvider>(Bindings.SsoProvider).toConstantValue(
		SsoFactoryProvider.configureClient(AWS_AMPLIFY_CONFIG)
	);
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IGameplayApiProvider>(Bindings.GameplayApiProvider).to(GameplayApiProvider);
	bind<ILocalizationApiProvider>(Bindings.LocalizationApiProvider).to(LocalizationApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IStandingsApiProvider>(Bindings.StandingsApiProvider).to(StandingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IGameplayStore>(Bindings.GameplayStore).to(GameplayStore).inSingletonScope();
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IStaticContentStore>(Bindings.StaticContentStore)
		.to(StaticContentStore)
		.inSingletonScope();
	bind<IStandingsStore>(Bindings.StandingsStore).to(StandingsStore);
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IFormLoginController>(Bindings.FormLoginController).to(FormLoginController);
	bind<IFormRegistrationController>(Bindings.FormRegistrationController).to(
		FormRegistrationController
	);
	bind<IModalsController>(Bindings.ModalsController).to(ModalsController);
	bind<IHomeController>(Bindings.HomeController).to(HomeController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IGameGateController>(Bindings.GameGateController).to(GameGateController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<ILeaguesToJoinController>(Bindings.LeaguesToJoinController).to(LeaguesToJoinController);
	bind<IContestController>(Bindings.ContestController).to(ContestController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<IInviteLeagueController>(Bindings.InviteLeagueController).to(InviteLeagueController);
	bind<ILeagueUsersController>(Bindings.LeagueUsersController).to(LeagueUsersController);
	bind<ILeagueControlsController>(Bindings.LeagueControlsController).to(LeagueControlsController);
	bind<ISettingsLeagueController>(Bindings.SettingsLeagueController).to(SettingsLeagueController);
	bind<ISettingsLeagueFormController>(Bindings.SettingsLeagueFormController).to(
		SettingsLeagueFormController
	);
	bind<IContestBannerController>(Bindings.ContestBannerController).to(ContestBannerController);
	bind<IMyAccountDetailsController>(Bindings.MyAccountDetailsController).to(
		MyAccountDetailsController
	);
	bind<IMyAccountChangePasswordController>(Bindings.MyAccountChangePasswordController).to(
		MyAccountChangePasswordController
	);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<IContestQuestionCardController>(Bindings.ContestQuestionCardController).to(
		ContestQuestionCardController
	);
	bind<IForgotPasswordFormController>(Bindings.ForgotPasswordFormController).to(
		ForgotPasswordFormController
	);
	bind<IFormResetPasswordController>(Bindings.FormResetPasswordController).to(
		FormResetPasswordController
	);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);

	bind<IStandingsController>(Bindings.StandingsController).to(StandingsController);

	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);

	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<IPrizesController>(Bindings.PrizesController).to(PrizesController);
});

export const helpers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IFormValidationHelper>(Bindings.FormValidationHelper).to(FormValidationHelper);
});
