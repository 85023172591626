import React, {useState} from "react";
import {observer} from "mobx-react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {FormControl, FormHelperText, TextField} from "@mui/material";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IFormResetPasswordController} from "views/components/forms/reset_password/form_reset_password.controller";
import styled from "@emotion/styled";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {Exist} from "views/components/exist/exist.component";
import {
	EMAIL_REGEXP_STR,
	FORM_VALIDATION_ELEMENT_CLASSNAME,
	PASSWORD_REGEXP_STR,
} from "data/constants";
import {ButtonViewPassword} from "views/components/forms/common/forms_common.component";
import {Bindings} from "data/constants/bindings";

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 42px;
		height: 42px;
	}
`;

const Title = styled.h3`
	font-family: var(--fontFamilyBase);
	color: var(--textColor3);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.2px;
	margin-bottom: 15px;
`;

const inputProps = {className: FORM_VALIDATION_ELEMENT_CLASSNAME};

export const FormResetPasswordComponent: React.FC = observer(() => {
	const controller = useViewController<IFormResetPasswordController>(
		Bindings.FormResetPasswordController
	);
	const {i18n, error, formValues, handleFormChange, submit, formErrors} = controller;

	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const handleToggleShowPassword = () => setPasswordVisible(!isPasswordVisible);

	const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
	const handleToggleShowConfirmPassword = () =>
		setConfirmPasswordVisible(!isConfirmPasswordVisible);

	const errorResetCode = formErrors["code"];
	const errorEmail = formErrors["email"];
	const errorPassword = formErrors["password"];
	const errorConfirmPassword = formErrors["confirmPassword"];

	return (
		<form
			onChange={handleFormChange}
			onSubmit={submit}
			noValidate
			className="reset-password-form">
			<IconWrapper>
				<CheckCircleIcon color="primary" width={42} height={42} />
			</IconWrapper>
			<Title>{i18n.t("password.reset.title", "Reset Password")}</Title>
			<FormControl fullWidth>
				<TextField
					label={i18n.t("password.reset.code", "Code")}
					name="code"
					value={formValues.code}
					required
					inputProps={inputProps}
					error={Boolean(errorResetCode)}
					helperText={errorResetCode}
				/>
			</FormControl>
			<FormControl fullWidth>
				<TextField
					label={i18n.t("login_form.label.email", "Email")}
					name="email"
					type="email"
					value={formValues.email}
					required
					inputProps={{
						pattern: EMAIL_REGEXP_STR,
						className: inputProps.className,
					}}
					error={Boolean(errorEmail)}
					helperText={errorEmail}
				/>
			</FormControl>
			<FormControl fullWidth>
				<TextField
					label={i18n.t("login_form.label.password", "Password")}
					name="password"
					type={isPasswordVisible ? "text" : "password"}
					value={formValues.password}
					required
					inputProps={{
						pattern: PASSWORD_REGEXP_STR,
						className: inputProps.className,
					}}
					InputProps={{
						sx: {paddingRight: 0},
						endAdornment: (
							<ButtonViewPassword
								isVisible={isPasswordVisible}
								onClick={handleToggleShowPassword}
							/>
						),
					}}
					error={Boolean(errorPassword)}
					helperText={errorPassword}
				/>
			</FormControl>
			<FormControl fullWidth>
				<TextField
					label={i18n.t("password.reset.repeat", "Repeat Password")}
					name="confirmPassword"
					type={isConfirmPasswordVisible ? "text" : "password"}
					value={formValues.confirmPassword}
					required
					inputProps={{
						pattern: PASSWORD_REGEXP_STR,
						className: inputProps.className,
					}}
					InputProps={{
						sx: {paddingRight: 0},
						endAdornment: (
							<ButtonViewPassword
								isVisible={isConfirmPasswordVisible}
								onClick={handleToggleShowConfirmPassword}
							/>
						),
					}}
					error={Boolean(errorConfirmPassword)}
					helperText={errorConfirmPassword}
				/>
			</FormControl>
			<Exist when={error}>
				<FormHelperText error>{error}</FormHelperText>
			</Exist>
			<ButtonPrimary sx={{mt: 1.5}} size="large" fullWidth type="submit">
				{i18n.t("forgot.request.submit", "Submit")}
			</ButtonPrimary>
		</form>
	);
});
