import React, {Fragment, useEffect} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IContestBannerController} from "views/components/contest/contest_banner/contest_banner.contoller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {
	ContestCountdown,
	ContestCountdownWrapper,
} from "views/components/contest/contest_countdown/contest_countdown.component";
import {observer} from "mobx-react";

export const ContestDate: React.FC<IContestFragment> = observer((contest) => {
	const controller = useViewController<IContestBannerController>(
		Bindings.ContestBannerController,
		{contest}
	);

	const {id, dateStart} = contest;

	const {
		i18n,
		setContestComplete,
		updateContest,
		isDateVisible,
		isTimerVisible,
		isOpen,
		isComingSoon,
		isComplete,
		lockDateString,
	} = controller;

	const isScheduled = isOpen || isComingSoon;

	useEffect(() => {
		updateContest(contest);
	}, [updateContest, contest]);

	return (
		<Fragment>
			<Exist when={isScheduled}>
				<Exist when={isTimerVisible}>
					<ContestCountdown
						contestID={id}
						date={dateStart}
						onComplete={setContestComplete}>
						{i18n.t("contest.date.locks_in", "Locks in")}{" "}
					</ContestCountdown>
				</Exist>
				<Exist when={isDateVisible}>
					<ContestCountdownWrapper>
						{i18n.t("contest.date.locks_on", "Locks on")} {lockDateString}
					</ContestCountdownWrapper>
				</Exist>
			</Exist>
			<Exist when={!isScheduled}>
				<ContestCountdownWrapper>
					{isComplete
						? i18n.t("contest.date.complete", "Complete")
						: i18n.t("contest.date.in_progress", "In progress")}
				</ContestCountdownWrapper>
			</Exist>
		</Fragment>
	);
});
