import Amplify, {Auth} from "aws-amplify";
import {CognitoUser} from "amazon-cognito-identity-js";

/**
 * Setup documentation may be found here:
 * https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
 */
interface IConfig {
	Auth: {
		region: string;
		identityPoolRegion: string;
		userPoolId: string;
		userPoolWebClientId: string;
		authenticationFlowType: "USER_PASSWORD_AUTH";
	};
}

type IAuth = typeof Auth;

type ExcludeFirst<T extends unknown[]> = T extends [unknown, ...infer Rest] ? Rest : unknown[];

export interface ISsoProvider {
	currentSession: IAuth["currentSession"];
	currentAuthenticatedUser: (
		...params: Parameters<IAuth["currentAuthenticatedUser"]>
	) => Promise<CognitoUser>;
	forgotPassword: IAuth["forgotPassword"];
	forgotPasswordSubmit: IAuth["forgotPasswordSubmit"];
	signIn: (...params: Parameters<IAuth["signIn"]>) => Promise<CognitoUser>;
	signUp: IAuth["signUp"];
	signOut: IAuth["signOut"];
	changePassword: (
		user: CognitoUser,
		...params: ExcludeFirst<Parameters<IAuth["changePassword"]>>
	) => Promise<"SUCCESS">;
	updateUserAttributes: IAuth["updateUserAttributes"];
}

export abstract class SsoFactoryProvider {
	static configureClient(config: IConfig) {
		Amplify.configure(config);
		return Auth;
	}
}
