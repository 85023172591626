import styled from "@emotion/styled";
import {Container} from "@mui/material";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React from "react";
import {IconFB} from "views/components/icons/icon_fb";
import {IconInstagram} from "views/components/icons/icon_instagram";
import {IconPoweredGs} from "views/components/icons/icon_powered_gs";
import {IconTiktok} from "views/components/icons/icon_tiktok";
import {IconTwitter} from "views/components/icons/icon_twitter";
import {IconYouTube} from "views/components/icons/icon_youtube";
import {LinkStyled} from "views/components/links/link_styled/link_styled.component";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import {FOOTER_LINKS} from "data/constants";
import {first} from "lodash";
import {getLogoByGame} from "data/utils/images";

const mobileViewQuery = `max-width: 860px`;

const Background = styled.div`
	background: ${(props) => props.theme.palette.primary.main} no-repeat left bottom;
`;

const Wrapper = styled.footer`
	padding: 54px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--textColor4);

	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	@media (${mobileViewQuery}) {
		flex-direction: column;
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		padding: 20px;
	}
`;

const SiteInfo = styled.div``;

const Socials = styled(SiteInfo)`
	display: flex;
	align-items: flex-end;
	margin-left: auto;

	p {
		text-align: left;
		padding-left: 8px;
		color: var(--textColor4);
		text-transform: uppercase;
	}

	ul {
		display: flex;
		margin-top: 8px;
		align-items: flex-end;

		li {
			padding: 0 8px;
			transition: color 0.5s ease-in-out;
			cursor: pointer;
			display: flex;
			align-items: flex-end;

			a {
				display: flex;
				align-items: flex-end;
			}

			&:last-child {
				padding-right: 0;
			}

			svg {
				width: 22px;
				height: 22px;
			}

			&:hover {
				color: var(--textColor5);
			}
		}
	}

	@media (${mobileViewQuery}) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 10px;
		padding: 24px 10px 0;
		flex-flow: column;
		width: 100%;

		p {
			font-size: 16px;
			line-height: 20px;
			padding: 0;
		}
	}
`;

const Follow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-weight: 700;

	@media (max-width: 860px) {
		align-items: center;
	}
`;

const GeniusSportsLink = styled.a`
	transition: color 0.5s ease-in-out;
	display: flex;
	justify-content: flex-end;
	margin-left: 32px;

	&:hover {
		color: var(--textColor5);
	}

	@media (${mobileViewQuery}) {
		margin-top: 20px;
		margin-left: 0;
		svg {
			width: 103px;
		}
	}
`;

const Copyrights = styled.div`
	font-weight: 700;
`;

const UsefulLing = styled.div`
	margin-top: 13px;
	display: flex;
	gap: 74px;

	@media (max-width: 840px) {
		gap: 50px;
		justify-content: center;
	}

	@media (max-width: 640px) {
		gap: 30px;
	}

	a {
		font-weight: 700;
		color: var(--textColor4);

		&[target="_blank"] {
			background-size: 0 1px;

			&:hover {
				background-size: 100% 1px;
			}
		}
	}
`;

const GameLogo = styled.img`
	max-height: 52px;
	max-width: 100%;
	margin-right: 24px;
	@media (max-width: 840px) {
		max-width: 90%;
		margin-right: 0;
		margin-bottom: 16px;
	}
`;

export const Footer: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const links = FOOTER_LINKS[i18n.locale ?? ""] || first(Object.values(FOOTER_LINKS));

	return (
		<Background>
			<Container>
				<Wrapper>
					<GameLogo src={getLogoByGame()} />
					<SiteInfo>
						<Copyrights>
							{i18n.t(
								"footer.copyright",
								"© 2022 Los Angeles Rams. All Rights reserved."
							)}
						</Copyrights>
						<UsefulLing>
							<div>
								<LinkStyled
									target="_blank"
									rel="noopener noreferrer"
									href={links.terms}>
									{i18n.t("footer.terms_of_use", "Terms Of Use")}
								</LinkStyled>
							</div>
							<div>
								<LinkStyled
									target="_blank"
									rel="noopener noreferrer"
									href={links.privacy}>
									{i18n.t("footer.privacy_policy", "Privacy Policy")}{" "}
								</LinkStyled>
							</div>
						</UsefulLing>
					</SiteInfo>

					<Socials>
						<Follow>
							<p>{i18n.t("footer.follow_rams", "Follow LA Rams")}</p>
							<ul>
								<li>
									<a
										href={links.twitter}
										target="_blank"
										rel="noopener noreferrer">
										<IconTwitter />
									</a>
								</li>
								<li>
									<a
										href={links.facebook}
										target="_blank"
										rel="noopener noreferrer">
										<IconFB />
									</a>
								</li>
								<li>
									<a
										href={links.instagram}
										target="_blank"
										rel="noopener noreferrer">
										<IconInstagram />
									</a>
								</li>
								<li>
									<a
										href={links.youtube}
										target="_blank"
										rel="noopener noreferrer">
										<IconYouTube />
									</a>
								</li>
								<li>
									<a
										href={links.tickToc}
										target="_blank"
										rel="noopener noreferrer">
										<IconTiktok />
									</a>
								</li>
							</ul>
						</Follow>
						<GeniusSportsLink
							href="https://geniussports.com/"
							target="_blank"
							rel="noopener noreferrer">
							<IconPoweredGs />
						</GeniusSportsLink>
					</Socials>
				</Wrapper>
			</Container>
		</Background>
	);
});
