import {inject, injectable} from "inversify";
import type {IGraphQLClient} from "data/services/graphql/grapql_client_factory.service";
import gql from "data/graphql";
import {ApolloQueryResult} from "@apollo/client";
import {Bindings} from "data/constants/bindings";
import {ENVIRONMENT} from "data/constants";

type TGameInfoResult = ApolloQueryResult<IGetGameInfoQuery>;

export interface ILocalizationApiProvider {
	gameInfo: (variables: IGetGameInfoQueryVariables) => Promise<TGameInfoResult>;
}

const IS_REQUEST_DISABLED = true;

@injectable()
export class LocalizationApiProvider implements ILocalizationApiProvider {
	constructor(@inject(Bindings.GraphQLClient) private _client: IGraphQLClient) {}

	gameInfo = (variables: IGetGameInfoQueryVariables) => {
		if (!IS_REQUEST_DISABLED) {
			return this._client.query<IGetGameInfoQuery, IGetGameInfoQueryVariables>({
				query: gql.queries.GET_GAME_INFO,
				variables,
			});
		}

		return import(`data/providers/api/locale_configs/${ENVIRONMENT}.json`).then((result) => {
			return (result as {default: TGameInfoResult}).default;
		});
	};
}
