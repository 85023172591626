import React, {useCallback, useState} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {FORM_VALIDATION_ELEMENT_CLASSNAME} from "data/constants";
import {Exist} from "views/components/exist/exist.component";
import {ICustomFieldProps} from "data/types/global";
import {Bindings} from "data/constants/bindings";

export const Dropdown: React.FC<ICustomFieldProps> = (props) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const {id, type, name, data, code, onChange, error, defaultValue, disabled} = props;
	const [selectedValue, setValue] = useState(defaultValue ? String(defaultValue) : "");
	const labelID = `label-${type}-custom-field-${id}`;
	const elementID = `${type}-custom-field-${id}`;
	const isRequired = data?.required || false;
	const fieldName = i18n.findValueByLocale(name);

	const handleChange = useCallback(
		(event: SelectChangeEvent) => {
			const value = event.target.value;
			setValue(value);
			onChange({id, value, name: code});
		},
		[id, code, onChange]
	);

	return (
		<FormControl fullWidth disabled={disabled}>
			<InputLabel id={labelID} required={isRequired}>
				{fieldName}
			</InputLabel>
			<Select
				disabled={disabled}
				value={selectedValue}
				onChange={handleChange}
				name={code}
				labelId={labelID}
				id={elementID}
				label={fieldName}
				required={isRequired}
				error={Boolean(error)}
				classes={{
					nativeInput: FORM_VALIDATION_ELEMENT_CLASSNAME,
				}}>
				{data.options?.map(({id, name}) => (
					<MenuItem key={id} value={id}>
						{i18n.findValueByLocale(name)}
					</MenuItem>
				))}
			</Select>
			<Exist when={error}>
				<FormHelperText error>{error}</FormHelperText>
			</Exist>
		</FormControl>
	);
};
