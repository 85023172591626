import {ApolloError} from "@apollo/client";
import {ModalType, RequestState, ShareType, SocialNetwork} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {copyToClipboard, trackSentryErrors} from "data/utils";
import {ShareUtility} from "data/utils/social_share/social_share.utility";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {uniqBy} from "lodash";

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface IInviteLeagueController extends ViewController<IInit> {
	i18n: ILocalizationStore;

	inviteCount: number;
	copyState: RequestState;

	isInvalidEmails: boolean;
	invalidEmailsHelperMessage: string;
	league: ILeagueFragment | undefined;

	get inviteEmailState(): RequestState;

	copyCode(): Promise<void>;

	clearErrorMessage(): void;

	inviteEmails(emailsString: string): Promise<void>;

	updateLeagueId(leagueId: number): void;

	shareTw(): void;

	shareFB(): void;

	shareMobile(): void;

	updateInviteState(): void;

	dispose(): void;
}

@injectable()
export class InviteLeagueController implements IInviteLeagueController {
	private _league: ILeagueFragment | undefined;

	@observable private _copyState: RequestState = RequestState.IDLE;
	@observable private _inviteEmailsState: RequestState = RequestState.IDLE;
	@observable private _invalidEmails: string[] = [];
	@observable public inviteCount: number = 0;

	private timeout: ReturnType<typeof setTimeout> | undefined = undefined;
	private _navigate: IInit["navigate"];

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get copyState() {
		return this._copyState;
	}

	get league(): ILeagueFragment | undefined {
		return this._league;
	}

	get isInvalidEmails() {
		return !!this._invalidEmails.length;
	}

	get inviteEmailState() {
		return this._inviteEmailsState;
	}

	get invalidEmailsHelperMessage() {
		if (this.isInvalidEmails) {
			return `Incorrect emails - ${this._invalidEmails.join(", ")}`;
		}

		return this.i18n.t(
			"league.invite.email_description",
			"Emails should be separated by commas or new lines"
		);
	}

	@action
	clearErrorMessage() {
		this._invalidEmails = [];
		this._inviteEmailsState = RequestState.IDLE;
	}

	@action
	async inviteEmails(emailsString: string) {
		const league = this._league;

		if (league) {
			const EMAIL_REGEXP = "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$";
			const emailsByNewLine = emailsString.replace(/\n/g, ",").replace(/,+/, ",");
			const emailsArray = emailsByNewLine.split(",").map((it) => it.trim());
			const invalidEmails = emailsArray.filter(
				(it) => it.length && !new RegExp(EMAIL_REGEXP).exec(it)
			);

			if (invalidEmails.length) {
				this._invalidEmails = invalidEmails;
				return;
			}

			const identityEmails = uniqBy(
				emailsArray.filter(Boolean).map((email) => ({
					email,
				})),
				"email"
			);

			this._inviteEmailsState = RequestState.PENDING;

			return this._leaguesStore
				.inviteLeague({
					leagueId: league.id,
					emails: identityEmails,
				})
				.then((_e) => {
					runInAction(() => {
						this._inviteEmailsState = RequestState.SUCCESS;
					});
				})
				.catch((err) => {
					trackSentryErrors(err, {}, "league invite");
					runInAction(() => {
						this._inviteEmailsState = RequestState.ERROR;
					});

					this._modalsStore.showModal(ModalType.ERROR, {
						message: (err as ApolloError).message,
					});
				});
		}
	}

	copyCode() {
		this.timeout = setTimeout(() => {
			this._copyState = RequestState.IDLE;
		}, 5000);

		if (!this._league) {
			this._copyState = RequestState.ERROR;
			return Promise.resolve();
		}

		return copyToClipboard(this._league.code).then((result) => {
			runInAction(() => {
				if (result) {
					this._copyState = RequestState.SUCCESS;
				} else {
					this._copyState = RequestState.ERROR;
				}
			});
		});
	}

	get _shareMessage() {
		return this.i18n.t(
			"league.share.message",
			"Come and join my CFL Blitz Picks league! Join via the link below or by using the league pin: {{X}}",
			{X: this.league?.code}
		);
	}

	updateLeagueId(leagueId: number) {
		this._league = this._leaguesStore.getLeagueById(leagueId);
	}

	shareTw() {
		const league = this._league;

		if (league) {
			ShareUtility.share({
				message: this._shareMessage,
				type: ShareType.LEAGUE,
				leagueId: league.id,
				socialNetwork: SocialNetwork.TWITTER,
				locale: this.i18n.locale,
			});
		}
	}

	shareFB() {
		const league = this._league;
		if (league) {
			ShareUtility.share({
				message: this._shareMessage,
				type: ShareType.LEAGUE,
				leagueId: league.id,
				socialNetwork: SocialNetwork.FACEBOOK,
				locale: this.i18n.locale,
			});
		}
	}

	shareMobile() {
		const league = this._league;
		if (league) {
			ShareUtility.share({
				message: this._shareMessage,
				type: ShareType.LEAGUE,
				leagueId: league.id,
				socialNetwork: SocialNetwork.MOBILE,
				locale: this.i18n.locale,
			});
		}
	}

	updateInviteState() {
		this._inviteEmailsState = RequestState.IDLE;
	}

	init({navigate}: IInit): void {
		this._navigate = navigate;
	}

	dispose(): void {
		clearTimeout(this.timeout);
	}
}
