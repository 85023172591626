import * as fragments from "./fragments";
import * as mutations from "./mutations";
import * as queries from "./queries";

const gql = {
	fragments,
	mutations,
	queries,
};

export {fragments, mutations, queries};

export default gql;
