import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import type {IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {AwsAmplifyError, COGNITO_CUSTOM_FIELD_NAME, GAME_ID, GraphQLError} from "data/constants";
import {ErrorResponse} from "@apollo/client/link/error";
import {isEmpty, sortBy} from "lodash";
import {Bindings} from "data/constants/bindings";
import type {ISsoProvider} from "data/providers/sso/sso_factory.provider";
import {trackSentryErrors} from "data/utils";

export interface ILoginPayload {
	email: string;
	password: string;
	validationData?: Record<string, unknown>;
	locale: string;
}

export interface IGeneralTempUserData {
	email?: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	username?: string;
}

export interface ITempUserData extends IGeneralTempUserData {
	customFields?: IUserCustomFieldValueFragment[];
	isNotificationEnabled?: boolean;
	[name: string]: unknown;
}

export interface IRegistrationPayload extends Required<IGeneralTempUserData> {
	customFields?: IUserCustomFieldValueFragment[];
	locale: string;
}

interface ICognitoUserAttributes extends Partial<Record<string, string>> {
	given_name?: string;
	family_name?: string;
	email?: string;
	nickname?: string;
	"custom:custom_fields"?: string;
}

export interface IResetPasswordPayload {
	email: string;
	code: string;
	password: string;
}

export interface IResetPasswordFromAccountPayload {
	user?: CognitoUser;
	oldPassword: string;
	newPassword: string;
}

export interface IUpdateUser extends Omit<IUpdateUserMutationVariables, "customFields"> {
	customFields?: IUserCustomFieldValueFragment[];
}

export interface IUserStore {
	clearUsedData: () => Promise<unknown>;
	refreshAccessToken: () => Promise<string>;
	requestRegistrationCustomFields: () => Promise<void>;

	get user(): IUserFragment | undefined;

	get tmpUserData(): ITempUserData | null;

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	get token(): string | null;

	get isSessionChecked(): boolean;

	get registrationCustomFields(): ICustomFieldFragment[] | null;

	getCognitoUserAttributes(): Promise<ICognitoUserAttributes>;

	setTmpUserData(data: ITempUserData): void;

	restoreSession(): Promise<IUserFragment | void>;

	checkUsername(
		variables: ICheckUsernameQueryVariables | ICheckUsernameAuthorizedQueryVariables
	): Promise<boolean>;

	login(payload: ILoginPayload): Promise<IUserFragment>;

	register(payload: IRegistrationPayload): Promise<IUserFragment>;

	forgotPassword(email: string): Promise<unknown>;

	resetPassword(payload: IResetPasswordPayload): Promise<unknown>;

	changePassword(payload: IResetPasswordFromAccountPayload): Promise<string>;

	logout(): Promise<unknown>;

	updateUser(params: IUpdateUser): Promise<unknown>;
}

@injectable()
export class UserStore implements IUserStore {
	static notAuthorizedError = "User is not authorized";

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApiProvider: IAuthApiProvider,
		@inject(Bindings.SsoProvider) private _authProvider: ISsoProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _user?: IUserFragment = undefined;
	@observable private _cognitoUser?: CognitoUser = undefined;
	@observable private _tmpUserData: ITempUserData | null = null;
	@observable private _checkUserNameRequest?: ReturnType<
		IAuthApiProvider["checkUsername"] | IAuthApiProvider["checkUsernameAuthorized"]
	>;

	get user() {
		return this._user;
	}

	get tmpUserData() {
		return this._tmpUserData;
	}

	@observable private _wasLoggedOut = false;

	get wasLoggedOut(): boolean {
		return this._wasLoggedOut;
	}

	@observable private _token: string | null = null;

	get token() {
		return this._token;
	}

	@observable private _isSessionChecked = false;

	get isSessionChecked() {
		return this._isSessionChecked;
	}

	@observable private _registrationCustomFields: ICustomFieldFragment[] | null = null;

	get registrationCustomFields() {
		return this._registrationCustomFields;
	}

	get phoneNumberCustomField(): IUserCustomFieldValueFragment | undefined {
		const customField = this.registrationCustomFields?.find((field) => field.type === "Phone");
		return this._tmpUserData?.customFields?.find((field) => field.id === customField?.id);
	}

	get isAuthorized(): boolean {
		return Boolean(this._isSessionChecked && this._user);
	}

	private static createCustomFieldObject(customFields?: IUserCustomFieldValueFragment[]) {
		if (isEmpty(customFields)) {
			return {};
		}

		return {[COGNITO_CUSTOM_FIELD_NAME]: JSON.stringify(customFields)};
	}

	private async getAccessToken() {
		const authSession = await this._authProvider.currentSession();
		return authSession.getIdToken().getJwtToken();
	}

	async updateUser(params: IUpdateUser): Promise<IUserFragment> {
		return this.updateAttributes({
			given_name: params.firstName,
			family_name: params.lastName,
			email: params.email,
			nickname: params.username,
			...UserStore.createCustomFieldObject(params.customFields),
		})
			.then(async () => {
				const {data} = await this._authApiProvider.updateUser(params);
				return data!.updateUser;
			})
			.then(this.storeUserOnSuccessPlatformAuth);
	}

	@action restoreSession() {
		return this._authProvider
			.currentAuthenticatedUser()
			.then((user) => runInAction(() => (this._cognitoUser = user)))
			.then(this.refreshAccessToken)
			.then(this.loginUserInPlatform)
			.then(this.storeUserOnSuccessPlatformAuth)
			.catch((error) => {
				trackSentryErrors(error, {}, "restoreSession");
			})
			.finally(() =>
				runInAction(() => {
					this._isSessionChecked = true;
				})
			);
	}

	private syncCognitoUserWithApi = async () => {
		const {
			email = "",
			nickname = "",
			given_name = "",
			family_name = "",
			[COGNITO_CUSTOM_FIELD_NAME]: customFieldsStr = "[]",
		} = await this.getCognitoUserAttributes();

		const {data} = await this._authApiProvider.updateUser({
			email,
			username: nickname,
			firstName: given_name,
			lastName: family_name,
			customFields: JSON.parse(customFieldsStr) as IUserCustomFieldValueFragment[],
		});

		return data!.updateUser;
	};

	async forgotPassword(email: string): Promise<unknown> {
		return this._authProvider.forgotPassword(email);
	}

	async resetPassword({email, code, password}: IResetPasswordPayload): Promise<unknown> {
		return this._authProvider.forgotPasswordSubmit(email, code, password);
	}

	@action private setCognitoUser = (cognitoUser: CognitoUser) => {
		return this.getAccessToken().then((token) =>
			runInAction(() => {
				this._token = token;
				this._cognitoUser = cognitoUser;
			})
		);
	};

	@action private clearTmpValues = <T>(args: T) => {
		this._wasLoggedOut = false;
		this._tmpUserData = null;

		return args;
	};

	private onAuthError = async (error: AwsAmplifyError) => {
		trackSentryErrors(error, {}, "onAuthError");
		await this.clearUsedData();
		throw error;
	};

	async checkUsername(
		variables: ICheckUsernameQueryVariables | ICheckUsernameAuthorizedQueryVariables
	) {
		this._checkUserNameRequest?.cancel();
		const method = this.isAuthorized ? "checkUsernameAuthorized" : "checkUsername";
		this._checkUserNameRequest = this._authApiProvider[method](variables);

		return this._checkUserNameRequest.then(
			(result) => result.data[method as keyof typeof result.data]
		);
	}

	@action
	async login({email, password, locale}: ILoginPayload): Promise<IUserFragment> {
		return this._authProvider
			.signIn({
				username: email,
				password,
			})
			.then(this.setCognitoUser)
			.then(this.loginUserInPlatform)
			.then((user) =>
				this.syncCognitoUserWithApi().catch((error) => {
					trackSentryErrors(error, {user}, "syncCognitoUserWithApi");
					return user;
				})
			)
			.catch((err: ErrorResponse) => {
				trackSentryErrors(err, {}, "Login flow");

				if (GraphQLError.isNotFound(err.graphQLErrors)) {
					return this.registerUserInPlatform(locale);
				}

				throw err;
			})
			.then(this.storeUserOnSuccessPlatformAuth)
			.then(this.clearTmpValues)
			.catch(this.onAuthError);
	}

	@action register({
		email,
		password,
		firstName,
		lastName,
		username,
		customFields,
		locale,
	}: IRegistrationPayload): Promise<IUserFragment> {
		return this._authProvider
			.signUp({
				username: email,
				password,
				attributes: {
					given_name: firstName,
					family_name: lastName,
					nickname: username,
					...UserStore.createCustomFieldObject(customFields),
				},
			})
			.then(() =>
				this._authProvider.signIn({
					username: email,
					password,
				})
			)
			.then(this.setCognitoUser)
			.then(() => this.registerUserInPlatform(locale))
			.then(this.storeUserOnSuccessPlatformAuth)
			.then(this.clearTmpValues)
			.catch(this.onAuthError);
	}

	public changePassword(payload: IResetPasswordFromAccountPayload): Promise<string> {
		return this._authProvider.changePassword(
			this._cognitoUser!,
			payload.oldPassword,
			payload.newPassword
		);
	}

	@action
	logout() {
		this._tmpUserData = null;
		this._wasLoggedOut = true;

		return this.clearUsedData();
	}

	@action setTmpUserData(data: ITempUserData) {
		const tmpData = this._tmpUserData || {};

		this._tmpUserData = {
			...tmpData,
			...data,
		};
	}

	@action clearUsedData = () => {
		this._token = null;
		this._user = undefined;
		this._cognitoUser = undefined;

		return this._authProvider.signOut();
	};

	@action refreshAccessToken = () => {
		const user = this._cognitoUser;
		const refreshToken = user?.getSignInUserSession()?.getRefreshToken();
		if (!user || !refreshToken) {
			return Promise.reject(UserStore.notAuthorizedError);
		}

		return new Promise<string>((resolve, reject) => {
			user.refreshSession(refreshToken, (refreshSessionError) => {
				if (refreshSessionError) {
					return reject(refreshSessionError);
				}

				void this.getAccessToken()
					.then((accessToken) => {
						runInAction(() => (this._token = accessToken));
						resolve(accessToken);
					})
					.catch((accessTokenError) => {
						trackSentryErrors(accessTokenError, {}, "refreshSession - getAccessToken");
						return reject(accessTokenError);
					});
			});
		});
	};

	@action requestRegistrationCustomFields = () => {
		return this._authApiProvider
			.customFields({
				gameID: GAME_ID,
			})
			.then((response) =>
				runInAction(() => {
					this._registrationCustomFields = sortBy(response.data.customFields, "orderNum");
				})
			);
	};

	private updateAttributes(attributes: ICognitoUserAttributes): Promise<string> {
		return this._authProvider.updateUserAttributes(this._cognitoUser, attributes);
	}

	@computed
	async getCognitoUserAttributes(): Promise<ICognitoUserAttributes> {
		const attrs: CognitoUserAttribute[] = await new Promise((resolve) => {
			this._cognitoUser?.getUserAttributes((_, attrs) => resolve(attrs || []));
		});

		return attrs.reduce((acc, attr) => ({...acc, [attr.Name]: attr.Value}), {});
	}

	@action private storeUserOnSuccessPlatformAuth = (user: IUserFragment): IUserFragment => {
		this._user = user;
		return user;
	};

	private loginUserInPlatform = async () => {
		return this._authApiProvider
			.login({
				gameID: GAME_ID,
			})
			.then((response) => {
				const user = response.data.login;

				if (!user) {
					throw new Error("User not found");
				}

				return user;
			});
	};

	private registerUserInPlatform = async (locale: string) => {
		const {
			email = "",
			nickname = "",
			given_name = "",
			family_name = "",
			...rest
		} = await this.getCognitoUserAttributes();

		const customFieldsStr = rest[COGNITO_CUSTOM_FIELD_NAME] ?? "[]";
		const customFields = JSON.parse(customFieldsStr) as IUserCustomFieldValueFragment[];

		return this._authApiProvider
			.register({
				locale,
				email: email,
				firstName: given_name,
				lastName: family_name,
				username: nickname,
				customFields,
				gameId: GAME_ID,
			})
			.then((response) => response.data!.registerUser);
	};
}
