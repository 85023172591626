import styled from "@emotion/styled";
import {
	Avatar,
	Divider,
	IconButton,
	ListItemButton,
	ListItemText,
	SwipeableDrawerProps,
	useMediaQuery,
	Box,
	Typography,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React, {Fragment} from "react";
import {NavLink} from "react-router-dom";
import {Exist} from "views/components/exist/exist.component";
import {IHeaderController} from "views/components/header/header.controller";
import {IconMenu} from "views/components/icons/icon_menu";
import {Container} from "views/components/structure/structure.component";
import {Bindings} from "data/constants/bindings";
import {FOOTER_LINKS} from "data/constants";
import {IconTwitter} from "views/components/icons/icon_twitter";
import {IconFB} from "views/components/icons/icon_fb";
import header_bg from "assets/img/header-bg.png";
import {getLogoByGame} from "data/utils/images";

const mobileViewQuery = `max-width: 970px`;

const HeaderWrapper = styled.div`
	background: url(${header_bg}) var(--primaryColor) no-repeat right bottom / auto 100%;
	font-family: var(--fontFamilyBase);
	color: var(--textColor4);
	text-transform: uppercase;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	border-bottom: 2px solid var(--secondaryColor);
`;

const Wrapper = styled(Container)`
	display: flex;
	justify-content: flex-start;
	height: 68px;

	@media (${mobileViewQuery}) {
		padding: 8px 16px;
		min-height: 82px;
		height: auto;
	}
`;

const GameLogoLink = styled(NavLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	@media (${mobileViewQuery}) {
		margin: 0 auto;
	}
`;

const GameLogo = styled.img`
	max-height: 52px;
	max-width: 100%;

	@media (max-width: 480px) {
		max-width: 90%;
	}
`;

const MenuList = styled.div`
	display: flex;
	font-size: 16px;

	button {
		outline: none;
		border: none;
		background: none;
		text-transform: uppercase;
		font-weight: 700;
		font-size: inherit;
		line-height: 20px;
		color: #fff;
	}

	a {
		font-size: inherit;
		font-weight: 700;
		color: #fff;

		&.active {
			color: var(--secondaryColor);
		}
	}
`;

const UserInformation = styled(NavLink)`
	margin-left: 20px;
	position: relative;
	cursor: pointer;

	@media (${mobileViewQuery}) {
		margin-left: 0;
	}
`;

const Menu = styled.menu`
	margin-right: auto;
	display: flex;
	flex: 1;
`;

const IconMenuButton = styled(IconButton)`
	height: 60px;
	margin-right: 0;
	padding: 0;

	svg {
		height: 100%;
	}
`;

const BoxWrapper = styled.div`
	width: 240px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;
	flex-direction: column;
`;

const ListItemFull = styled(ListItemButton)`
	width: 100%;
	height: 48px;
	position: relative;

	svg {
		margin-right: 20px;
	}
`;

const ListItemUser = styled(ListItemFull)`
	height: 72px;
	padding-bottom: 16px;
	padding-top: 16px;
`;

const ListFull = styled.div`
	width: 100%;
`;

const RightSide = styled.div`
	display: flex;
	align-items: center;
`;

const Positioned = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	display: none;
	flex-flow: column;
	background: var(--background3);
	color: var(--textColor7);
	box-shadow: 0 0.1px 0.3px rgba(28, 28, 30, 0.1), 0 1px 2px rgba(28, 28, 30, 0.2);
	border-radius: 0 0 4px 4px;
	width: max-content;
	z-index: 1;

	a {
		white-space: nowrap;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		opacity: 1;
		padding: 8px 12px;
		color: var(--textColor7);

		&:last-child {
			border: none;
		}

		&:hover {
			color: var(--textColor7);
		}

		&.active {
			color: var(--textColor7);
		}
	}

	& > div {
		border-bottom: 1px solid #cccccc;

		&:last-child {
			border-bottom: none;
		}
	}
`;

const RelativeBox = styled(Box)`
	position: relative;
	margin-left: 32px;
	display: flex;
	align-items: center;

	a {
		white-space: nowrap;
	}

	&:hover {
		.sub-menu {
			display: flex;
		}
	}
`;

const Submenu = styled.div`
	padding-left: 20px;
`;

const LinkSpan = styled(Typography)`
	line-height: 32px;
	margin-left: 20px;
`;

const FollowRams = styled.div`
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	gap: 12px;

	svg {
		transition: all 0.5s;
		width: 22px;
		height: 22px;
	}

	a:hover svg {
		opacity: 0.7;
	}
`;

/**
 * Override type casting because of outdated versions of React
 */
const Drawer = SwipeableDrawer as unknown as React.FC<SwipeableDrawerProps>;

export const Header: React.FC = observer(() => {
	const isMobileView = useMediaQuery(`(${mobileViewQuery})`);
	const controller = useViewController<IHeaderController>(Bindings.HeaderController);
	const {i18n, staticPagesMenuList} = controller;
	const showDrawer = () => controller.toggleDrawer(true);
	const hideDrawer = () => controller.toggleDrawer(false);
	const links = FOOTER_LINKS[i18n.locale || "es-ES"];

	return (
		<HeaderWrapper>
			<Wrapper>
				<Exist when={isMobileView}>
					<IconMenuButton
						color="inherit"
						aria-label="open drawer"
						onClick={showDrawer}
						edge="end">
						<IconMenu />
					</IconMenuButton>
					<Drawer
						anchor={"left"}
						open={controller.isOpenDrawer}
						onClose={hideDrawer}
						onOpen={showDrawer}
						BackdropProps={{
							sx: {
								background: "transparent",
							},
						}}
						PaperProps={{
							sx: {
								color: "#FFF",
								background: "var(--primaryColor)",
							},
						}}>
						<BoxWrapper>
							<ListFull>
								<Exist when={controller.isAuthorized}>
									<NavLink to="/my-account">
										<ListItemUser>
											<Avatar
												sx={{
													backgroundColor: "var(--textColor5)",
													color: "#FFF",
												}}>
												{controller.userInitials}
											</Avatar>
											<ListItemText sx={{marginLeft: "15px"}}>
												{controller.userFullName}
											</ListItemText>
										</ListItemUser>
									</NavLink>
									<Divider sx={{borderColor: "var(--background4)"}} />
								</Exist>
								<Exist when={controller.isAuthorized}>
									{controller.mainMenuList.map((item, index) => (
										<Fragment key={index}>
											<div>
												<ListItemButton
													component={NavLink}
													to={item.url}
													onClick={hideDrawer}>
													<LinkSpan>{item.label.toUpperCase()}</LinkSpan>
												</ListItemButton>
												<Exist when={item.children}>
													<Submenu>
														{item.children?.map((subMenuItem) => (
															<ListItemButton
																component={NavLink}
																to={subMenuItem.url}
																key={subMenuItem.url}
																onClick={hideDrawer}>
																<LinkSpan>
																	{subMenuItem.label.toUpperCase()}
																</LinkSpan>
															</ListItemButton>
														))}
													</Submenu>
												</Exist>
											</div>
										</Fragment>
									))}
								</Exist>
								<Exist when={!controller.isAuthorized}>
									<ListItemButton component={NavLink} to="/" onClick={hideDrawer}>
										<LinkSpan>
											{i18n.t("nav.main.home", "Home").toUpperCase()}
										</LinkSpan>
									</ListItemButton>
									{controller.guestMenuList.map((item, index) => (
										<ListItemButton
											key={index}
											onClick={() => {
												item.clickEvent?.();
												hideDrawer();
											}}>
											<LinkSpan>{item.label.toUpperCase()}</LinkSpan>
										</ListItemButton>
									))}
								</Exist>
								<Divider sx={{borderColor: "var(--background4)"}} />
								{staticPagesMenuList.map(({label, url}, index) => (
									<ListItemButton
										key={index}
										component={NavLink}
										to={url}
										onClick={hideDrawer}>
										<LinkSpan>{label.toUpperCase()}</LinkSpan>
									</ListItemButton>
								))}
								<Exist when={controller.isAuthorized}>
									<ListItemButton
										component={NavLink}
										to="/my-account"
										onClick={hideDrawer}>
										<LinkSpan>
											{i18n
												.t("nav.main.my_account", "My Account")
												.toUpperCase()}
										</LinkSpan>
									</ListItemButton>
								</Exist>
							</ListFull>
						</BoxWrapper>
					</Drawer>
				</Exist>
				<GameLogoLink to={controller.rootPath}>
					<GameLogo src={getLogoByGame()} alt="" />
				</GameLogoLink>
				<Exist when={!isMobileView}>
					<Menu>
						<MenuList>
							{controller.isAuthorized
								? controller.mainMenuList.map((item, index) => (
										<RelativeBox key={index}>
											<NavLink to={item.url}>{item.label}</NavLink>
											<Exist when={item.children}>
												<Positioned className={"sub-menu"}>
													{item.children?.map((it, index) => (
														<ListItemButton
															component={NavLink}
															to={it.url}
															key={index}>
															{it.label}
														</ListItemButton>
													))}
												</Positioned>
											</Exist>
										</RelativeBox>
								  ))
								: controller.guestMenuList.map((item, index) => (
										<RelativeBox key={index}>
											<button onClick={item.clickEvent}>{item.label}</button>
										</RelativeBox>
								  ))}
							{staticPagesMenuList.map(({label, url}, index) => (
								<RelativeBox key={index}>
									<NavLink to={url}>{label}</NavLink>
								</RelativeBox>
							))}
						</MenuList>
					</Menu>
				</Exist>
				<RightSide>
					<Exist when={!isMobileView && !controller.isAuthorized}>
						<FollowRams>
							{i18n.t("footer.follow_rams", "Follow LA Rams")}
							<a href={links.facebook} target="_blank" rel="noopener noreferrer">
								<IconFB />
							</a>
							<a href={links.twitter} target="_blank" rel="noopener noreferrer">
								<IconTwitter />
							</a>
						</FollowRams>
					</Exist>
					<Exist when={controller.isAuthorized}>
						<UserInformation to="my-account">
							<Avatar
								sx={{
									background: "#F7F7F7",
									color: "var(--textColor5)",
								}}>
								{controller.userInitials}
							</Avatar>
						</UserInformation>
					</Exist>
				</RightSide>
			</Wrapper>
		</HeaderWrapper>
	);
});
