import {ViewController} from "data/types/structure";
import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import React from "react";
import {AwsAmplifyError} from "data/constants";
import type {IResetPasswordPayload, IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

export interface IFormResetPasswordValues extends IResetPasswordPayload {
	confirmPassword: string;
}

export interface IResetPasswordForm extends HTMLFormElement {
	code: HTMLInputElement;
	email: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

export interface IFormResetPasswordController extends ViewController {
	get formErrors(): Record<string, string>;
	i18n: ILocalizationStore;
	submit: (event: React.SyntheticEvent<IResetPasswordForm>) => void;
	handleFormChange: ({target}: React.ChangeEvent<IResetPasswordForm>) => void;
	error: string | undefined;
	formValues: IFormResetPasswordValues;
}

@injectable()
export class FormResetPasswordController implements IFormResetPasswordController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	@observable private _error?: string;

	@observable private _formValues: IFormResetPasswordValues = {
		code: "",
		email: "",
		password: "",
		confirmPassword: "",
	};

	get error() {
		return this._error;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get formValues() {
		return this._formValues;
	}

	@action public submit = (event: React.SyntheticEvent<IResetPasswordForm>) => {
		event.preventDefault();

		this._error = undefined;

		const {checkValidity, setFormFieldError, clearFormFieldError, errors} =
			this._validationHelper;
		const form = event.currentTarget;
		const {confirmPassword, password} = form;

		clearFormFieldError(confirmPassword.name);
		checkValidity(form);

		if (confirmPassword.value !== password.value) {
			setFormFieldError(confirmPassword.name, errors.password_mismatch);
		}

		if (!this._validationHelper.isValid) {
			return;
		}

		this._userStore.resetPassword(this.formValues).then(this.onSuccess).catch(this.onError);
	};

	@action
	public handleFormChange = ({target: {name, value}}: React.ChangeEvent<HTMLFormElement>) => {
		this._error = undefined;
		this._validationHelper.clearFormFieldError(name);

		this._formValues = {
			...this._formValues,
			[name]: value as string,
		};
	};

	private onSuccess = () => {
		this._modalsStore.hideModal();
	};

	@action private onError = (error: AwsAmplifyError) => {
		trackSentryErrors(error, {}, "form reset password");
		this._error = error.message;
	};

	public dispose(): void {
		return;
	}

	public init(): void {
		return;
	}
}
