import React, {Fragment} from "react";
import {ModalAuth} from "views/components/modals/modal_auth.component";
import {ModalError} from "views/components/modals/modal_error.component";
import {ModalLeagueJoinedComponent} from "views/components/modals/modal_league_joined.component";
import {ModalLeagueLeave} from "views/components/modals/modal_league_leave.component";
import {ModalLeagueRemove} from "views/components/modals/modal_league_remove.component";
import {ModalLeagueRemoveUser} from "views/components/modals/modal_league_remove_user.component";
import {ModalPasswordChangedComponent} from "views/components/modals/modal_password_changed.component";
import {ModalForgotPassword} from "views/components/modals/modal_forgot_password.component";
import {ModalResetPassword} from "views/components/modals/modal_reset_password";
import {ModalAccountUpdatedComponent} from "views/components/modals/modal_account_updated.component";

export const Modals: React.FC = () => (
	<Fragment>
		<ModalAuth />
		<ModalForgotPassword />
		<ModalError />
		<ModalPasswordChangedComponent />
		<ModalAccountUpdatedComponent />
		<ModalResetPassword />
		<ModalLeagueRemoveUser />
		<ModalLeagueJoinedComponent />
		<ModalLeagueLeave />
		<ModalLeagueRemove />
	</Fragment>
);
