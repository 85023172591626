import {ApolloError} from "@apollo/client";
import {AwsAmplifyError, FORM_VALIDATION_ELEMENT_CLASSNAME, GAME_ID} from "data/constants";
import {ModalType, RequestState} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITempUserData, IUserStore} from "data/stores/user/user.store";
import type {ICustomFieldValue} from "data/types/global";
import {ViewController} from "data/types/structure";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {inject, injectable} from "inversify";
import {debounce, filter, find, isEmpty} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import React from "react";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import dayjs from "dayjs";

type THTMLFormElements = HTMLInputElement | HTMLSelectElement;

export interface IRegistrationForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	username: HTMLInputElement;
}

export interface IFormRegistrationController extends ViewController {
	get error(): string | undefined;

	get requestState(): RequestState;

	get formErrors(): Record<string, string>;

	get isFormLocked(): boolean;

	get tmpUserData(): ITempUserData | null;

	get customFields(): ICustomFieldFragment[] | null;

	get i18n(): ILocalizationStore;

	get formValidationHelper(): IFormValidationHelper;

	setCustomFieldsToTmp(): void;

	checkValidity(form: IRegistrationForm): Promise<boolean>;

	register(): Promise<void> | void;

	handleSubmitForm: (event: React.SyntheticEvent<IRegistrationForm>) => void;
	handleFormChange: () => void;
	handleInputFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleUsernameFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCustomFieldChange: (params: ICustomFieldValue) => void;
	goToLogin: () => void;
}

@injectable()
export class FormRegistrationController implements IFormRegistrationController {
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _error?: string = undefined;

	private checkUsernameUniqueDebounced = debounce(this.checkUsernameUnique.bind(this), 750, {
		leading: false,
		trailing: true,
	});

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	get tmpUserData() {
		return this._userStore.tmpUserData;
	}

	get error() {
		return this._error;
	}

	get requestState() {
		return this._requestState;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isFormLocked() {
		return this._requestState === RequestState.PENDING;
	}

	get customFields() {
		const fields = this._userStore.registrationCustomFields || [];
		return fields.filter(({isRegistration}) => isRegistration);
	}

	@action handleFormChange = () => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
	};

	private checkUsernameUnique(fieldName: string, fieldValue: string): Promise<boolean> {
		if (!fieldValue) return Promise.resolve(false);

		return this._userStore
			.checkUsername({
				username: fieldValue,
				gameID: GAME_ID,
			})
			.then((isUnique) => {
				if (!isUnique) {
					this._validationHelper.setFormFieldError(
						fieldName,
						this.i18n.t(
							"registration.username.already_exist",
							"The provided username is already exist"
						)
					);
				}

				return isUnique;
			})
			.catch((err: Error) => {
				trackSentryErrors(err, {}, "form registration - check username");
				this._validationHelper.setFormFieldError(fieldName, err.message);

				return false;
			});
	}

	handleUsernameFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;

		this.handleInputFieldChange(event);
		void this.checkUsernameUniqueDebounced(name, value);
	};

	handleInputFieldChange = ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
		this._validationHelper.clearFormFieldError(name);
		this._userStore.setTmpUserData({[name]: value});
	};

	handleCustomFieldChange = ({name, value, id}: ICustomFieldValue) => {
		this._validationHelper.clearFormFieldError(name);

		const values = filter(
			this._userStore.tmpUserData?.customFields,
			(field) => field.id !== id
		);

		this._userStore.setTmpUserData({
			customFields: [...values, {id, value}],
			[name]: value,
		});
	};

	copyFieldsValues(form: HTMLFormElement) {
		const fields = Array.from(
			form.getElementsByClassName(FORM_VALIDATION_ELEMENT_CLASSNAME)
		) as unknown as THTMLFormElements[];

		const regularFieldNames = [
			"email",
			"password",
			"confirmPassword",
			"firstName",
			"lastName",
			"username",
		];

		fields.forEach((field) => {
			const {name} = field;
			let {value} = field;

			if (regularFieldNames.includes(name)) {
				this.handleInputFieldChange({
					target: {name, value},
				} as React.ChangeEvent<HTMLInputElement>);
			} else {
				if (field.type === "checkbox") {
					value = String(Number((field as HTMLInputElement).checked));
				} else if (field.hasAttribute("data-datepicker")) {
					const dateFormat = field.getAttribute("data-date-format") || "DD/MM/YYYY";
					value = dayjs(value, dateFormat, true).utc(true).toISOString();
				}

				const id = find(this.customFields, {code: name})!.id;
				this.handleCustomFieldChange({name, value, id});
			}
		});
	}

	@action private onError = (error: AwsAmplifyError) => {
		trackSentryErrors(error, {}, "form registration - error handler");

		this._requestState = RequestState.ERROR;
		this._error = error.message;

		if (AwsAmplifyError.isUsernameExists(error)) {
			this._error = this.i18n.t(
				"registration.UsernameExistsException",
				"An account with the given email already exists."
			);
		}
	};

	@action private onSuccess = () => {
		this._modalsStore.hideModal();
		this._requestState = RequestState.SUCCESS;
		createConnextraScriptTag(ConnextraType.REGISTRATION_CONFIRM);
	};

	@action register() {
		this._requestState = RequestState.PENDING;

		if (!this._userStore.tmpUserData) {
			this._error = "Unexpected error. User's data not found";
			this._requestState = RequestState.ERROR;
			return;
		}

		return this._userStore
			.register({
				locale: this.i18n.locale!,
				...(this._userStore.tmpUserData as Required<ITempUserData>),
			})
			.then(this.onSuccess)
			.catch(this.onError);
	}

	goToLogin = () => this._modalsStore.showModal(ModalType.LOGIN);

	async checkValidity(form: IRegistrationForm) {
		const {password, confirmPassword, username} = form;
		const {checkValidity, setFormFieldError, formErrors, errors} = this._validationHelper;

		checkValidity(form);

		if (confirmPassword.value !== password.value) {
			setFormFieldError(confirmPassword.name, errors.password_mismatch);
		}

		if (!this._validationHelper.isValid) {
			return false;
		}

		if (!formErrors[username.name]) {
			await this.checkUsernameUnique(username.name, username.value);
		}

		return this._validationHelper.isValid;
	}

	handleSubmitForm = (event: React.SyntheticEvent<IRegistrationForm>) => {
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;

		void this.checkValidity(form).then((isValid) => {
			if (isValid) {
				this.copyFieldsValues(form);
				void this.register();
			}
		});
	};

	@action
	private requestCustomFields() {
		this._requestState = RequestState.PENDING;

		return this._userStore
			.requestRegistrationCustomFields()
			.then(() => runInAction(() => (this._requestState = RequestState.SUCCESS)))
			.catch((err) => {
				trackSentryErrors(err, {}, "form registration - request custom fields");
				this._requestState = RequestState.ERROR;
				this._modalsStore.showModal(ModalType.ERROR, {
					message: (err as ApolloError).message,
				});
			});
	}

	setCustomFieldsToTmp() {
		const customFieldsWithDefaultValue = this.customFields.filter(
			({defaultValue}) => defaultValue
		);
		const customFieldsValues = customFieldsWithDefaultValue.map(({id, defaultValue, code}) => ({
			id,
			value: (this._userStore.tmpUserData?.[code] || defaultValue!) as string,
		}));

		const customFields = customFieldsWithDefaultValue.reduce((acc, {id, code}) => {
			acc[code] = find(customFieldsValues, {id})?.value;
			return acc;
		}, {} as Record<string, unknown>);

		this._userStore.setTmpUserData({
			customFields: customFieldsValues,
			...customFields,
		});
	}

	dispose(): void {
		return;
	}

	async init() {
		if (isEmpty(this.customFields)) {
			await this.requestCustomFields();
		}

		this.setCustomFieldsToTmp();
		createConnextraScriptTag(ConnextraType.REGISTRATION_START);
	}

	get formValidationHelper(): IFormValidationHelper {
		return this._validationHelper;
	}
}
