import {isEqual} from "lodash";

/**
 * Aws Cognito has no list of errors in types/enums, that's why they're descibed here.
 */
enum AwsAmplifyErrorCodes {
	UserNotConfirmedException = "UserNotConfirmedException",
	UserNotFoundException = "UserNotFoundException", // User does not exist.
	UserLambdaValidationException = "UserLambdaValidationException",
	UnexpectedLambdaException = "UnexpectedLambdaException",
	TooManyRequestsException = "TooManyRequestsException",
	ResourceNotFoundException = "ResourceNotFoundException",
	PasswordResetRequiredException = "PasswordResetRequiredException",
	NotAuthorizedException = "NotAuthorizedException", // Incorrect username or password
	InvalidUserPoolConfigurationException = "InvalidUserPoolConfigurationException",
	InvalidSmsRoleTrustRelationshipException = "InvalidSmsRoleTrustRelationshipException",
	InvalidSmsRoleAccessPolicyException = "InvalidSmsRoleAccessPolicyException",
	InvalidParameterException = "InvalidParameterException",
	InvalidLambdaResponseException = "InvalidLambdaResponseException",
	InternalErrorException = "InvalidLambdaResInternalErrorExceptionponseException",
	UsernameExistsException = "UsernameExistsException", //  An account with the given email already exists
}

/**
 * Aws Amplify SignIn uses Cognito's "InitiateAuth" under the hood, so error codes can be found here:
 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
 */
export abstract class AwsAmplifyError extends Error {
	static codes = AwsAmplifyErrorCodes;

	code?: AwsAmplifyErrorCodes;
	message!: string;

	static isConfirmPasswordRequired(error: AwsAmplifyError) {
		return isEqual(error.code, AwsAmplifyError.codes.UserNotConfirmedException);
	}

	static isUserNotFound(error: AwsAmplifyError) {
		return isEqual(error.code, AwsAmplifyError.codes.UserNotFoundException);
	}

	static isWrongCredentials(error: AwsAmplifyError) {
		return isEqual(error.code, AwsAmplifyError.codes.NotAuthorizedException);
	}

	static isUsernameExists(error: AwsAmplifyError) {
		return isEqual(error.code, AwsAmplifyError.codes.UsernameExistsException);
	}
}
