import React, {useCallback} from "react";
import {generatePath, useNavigate} from "react-router-dom";
import {
	Box,
	Collapse,
	Divider,
	List,
	ListItemButton,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useViewController} from "data/services/locator/locator_hook.service";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import styled from "@emotion/styled";
import {gt, size} from "lodash";
import {Bindings} from "data/constants/bindings";
import {ContestDate} from "views/components/contest/contest_date";
import {observer} from "mobx-react";
import {CONTEST_ROUTES} from "data/constants";
import {ContestUtils} from "data/utils/contest_utils";

const ContestItemWrapper = styled.div`
	display: flex;
	gap: 2px;
	flex: 1;
	flex-flow: column;

	h6 {
		font-size: 14px;
	}
`;

const ContestItemData = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
`;

const DropdownHolder = styled(Box)`
	position: relative;
	max-width: 600px;
	width: 100%;
	z-index: 1;
`;

const DropdownPlaceholder = styled(Box)`
	height: 64px;
`;

const DropdownPaper = styled(Paper)`
	position: absolute;
	width: 100%;
	top: 0;
`;

const ListScrollableContainer = styled(Paper)`
	max-height: 200px;
	overflow: auto;
`;

const ListSpacer = styled(Box)`
	height: 10px;
`;

const DropdownWrapper = styled(Stack)`
	position: relative;
	z-index: 2;
	align-items: center;
`;

interface IContestItemProps {
	contest: IContestFragment;
}

const ContestDropdownItem: React.FC<IContestItemProps> = observer((props) => {
	const {contest} = props;
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return (
		<ContestItemWrapper>
			<Typography variant="h6" sx={{fontWeight: 700}}>
				{i18n.findValueByLocale(contest.name)}
			</Typography>
			<ContestItemData>
				<ContestDate {...contest} />
			</ContestItemData>
		</ContestItemWrapper>
	);
});

interface IContestsDropdownProps {
	currentContest?: IContestFragment | null;
	allContests: IContestFragment[];
}

export const ContestsDropdown: React.FC<IContestsDropdownProps> = (props) => {
	const navigate = useNavigate();
	const {currentContest, allContests} = props;
	const restContests = allContests.filter(({id}) => id !== currentContest?.id);
	const [isDropdownOpen, setOpenDropdown] = React.useState(false);
	const hasMoreThenOneContest = gt(size(allContests), 1);

	const goToContest = useCallback(
		(contest: IContestFragment) => {
			const [contestGameRoute, contestResultRoute] = CONTEST_ROUTES;
			const routePath = (
				ContestUtils.isScheduled(contest) ? contestGameRoute : contestResultRoute
			).path;
			const path = generatePath(routePath, {id: String(contest.id)});

			navigate(path);
			setOpenDropdown(false);
		},
		[navigate]
	);

	const toggleDropdown = useCallback(() => {
		if (!hasMoreThenOneContest) return;
		setOpenDropdown(!isDropdownOpen);
	}, [isDropdownOpen, hasMoreThenOneContest]);

	if (!currentContest) {
		return <DropdownPlaceholder />;
	}

	return (
		<DropdownWrapper>
			<DropdownHolder>
				<DropdownPlaceholder />
				<DropdownPaper elevation={3}>
					<List
						sx={{width: "100%"}}
						component="nav"
						disablePadding
						aria-labelledby="contests navigation">
						<ListItemButton onClick={toggleDropdown}>
							<ContestDropdownItem contest={currentContest} />
							<Exist when={hasMoreThenOneContest}>
								<Exist when={isDropdownOpen}>
									<ExpandLess />
								</Exist>
								<Exist when={!isDropdownOpen}>
									<ExpandMore />
								</Exist>
							</Exist>
						</ListItemButton>
						<Collapse in={isDropdownOpen} timeout="auto" unmountOnExit>
							<Divider />
							<ListScrollableContainer elevation={0}>
								<ListSpacer />
								<List component="div" dense disablePadding>
									{restContests.map((contest) => (
										<ListItemButton
											onClick={() => goToContest(contest)}
											key={contest.id}>
											<ContestDropdownItem contest={contest} />
										</ListItemButton>
									))}
								</List>
								<ListSpacer />
							</ListScrollableContainer>
						</Collapse>
					</List>
				</DropdownPaper>
			</DropdownHolder>
		</DropdownWrapper>
	);
};
