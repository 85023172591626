import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/core.css";

import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import Routes from "routes/routes.component";
import SecreteGateRoutes from "routes/secrete_gate_routes.component";
import {BrowserRouter} from "react-router-dom";
import ReactDOM from "react-dom";
import {
	DIContainer,
	services,
	providers,
	stores,
	controllers,
	helpers,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {SENTRY_CONFIG} from "data/constants";
import * as Sentry from "@sentry/react";
import {PagePreloader} from "views/components/preloader/preloader.component";
import {Modals} from "views/components/modals/modals.component";
import {GameGate} from "views/components/game_gate/game_gate.component";
import {ThemeProvider} from "@mui/material";
import {theme} from "data/constants/theme";
import {LandingController} from "views/pages/landing/landing.controller";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const root = document.getElementById("root");

Sentry.init(SENTRY_CONFIG);
DIContainer.load(services, providers, stores, controllers, helpers);
dayjs.extend(utc);

if (root) {
	ReactDOM.render(
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<InjectionProvider container={DIContainer}>
					<GameGate>
						<BrowserRouter>
							<Suspense fallback={<PagePreloader />}>
								{LandingController.IS_SECRET_PASSED ? (
									<Routes />
								) : (
									<SecreteGateRoutes />
								)}
								<Modals />
							</Suspense>
						</BrowserRouter>
					</GameGate>
				</InjectionProvider>
			</ThemeProvider>
		</React.StrictMode>,
		root
	);
} else {
	throw Error("Root element was not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
