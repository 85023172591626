import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import React from "react";
import {ViewController} from "data/types/structure";
import {ModalType, RequestState} from "data/enums";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

interface IChangePasswordForm extends HTMLFormElement {
	oldPassword: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

export interface IMyAccountChangePasswordController extends ViewController {
	readonly i18n: ILocalizationStore;
	handleFormChange: (event: React.ChangeEvent<IChangePasswordForm>) => void;
	submit: (event: React.SyntheticEvent<IChangePasswordForm>) => void;

	get isLoading(): boolean;

	get error(): string | undefined;

	get formErrors(): Record<string, string>;

	get formValidationHelper(): IFormValidationHelper;
}

@injectable()
export class MyAccountChangePasswordController implements IMyAccountChangePasswordController {
	@observable _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	@observable private _error?: string;

	get error() {
		return this._error;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	@action handleFormChange = ({target: {name}}: React.ChangeEvent<HTMLFormElement>) => {
		this._error = undefined;
		this._validationHelper.clearFormFieldError(name);
	};

	@action submit = (event: React.SyntheticEvent<IChangePasswordForm>) => {
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;
		const {checkValidity, setFormFieldError, errors} = this._validationHelper;
		const {password, confirmPassword, oldPassword} = form;

		checkValidity(form);

		if (password.value !== confirmPassword.value) {
			setFormFieldError(confirmPassword.name, errors.password_mismatch);
		}

		if (!this._validationHelper.isValid) return;

		this._requestState = RequestState.PENDING;

		this._userStore
			.changePassword({
				oldPassword: oldPassword.value,
				newPassword: password.value,
			})
			.then(() => this.onSuccess(form))
			.catch(this.onError);
	};

	@action private onSuccess = (form: IChangePasswordForm) => {
		const {password, confirmPassword, oldPassword} = form;

		password.value = "";
		confirmPassword.value = "";
		oldPassword.value = "";

		this._requestState = RequestState.IDLE;
		this._modalsStore.showModal(ModalType.PASSWORD_CHANGED);
	};

	@action private onError = (error: Error) => {
		trackSentryErrors(error, {}, "my account change password");
		this._requestState = RequestState.ERROR;
		this._error = error.message;
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	get formValidationHelper(): IFormValidationHelper {
		return this._validationHelper;
	}
}
