import {ViewController} from "data/types/structure";
import {action, makeAutoObservable, observable} from "mobx";
import React from "react";
import {ModalType, RequestState} from "data/enums";
import {inject, injectable} from "inversify";
import type {ITempUserData, IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {AwsAmplifyError} from "data/constants";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

export interface IForgotPasswordFormController extends ViewController {
	submit: (event: React.SyntheticEvent<HTMLFormElement>) => Promise<void> | void;
	i18n: ILocalizationStore;
	handleFormChange: ({target}: React.ChangeEvent<HTMLFormElement>) => void;
	get isLoading(): boolean;
	get error(): string | undefined;
	get requestState(): RequestState;
	get formErrors(): Record<string, string>;
	get tmpUserData(): ITempUserData | null;
}

@injectable()
export class ForgotPasswordFormController implements IForgotPasswordFormController {
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _error?: string;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	get error() {
		return this._error;
	}

	get requestState() {
		return this._requestState;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get tmpUserData() {
		return this._userStore.tmpUserData;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	@action submit = (event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!this._validationHelper.checkValidity(event.currentTarget)) {
			return;
		}

		this._requestState = RequestState.PENDING;

		return this._userStore
			.forgotPassword(this._userStore.tmpUserData?.email ?? "")
			.then(this.onSuccess)
			.catch(this.onError);
	};

	private onSuccess = (): void => {
		this._modalsStore.showModal(ModalType.FORGOT_PASSWORD_CONFIRM);
		this._requestState = RequestState.IDLE;
	};

	private onError = (error: AwsAmplifyError): void => {
		trackSentryErrors(error, {}, "Forgot password form");
		this._error = error.message;
		this._requestState = RequestState.ERROR;
	};

	@action handleFormChange = ({target: {name, value}}: React.ChangeEvent<HTMLFormElement>) => {
		this._error = undefined;
		this._validationHelper.clearFormFieldError(name);
		this._userStore.setTmpUserData({[name]: value as string});
	};

	init(): void {
		return;
	}

	dispose(): void {
		return;
	}
}
