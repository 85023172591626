import {ApolloQueryResult} from "@apollo/client";
import type {FetchResult} from "@apollo/client/link/core";
import gql from "data/graphql";
import type {IGraphQLClient} from "data/services/graphql/grapql_client_factory.service";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";

export interface ILeaguesApiProvider {
	create: (
		variables: IAddPredictorLeagueMutationVariables
	) => Promise<FetchResult<IAddPredictorLeagueMutation>>;

	updatePredictorLeague: (
		variables: IUpdatePredictorLeagueMutationVariables
	) => Promise<FetchResult<IUpdatePredictorLeagueMutation>>;

	leavePredictorLeague: (
		variables: ILeavePredictorLeagueMutationVariables
	) => Promise<FetchResult<ILeavePredictorLeagueMutation>>;

	joinPredictorLeague: (
		variables: IJoinPredictorLeagueMutationVariables
	) => Promise<FetchResult<IJoinPredictorLeagueMutation>>;

	invitePredictorLeague: (
		variables: IInvitePredictorLeagueMutationVariables
	) => Promise<FetchResult<IInvitePredictorLeagueMutation>>;

	removeUserFromPredictorLeague: (
		variables: IRemoveUserFromPredictorLeagueMutationVariables
	) => Promise<FetchResult<IRemoveUserFromPredictorLeagueMutation>>;

	removeLeague: (
		variables: IDeletePredictorLeagueMutationVariables
	) => Promise<FetchResult<IDeletePredictorLeagueMutation>>;

	myPredictorLeagues: () => Promise<ApolloQueryResult<IGetMyLeaguesQuery>>;
	showForJoinPredictorLeague: (
		variables: IGetLeaguesToJoinQueryVariables
	) => Promise<ApolloQueryResult<IGetLeaguesToJoinQuery>>;

	getPredictorLeague: (
		variables: IGetPredictorLeagueQueryVariables
	) => Promise<ApolloQueryResult<IGetPredictorLeagueQuery>>;

	getPredictorLeagueUsers: (
		variables: IGetPredictorLeagueUsersQueryVariables
	) => Promise<ApolloQueryResult<IGetPredictorLeagueUsersQuery>>;
}

@injectable()
export class LeaguesApiProvider implements ILeaguesApiProvider {
	constructor(@inject(Bindings.GraphQLClient) private _client: IGraphQLClient) {}

	create = (variables: IAddPredictorLeagueMutationVariables) =>
		this._client.mutate<IAddPredictorLeagueMutation, IAddPredictorLeagueMutationVariables>({
			mutation: gql.mutations.CREATE_LEAGUE,
			variables,
		});

	updatePredictorLeague = (variables: IUpdatePredictorLeagueMutationVariables) =>
		this._client.mutate<
			IUpdatePredictorLeagueMutation,
			IUpdatePredictorLeagueMutationVariables
		>({
			mutation: gql.mutations.UPDATE_LEAGUE,
			variables,
		});

	leavePredictorLeague = (variables: ILeavePredictorLeagueMutationVariables) =>
		this._client.mutate<ILeavePredictorLeagueMutation, ILeavePredictorLeagueMutationVariables>({
			mutation: gql.mutations.LEAVE_LEAGUE,
			variables,
		});

	joinPredictorLeague = (variables: IJoinPredictorLeagueMutationVariables) =>
		this._client.mutate<IJoinPredictorLeagueMutation, IJoinPredictorLeagueMutationVariables>({
			mutation: gql.mutations.JOIN_LEAGUE,
			variables,
		});

	invitePredictorLeague = (variables: IInvitePredictorLeagueMutationVariables) =>
		this._client.mutate<
			IInvitePredictorLeagueMutation,
			IInvitePredictorLeagueMutationVariables
		>({
			mutation: gql.mutations.INVITE_TO_LEAGUE,
			variables,
		});

	removeUserFromPredictorLeague = (variables: IRemoveUserFromPredictorLeagueMutationVariables) =>
		this._client.mutate<
			IRemoveUserFromPredictorLeagueMutation,
			IRemoveUserFromPredictorLeagueMutationVariables
		>({
			mutation: gql.mutations.REMOVE_LEAGUE_USER,
			variables,
		});

	removeLeague = (variables: IDeletePredictorLeagueMutationVariables) =>
		this._client.mutate<
			IDeletePredictorLeagueMutation,
			IDeletePredictorLeagueMutationVariables
		>({
			mutation: gql.mutations.REMOVE_LEAGUE,
			variables,
		});

	myPredictorLeagues = () =>
		this._client.query<IGetMyLeaguesQuery>({
			query: gql.queries.GET_MY_LEAGUES,
		});

	showForJoinPredictorLeague = (variables: IGetLeaguesToJoinQueryVariables) =>
		this._client.query<IGetLeaguesToJoinQuery, IGetLeaguesToJoinQueryVariables>({
			query: gql.queries.GET_LEAGUES_TO_JOIN,
			variables,
		});

	getPredictorLeague = (variables: IGetPredictorLeagueQueryVariables) =>
		this._client.query<IGetPredictorLeagueQuery, IGetPredictorLeagueQueryVariables>({
			query: gql.queries.GET_LEAGUE,
			variables,
		});

	getPredictorLeagueUsers = (variables: IGetPredictorLeagueUsersQueryVariables) =>
		this._client.query<IGetPredictorLeagueUsersQuery, IGetPredictorLeagueUsersQueryVariables>({
			query: gql.queries.GET_LEAGUE_USERS,
			variables,
		});
}
