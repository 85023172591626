import {gql} from "@apollo/client";

export const NAME_TRANSLATIONS = gql`
	fragment NameTranslations on PredictorTranslation {
		locale
		value
	}
`;

export const CUSTOM_FIELD_NAME_TRANSLATIONS = gql`
	fragment CustomFieldNameTranslations on CustomFieldOptionName {
		locale
		value
	}
`;

export const USER_FRAGMENT = gql`
	fragment User on PredictorUser {
		id
		firstName
		lastName
		username
		email
		isNotificationEnabled
	}
`;

export const OPTION_FRAGMENT = gql`
	${NAME_TRANSLATIONS}

	fragment Option on QuestionOption {
		id
		imageUrl
		name {
			...NameTranslations
		}
	}
`;

export const ANSWER_FRAGMENT = gql`
	fragment QuestionAnswer on Answer {
		question {
			id
		}
		value
		# Received points for answer
		points
		# Selected option
		option {
			id
		}
	}
`;

export const QUESTION_FRAGMENT = gql`
	${OPTION_FRAGMENT}
	${NAME_TRANSLATIONS}

	fragment Question on Question {
		id
		orderNum
		name {
			...NameTranslations
		}
		imageUrls
		lockDate
		publishStatus
		rangeCorrect
		rangeFrom
		rangeTo
		status
		contest {
			scoringRules {
				points
			}
		}
		layout {
			id
			name
		}
		options {
			...Option
		}
		scoringRules {
			points
		}
		correctOptions {
			value
			# Correct option
			option {
				id
			}
		}
		type {
			logicType
		}
	}
`;

export const CONTEST_FRAGMENT = gql`
	${NAME_TRANSLATIONS}

	fragment Contest on Contest {
		dateEnd
		dateStart
		id
		hasQuestions
		name {
			...NameTranslations
		}
		status
	}
`;

export const SEASON_FRAGMENT = gql`
	fragment Season on Season {
		id
		name
		game {
			id
			name
		}
	}
`;

export const CUSTOM_FIELD_FRAGMENT = gql`
	${NAME_TRANSLATIONS}
	${CUSTOM_FIELD_NAME_TRANSLATIONS}

	fragment CustomField on CustomField {
		id
		name {
			...NameTranslations
		}
		type
		code
		orderNum
		isEditable
		isRegistration
		defaultValue
		description {
			...NameTranslations
		}
		data {
			required
			from
			to
			options {
				id
				name {
					...CustomFieldNameTranslations
				}
			}
		}
	}
`;

export const GAME_INFO_FRAGMENT = gql`
	fragment GameInfo on GameUnauthorized {
		locales
		urls {
			url
			locale
		}
	}
`;

/**
 * Don't remove. It's required for types auto-generation
 */
gql`
	fragment UserCustomFieldValue on UserCustomField {
		id
		value
	}
`;

export const LEAGUE_FRAGMENT = gql`
	${NAME_TRANSLATIONS}

	fragment League on PredictorLeague {
		author {
			id
			firstName
			lastName
			username
		}
		code
		createdAt
		endContest {
			type {
				id
			}
		}
		game {
			id
		}
		id
		joinedUsers
		name
		privacy
		startContest {
			id
			dateStart
			name {
				...NameTranslations
			}
		}
		status
		type {
			isOverall
			id
		}
		updatedAt
		currentRank
		previousRank
	}
`;

export const LEAGUE_USER_FRAGMENT = gql`
	fragment LeagueUser on PredictorUser {
		firstName
		lastName
		username
		id
	}
`;

export const LEAGUE_USERS_FRAGMENT = gql`
	${LEAGUE_USER_FRAGMENT}

	fragment LeagueUsers on PredictorUsersWithPages {
		isNextPage
		pages
		users {
			...LeagueUser
		}
	}
`;

export const RANK_USER_FRAGMENT = gql`
	fragment RankUser on PredictorRank {
		contest {
			id
		}
		contestRank
		contestPoints
		overallPoints
		overallRank
		user {
			firstName
			lastName
			username
			id
		}
	}
`;

export const RANKS_FRAGMENT = gql`
	${RANK_USER_FRAGMENT}

	fragment Ranks on PredictorRankWithPages {
		isNextPage
		pages
		ranks {
			...RankUser
		}
	}
`;

export const LEAGUES_TO_JOIN_FRAGMENT = gql`
	${LEAGUE_FRAGMENT}

	fragment LeaguesToJoin on PredictorLeaguesWithPages {
		isNextPage
		pages
		leagues {
			...League
		}
	}
`;
