import {ApolloError, ApolloLink, fromPromise, HttpLink, NextLink, Operation} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {get, isEqual} from "lodash";
import {DIContainer} from "data/services/locator/locator_container.service";
import {type IGraphQLClientParameters} from "data/services/graphql/grapql_client_factory.service";
import {throwServerError} from "@apollo/client/link/utils/throwServerError";
import {IUserStore} from "data/stores/user/user.store";
import {GraphQLError} from "data/constants";
import {NetworkErrorCodes} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

const appendBearerToken = (operation: Operation): Operation => {
	const {token} = DIContainer.get<IUserStore>(Bindings.UserStore);

	operation.setContext(({headers = {}}) => ({
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : null,
		},
	}));

	return operation;
};

class AuthLink extends ApolloLink {
	request(operation: Operation, forward: NextLink) {
		return forward(appendBearerToken(operation));
	}
}

export abstract class ApolloClientLinks {
	static createErrorLink(): ApolloLink {
		let _isTokenRefreshing = false;
		let _pendingRequests: (() => void)[] = [];

		const resolvePendingRequests = () => {
			_pendingRequests.forEach((callback) => callback());
			_pendingRequests = [];
		};

		return onError(({networkError, graphQLErrors, operation, forward}) => {
			const errorCode = get(networkError, "statusCode", 0) as number;

			trackSentryErrors(
				networkError,
				{
					graphQLErrors,
					operationName: operation.operationName,
					variables: operation.variables,
				},
				"GraphQL Middleware"
			);

			const isNotAuthorized =
				GraphQLError.isNotAuthorized(graphQLErrors) ||
				isEqual(errorCode, NetworkErrorCodes.UNAUTHENTICATED);

			if (isNotAuthorized) {
				/**
				 * The current implementation of retry handle a lot of requests,
				 * and help to avoid refresh token call for each request.
				 */
				const {refreshAccessToken, clearUsedData} = DIContainer.get<IUserStore>(
					Bindings.UserStore
				);

				let forward$;

				if (!_isTokenRefreshing) {
					_isTokenRefreshing = true;

					const refreshTokenPromise = refreshAccessToken()
						.then((token) => {
							resolvePendingRequests();
							return token;
						})
						.catch((error) => {
							trackSentryErrors(error, {}, "refreshAccessToken inside middleware");
							void clearUsedData();
							_pendingRequests = [];
							return false;
						})
						.finally(() => {
							_isTokenRefreshing = false;
						});

					forward$ = fromPromise(refreshTokenPromise).filter((value) => Boolean(value));
				} else {
					/**
					 * Will only emit once the Promise is resolved
					 */
					forward$ = fromPromise(
						new Promise((resolve) => {
							_pendingRequests.push(() => resolve(undefined));
						})
					);
				}

				return forward$.flatMap(() => forward(appendBearerToken(operation)));
			}
		});
	}

	static createAuthLink(): ApolloLink {
		return new AuthLink();
	}

	static createHttpLink(options: IGraphQLClientParameters): ApolloLink {
		const customFetch = async (uri: string, options: Record<string, unknown>) => {
			const response = await fetch(uri, options);

			if (response.status >= 400) {
				return Promise.reject(
					new ApolloError({
						errorMessage: response.statusText,
						networkError: throwServerError(response, {}, response.statusText),
					})
				);
			}

			return response;
		};

		return new HttpLink({uri: options.uri, fetch: customFetch});
	}
}
