import {inject, injectable} from "inversify";
import type {IGraphQLClient} from "data/services/graphql/grapql_client_factory.service";
import {ApolloQueryResult} from "@apollo/client";
import gql from "data/graphql";
import type {FetchResult} from "@apollo/client/link/core";
import {Bindings} from "data/constants/bindings";

export interface IGameplayApiProvider {
	requestSeasonsList: (
		variables: IGetSeasonsQueryVariables
	) => Promise<ApolloQueryResult<IGetSeasonsQuery>>;
	requestContestsList: (
		variables: IGetContestsQueryVariables
	) => Promise<ApolloQueryResult<IGetContestsQuery>>;
	requestQuestionsList: (
		variables: IGetQuestionsQueryVariables
	) => Promise<ApolloQueryResult<IGetQuestionsQuery>>;
	requestAnswersList: (
		variables: IGetAnswersQueryVariables
	) => Promise<ApolloQueryResult<IGetAnswersQuery>>;
	saveAnswers: (
		variables: ISaveAnswerMutationVariables
	) => Promise<FetchResult<ISaveAnswerMutation>>;
}

@injectable()
export class GameplayApiProvider implements IGameplayApiProvider {
	constructor(@inject(Bindings.GraphQLClient) private _client: IGraphQLClient) {}

	requestSeasonsList = (variables: IGetSeasonsQueryVariables) =>
		this._client.query<IGetSeasonsQuery, IGetSeasonsQueryVariables>({
			query: gql.queries.GET_SEASONS,
			variables,
		});

	requestContestsList = (variables: IGetContestsQueryVariables) =>
		this._client.query<IGetContestsQuery, IGetContestsQueryVariables>({
			query: gql.queries.GET_CONTESTS,
			variables,
		});

	requestQuestionsList = (variables: IGetQuestionsQueryVariables) =>
		this._client.query<IGetQuestionsQuery, IGetQuestionsQueryVariables>({
			query: gql.queries.GET_QUESTIONS,
			variables,
		});

	requestAnswersList = (variables: IGetAnswersQueryVariables) =>
		this._client.query<IGetAnswersQuery, IGetAnswersQueryVariables>({
			query: gql.queries.GET_ANSWERS,
			variables,
		});

	saveAnswers = (variables: ISaveAnswerMutationVariables) =>
		this._client.mutate<ISaveAnswerMutation, ISaveAnswerMutationVariables>({
			mutation: gql.mutations.SAVE_ANSWER,
			variables,
			fetchPolicy: "no-cache",
		});
}
