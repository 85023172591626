import {action, computed, makeAutoObservable, observable} from "mobx";

export interface IStepper {
	readonly totalSteps: number;
	get hasNext(): boolean;
	get hasPrev(): boolean;
	get currentStep(): number;
	toNext: () => boolean;
	toPrev: () => boolean;
	goTo: (step: number) => boolean;
	isActiveStep: (step: number) => boolean;
}

export class Stepper implements IStepper {
	private readonly _lastIndex: number = 0;
	@observable private _currentStep: number = 0;

	constructor(public readonly totalSteps: number = 0) {
		if (totalSteps > 0) {
			this._lastIndex = totalSteps - 1;
		}

		makeAutoObservable(this);
	}

	get currentStep() {
		return this._currentStep;
	}

	@computed get hasNext() {
		return this._currentStep < this._lastIndex;
	}

	@computed get hasPrev() {
		return this._currentStep > 0;
	}

	@action toNext = () => {
		if (!this.hasNext) {
			return false;
		}

		this._currentStep++;
		return true;
	};

	@action toPrev = () => {
		if (!this.hasPrev) {
			return false;
		}

		this._currentStep--;
		return true;
	};

	@action goTo = (step: number) => {
		if (step >= 0 && step <= this._lastIndex) {
			this._currentStep = step;
			return true;
		} else {
			return false;
		}
	};

	@computed isActiveStep = (step: number) => {
		return this._currentStep === step;
	};
}
