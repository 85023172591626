import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITempUserData, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ModalType, RequestState} from "data/enums";
import React from "react";
import {debounce, filter, find, isEmpty} from "lodash";
import {AwsAmplifyError, COGNITO_CUSTOM_FIELD_NAME, GAME_ID} from "data/constants";
import {ApolloError} from "@apollo/client";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import type {ICustomFieldValue} from "data/types/global";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

export interface IMyAccountForm extends HTMLFormElement {
	email: HTMLInputElement;
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	username: HTMLInputElement;
}

export interface IMyAccountDetailsController extends ViewController {
	readonly i18n: ILocalizationStore;
	get tmpUserData(): ITempUserData | null;
	get isFormSaving(): boolean;
	get isDataLoading(): boolean;
	get error(): string | undefined;
	get customFields(): ICustomFieldFragment[];
	get formErrors(): Record<string, string>;
	handleSubmitForm: (event: React.SyntheticEvent<IMyAccountForm>) => void;
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
	handleInputFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleUsernameFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleEmailNotificationsInputFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCustomFieldChange: (params: ICustomFieldValue) => void;
}

@injectable()
export class MyAccountDetailsController implements IMyAccountDetailsController {
	@observable private _formRequestState: RequestState = RequestState.IDLE;
	@observable private _dataLoadingRequestState: RequestState = RequestState.PENDING;

	private checkUsernameUniqueDebounced = debounce(this.checkUsernameUnique.bind(this), 750, {
		leading: false,
		trailing: true,
	});

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	@observable private _error?: string;

	get error() {
		return this._error;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get customFields() {
		const fields = this._userStore.registrationCustomFields || [];
		return fields.filter(({isEditable}) => isEditable);
	}

	get tmpUserData(): ITempUserData | null {
		return this._userStore.tmpUserData;
	}

	get isFormSaving() {
		return this._formRequestState === RequestState.PENDING;
	}

	get isDataLoading() {
		return this._dataLoadingRequestState === RequestState.PENDING;
	}

	@action private onSuccess = (): void => {
		this._formRequestState = RequestState.IDLE;
		this._modalsStore.showModal(ModalType.ACCOUNT_UPDATED);
	};

	@action private onError = (error: AwsAmplifyError) => {
		trackSentryErrors(error, {}, "my account - error handler");
		this._error = error.message;
		this._formRequestState = RequestState.ERROR;
	};

	@action handleFormChange = () => {
		this._error = undefined;
		this._formRequestState = RequestState.IDLE;
	};

	private checkUsernameUnique(fieldName: string, fieldValue: string): Promise<boolean> {
		if (!fieldValue) return Promise.resolve(false);

		return this._userStore
			.checkUsername({
				username: fieldValue,
				gameID: GAME_ID,
			})
			.then((isUnique) => {
				if (!isUnique) {
					this._validationHelper.setFormFieldError(
						fieldName,
						this.i18n.t(
							"registration.username.already_exist",
							"The provided username is already exist"
						)
					);
				}

				return isUnique;
			})
			.catch((err: Error) => {
				trackSentryErrors(err, {}, "form registration - check username");
				this._validationHelper.setFormFieldError(fieldName, err.message);

				return false;
			});
	}

	handleUsernameFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;

		this.handleInputFieldChange(event);
		void this.checkUsernameUniqueDebounced(name, value);
	};

	handleInputFieldChange = ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
		this._validationHelper.clearFormFieldError(name);
		this._userStore.setTmpUserData({[name]: value});
	};

	handleEmailNotificationsInputFieldChange = ({
		target: {name, checked},
	}: React.ChangeEvent<HTMLInputElement>) => {
		this._userStore.setTmpUserData({[name]: checked});
	};

	handleCustomFieldChange = ({name, value, id}: ICustomFieldValue) => {
		this._validationHelper.clearFormFieldError(name);

		const values = filter(
			this._userStore.tmpUserData?.customFields,
			(field) => field.id !== id
		);

		this._userStore.setTmpUserData({
			customFields: [...values, {id, value}],
			[name]: value,
		});
	};

	@action private updateDetails() {
		this._formRequestState = RequestState.PENDING;
		void this._userStore
			.updateUser(this._userStore.tmpUserData as Required<ITempUserData>)
			.then(this.onSuccess)
			.catch(this.onError);
	}

	async checkValidity(form: IMyAccountForm) {
		const {username} = form;
		const {checkValidity, formErrors} = this._validationHelper;

		checkValidity(form);

		if (!this._validationHelper.isValid) {
			return false;
		}

		if (!formErrors[username.name]) {
			await this.checkUsernameUnique(username.name, username.value);
		}

		return this._validationHelper.isValid;
	}

	handleSubmitForm = (event: React.SyntheticEvent<IMyAccountForm>) => {
		event.preventDefault();
		event.stopPropagation();
		this.handleFormChange();

		void this.checkValidity(event.currentTarget).then((isValid) => {
			if (isValid) void this.updateDetails();
		});
	};

	private async setCustomFieldsToTmp() {
		const {
			email = "",
			nickname = "",
			given_name = "",
			family_name = "",
			...rest
		} = await this._userStore.getCognitoUserAttributes();

		const customFieldsStr = rest[COGNITO_CUSTOM_FIELD_NAME] ?? "[]";
		const customFieldsValues = JSON.parse(customFieldsStr) as IUserCustomFieldValueFragment[];

		const customFields = this.customFields.reduce((acc, {id, code}) => {
			acc[code] = find(customFieldsValues, {id})?.value;
			return acc;
		}, {} as Record<string, unknown>);

		this._userStore.setTmpUserData({
			email,
			username: nickname,
			firstName: given_name,
			lastName: family_name,
			customFields: customFieldsValues,
			isNotificationEnabled: this._userStore.user?.isNotificationEnabled,
			...customFields,
		});
	}

	dispose(): void {
		return;
	}

	@action async init() {
		try {
			if (isEmpty(this.customFields)) {
				await this._userStore.requestRegistrationCustomFields();
			}

			await this.setCustomFieldsToTmp();

			runInAction(() => (this._dataLoadingRequestState = RequestState.SUCCESS));
		} catch (err) {
			trackSentryErrors(err, {}, "my account - details init");
			runInAction(() => (this._dataLoadingRequestState = RequestState.ERROR));

			this._modalsStore.showModal(ModalType.ERROR, {
				message: (err as ApolloError).message,
			});
		}
	}
}
