type TBuildNameParams = Pick<IUserFragment, "firstName" | "lastName" | "username">;

export const buildNameFromUser = (user?: TBuildNameParams | null) => {
	if (!user) {
		return "-";
	}

	if (user.username) {
		return user.username;
	}

	if (user.lastName) {
		return `${user.firstName[0]}. ${user.lastName}`;
	}

	return user.firstName;
};
