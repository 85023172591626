export const Bindings = {
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	GraphQLClient: Symbol("GraphQLClient"),
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	GameplayApiProvider: Symbol("GameplayApiProvider"),
	LocalizationApiProvider: Symbol("LocalizationApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	StandingsApiProvider: Symbol("StandingsApiProvider"),
	UserStore: Symbol("UserStore"),
	ModalsStore: Symbol("ModalsStore"),
	GameplayStore: Symbol("GameplayStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	LeaguesStore: Symbol("LeaguesStore"),
	StandingsStore: Symbol("StandingsStore"),
	AuthController: Symbol("AuthController"),
	FormLoginController: Symbol("FormLoginController"),
	FormRegistrationController: Symbol("FormRegistrationController"),
	ModalsController: Symbol("ModalsController"),
	HomeController: Symbol("HomeController"),
	HeaderController: Symbol("HeaderController"),
	GameGateController: Symbol("GameGateController"),
	LocalizationController: Symbol("LocalizationController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	LeaguesToJoinController: Symbol("LeaguesToJoinController"),
	ContestController: Symbol("ContestController"),
	LeagueController: Symbol("LeagueController"),
	InviteLeagueController: Symbol("InviteLeagueController"),
	LeagueUsersController: Symbol("LeagueUsersController"),
	LeagueControlsController: Symbol("LeagueControlsController"),
	SettingsLeagueController: Symbol("SettingsLeagueController"),
	SettingsLeagueFormController: Symbol("SettingsLeagueFormController"),
	ContestBannerController: Symbol("ContestBannerController"),
	MyAccountDetailsController: Symbol("MyAccountDetailsController"),
	MyAccountChangePasswordController: Symbol("MyAccountChangePasswordController"),
	LandingController: Symbol("LandingController"),
	ContestQuestionCardController: Symbol("ContestQuestionCardController"),
	ForgotPasswordFormController: Symbol("ForgotPasswordFormController"),
	FormResetPasswordController: Symbol("FormResetPasswordController"),
	HelpController: Symbol("HelpController"),
	PrizesController: Symbol("PrizesController"),
	StandingsController: Symbol("StandingsController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	FormValidationHelper: Symbol("FormValidationHelper"),
	SsoProvider: Symbol("SsoProvider"),
};
