import {ViewController} from "data/types/structure";
import {createConnextraScriptTag, ConnextraType} from "data/utils/connextra";
import {inject, injectable} from "inversify";
import type {ILoginPayload, ITempUserData, IUserStore} from "data/stores/user/user.store";
import React from "react";
import {action, makeAutoObservable, observable} from "mobx";
import {ModalType, RequestState} from "data/enums";
import {AwsAmplifyError} from "data/constants/AwsAmplifyError";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

export interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

export interface IFormLoginController extends ViewController {
	get error(): string | undefined;
	get formErrors(): Record<string, string>;
	get tmpUserData(): ITempUserData | null;
	get isFormLocked(): boolean;
	get i18n(): ILocalizationStore;
	get formValidationHelper(): IFormValidationHelper;
	get requestState(): RequestState;
	dispose(): void;

	handleSubmitForm: (event: React.SyntheticEvent<ILoginForm>) => void;
	login(params: ILoginPayload): Promise<void>;
	handleFormChange: (event: React.ChangeEvent<ILoginForm>) => void;
	goToRegistration: () => void;
	goToForgotPassword: () => void;
}

@injectable()
export class FormLoginController implements IFormLoginController {
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _error?: string = undefined;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	get tmpUserData() {
		return this._userStore.tmpUserData;
	}

	get error() {
		return this._error;
	}

	get requestState() {
		return this._requestState;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isFormLocked() {
		return this._requestState === RequestState.PENDING;
	}

	goToRegistration = () => this._modalsStore.showModal(ModalType.REGISTRATION);

	goToForgotPassword = () => this._modalsStore.showModal(ModalType.FORGOT_PASSWORD);

	@action handleFormChange = ({target}: React.ChangeEvent<HTMLFormElement>) => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
		this._validationHelper.clearFormFieldError(target.name);
		this._userStore.setTmpUserData({
			[target.name]: target.value as string,
		});
	};

	@action private onError = (error: AwsAmplifyError) => {
		trackSentryErrors(error, {}, "form login");
		this._requestState = RequestState.ERROR;
		this._error = error.message;

		if (AwsAmplifyError.isUserNotFound(error)) {
			this._error = this.i18n.t("login.UserNotFoundException", "User does not exist.");
		}

		if (AwsAmplifyError.isWrongCredentials(error)) {
			this._error = this.i18n.t(
				"login.WrongCredentialsException",
				"Incorrect username or password."
			);
		}
	};

	@action private onSuccess = () => {
		this._modalsStore.hideModal();
		this._requestState = RequestState.SUCCESS;
		createConnextraScriptTag(ConnextraType.LOGIN, this._userStore.user);
	};

	@action login({email, password, locale}: ILoginPayload) {
		this._requestState = RequestState.PENDING;

		return this._userStore
			.login({email, password, locale})
			.then(this.onSuccess)
			.catch(this.onError);
	}

	@action handleSubmitForm = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		this._error = undefined;
		const form = event.currentTarget;

		if (!this._validationHelper.checkValidity(form)) {
			return;
		}

		const {email, password} = form;

		void this.login({
			email: email.value,
			password: password.value,
			locale: this.i18n.locale!,
		});
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	get formValidationHelper(): IFormValidationHelper {
		return this._validationHelper;
	}
}
