import React, {useCallback, useState} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {FormControlLabel, FormGroup, FormHelperText, Switch} from "@mui/material";
import {FORM_VALIDATION_ELEMENT_CLASSNAME} from "data/constants";
import {Exist} from "views/components/exist/exist.component";
import {ICustomFieldProps} from "data/types/global";
import {Bindings} from "data/constants/bindings";
import {CheckboxLabelTypography} from "views/components/forms/common/forms_common.component";

export const Toggle: React.FC<ICustomFieldProps> = (props) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const {id, type, description, data, code, onChange, error, defaultValue, disabled} = props;
	const [isChecked, setCheckedState] = useState(Boolean(Number(defaultValue)));
	const elementID = `${type}-custom-field-${id}`;
	const isRequired = data?.required || false;
	const fieldDescription = description ? i18n.findValueByLocale(description) : "";

	const handleChange = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			const value = event.currentTarget.checked;
			setCheckedState(value);
			onChange({id, value: String(Number(value)), name: code});
		},
		[id, code, onChange]
	);

	return (
		<FormGroup sx={{justifyContent: "center"}}>
			<FormControlLabel
				disabled={disabled}
				control={
					<Switch
						id={elementID}
						disabled={disabled}
						checked={isChecked}
						onChange={handleChange}
						name={code}
						inputProps={{className: FORM_VALIDATION_ELEMENT_CLASSNAME}}
						required={isRequired}
					/>
				}
				label={
					<CheckboxLabelTypography
						component="span"
						display="inline"
						dangerouslySetInnerHTML={{__html: fieldDescription}}
					/>
				}
			/>
			<Exist when={error}>
				<FormHelperText error>{error}</FormHelperText>
			</Exist>
		</FormGroup>
	);
};
