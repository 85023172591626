import {ApolloError} from "@apollo/client";
import {ModalType} from "data/enums";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {first} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";

interface IInit {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface ICreateLeagueController extends ViewController<IInit> {
	i18n: ILocalizationStore;

	isInviteStepActive: boolean;
	isCreateStepActive: boolean;

	get createdLeague(): ILeagueFragment | undefined;
	get startContest(): number | null;
	get scheduledContests(): IContestFragment[];

	goBack(): void;
	skipStep(): void;

	setStartRound(round: number): void;
	createLeague(payload: IAddPredictorLeagueMutationVariables): Promise<void>;
}

enum Steps {
	create,
	invite,
}

@injectable()
export class CreateLeagueController implements ICreateLeagueController {
	@observable private _activeStep = Steps.create;
	@observable private _createdLeague: ILeagueFragment | undefined;

	private _navigate!: IInit["navigate"];
	private _startContest: number | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.GameplayStore) private _gamePlayStore: IGameplayStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get isCreateStepActive() {
		return this._activeStep === Steps.create;
	}

	get isInviteStepActive() {
		return this._activeStep === Steps.invite;
	}

	get startContest() {
		return this._startContest;
	}

	get createdLeague() {
		return this._createdLeague;
	}

	get scheduledContests() {
		return this._gamePlayStore.scheduledContests;
	}

	setStartRound(round: number) {
		this._startContest = round;
	}

	@action
	async createLeague(payload: IAddPredictorLeagueMutationVariables): Promise<void> {
		try {
			const league = await this._leaguesStore.createLeague(payload);
			runInAction(() => {
				this._activeStep = Steps.invite;
				this._createdLeague = league;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "create league");
			this._modalsStore.showModal(ModalType.ERROR, {
				message: (err as ApolloError).message,
			});
		}
	}

	goBack() {
		this._navigate!("/leagues");
	}

	skipStep() {
		const league = this.createdLeague;

		if (league) {
			this._navigate!(`/leagues/${league.id}/about`);
		}
	}

	async init({navigate}: IInit) {
		this._navigate = navigate;
		await this._gamePlayStore.requestContestsSafety();

		const startContestId = first(this.scheduledContests)?.id;

		if (startContestId) {
			runInAction(() => {
				this._startContest = startContestId;
			});
		}
	}

	dispose(): void {
		return;
	}
}
