import React, {Fragment, useMemo} from "react";
import {ICustomFieldProps} from "data/types/global";
import {Dropdown} from "views/components/forms/custom_fields/dropdown.component";
import {Checkbox} from "views/components/forms/custom_fields/checkbox.component";
import {Toggle} from "views/components/forms/custom_fields/toggle.component";
import {Phone} from "views/components/forms/custom_fields/phone.component";
import {Text} from "views/components/forms/custom_fields/text.component";
import {Numeric} from "views/components/forms/custom_fields/numeric.component";
import {DateTimeComponent as DateTime} from "views/components/forms/custom_fields/datetime.component";

export const CustomField: React.FC<ICustomFieldProps> = (props) => {
	const Component = useMemo(
		() =>
			({
				Dropdown,
				Checkbox,
				Toggle,
				Phone,
				Text,
				Numeric,
				DateTime,
				Range: null,
			}[props.type] || null),
		[props]
	);

	return Component ? <Component {...props} /> : <Fragment />;
};
