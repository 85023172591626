import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Modal, Stack} from "@mui/material";
import {ModalType} from "data/enums";
import {useViewController} from "data/services/locator/locator_hook.service";
import {isEqual} from "lodash";
import {observer} from "mobx-react";
import React from "react";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {
	ModalBox,
	ModalContent,
	ModalTitle,
	ModalDescription,
} from "views/components/modals/modal_common.component";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {Bindings} from "data/constants/bindings";

export const ModalLeagueJoinedComponent: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {visibleModal, i18n, close, modalContent} = controller;
	const isVisible = isEqual(visibleModal, ModalType.LEAGUE_JOINED);

	const leagueName = modalContent?.message || "";

	if (!isVisible) {
		return null;
	}

	return (
		<Modal open={isVisible} onClose={close}>
			<ModalBox>
				<ModalContent>
					<Stack direction={"column"} alignItems={"center"}>
						<CheckCircleIcon color={"success"} fontSize={"large"} sx={{mt: 2, mb: 2}} />

						<ModalTitle>{i18n.t("modal.league.joined_title", "YOU’RE IN!")}</ModalTitle>

						<ModalDescription>
							{i18n.t(
								"modal.league.joined_message",
								`You have successfully joined ${leagueName}.`,
								{
									X: leagueName,
								}
							)}
						</ModalDescription>

						<ButtonPrimary variant="contained" onClick={close}>
							{i18n.t("modal.buttons.close", "Close")}
						</ButtonPrimary>
					</Stack>
				</ModalContent>
			</ModalBox>
		</Modal>
	);
});
