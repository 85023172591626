import {retryFailLoad} from "data/utils";
import React, {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {ContestRoot} from "views/components/contest/contest_root/contest_root.component";
import {MainLayout} from "views/components/layouts/main_layout.component";
import {FooterOnlyLayout} from "views/components/layouts/footer_only_layout.component";
import {LeaguePreloader} from "views/components/leagues/league_preloader/league_preloader.component";
import {NotAuthOnlyRoute} from "views/components/route/not_auth_only_route.component";
import {PrivateRoute} from "views/components/route/private_route.component";

const Home = lazy(retryFailLoad(() => import("views/pages/home/home.page")));
const Landing = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const Prizes = lazy(retryFailLoad(() => import("views/pages/prizes/prizes.page")));
const Standings = lazy(retryFailLoad(() => import("views/pages/standings/standings.page")));

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const LeaguesToJoin = lazy(
	retryFailLoad(() => import("views/pages/leagues_to_join/leagues_to_join.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueTable = lazy(retryFailLoad(() => import("views/pages/league_table/league_table.page")));
const LeagueInvitePage = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.page"))
);
const Contest = lazy(retryFailLoad(() => import("views/pages/contest/contest.page")));
const ContestResult = lazy(
	retryFailLoad(() => import("views/pages/contest_result/contest_result.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const Unsubscribed = lazy(
	retryFailLoad(() => import("views/pages/unsubscribed/unsubscribed.page"))
);

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<NotAuthOnlyRoute />}>
			<Route element={<FooterOnlyLayout />}>
				<Route index element={<Landing />} />
				<Route path="verify" element={<Landing />} />
				<Route path="registration" element={<Landing />} />
				<Route path="login" element={<Landing />} />
				<Route path="forgot-password" element={<Landing />} />
			</Route>
		</Route>
		<Route path="/*" element={<MainLayout />}>
			<Route element={<PrivateRoute />}>
				<Route path="dashboard" element={<Home />} />
				<Route path="contest/:id" element={<ContestRoot />}>
					<Route index element={<Contest />} />
					<Route path="result" element={<ContestResult />} />
				</Route>
				<Route path="leagues">
					<Route index element={<MyLeagues />} />
					<Route path="create" element={<CreateLeague />} />
					<Route path="join" element={<LeaguesToJoin />}>
						<Route path=":code" element={<LeaguesToJoin />} />
					</Route>
					<Route path=":id" element={<League />}>
						<Route element={<LeaguePreloader />}>
							<Route path="table" element={<LeagueTable />} />
							<Route path="invites" element={<LeagueInvitePage />} />
							<Route path="about" element={<LeagueSettings />} />
							<Route path="*" element={<Navigate to="about" replace />} />
						</Route>
					</Route>
				</Route>
				<Route path="standings">
					<Route index element={<Standings />} />
					<Route path=":contestID" element={<Standings />} />
				</Route>
				<Route path="my-account" element={<MyAccount />} />
			</Route>
			<Route path="prizes" element={<Prizes />} />
			<Route path="help/*" element={<Help />} />
			<Route path="successfully-unsubscribed" element={<Unsubscribed />} />
			<Route path="*" element={<NotFound />} />
		</Route>
	</Routes>
);

export default RootRoutes;
