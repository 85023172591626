import {inject, injectable} from "inversify";
import {type IHttpClientService} from "data/services/http";
import {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {LOCO_JSON_URL, STATIC_CONTENT_JSON_URL} from "data/constants";
import type {
	ISections,
	ICategories,
	IArticles,
} from "data/stores/static_content/static_content.store";

export interface IJSONProvider {
	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;

	helpCategories(locale: string): Promise<AxiosResponse<ICategories>>;

	helpSections(locale: string): Promise<AxiosResponse<ISections>>;

	helpArticles(locale: string): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(@inject(Bindings.JsonHTTPClient) private _http: IHttpClientService) {}

	translations = (locale: string) =>
		this._http.get<Record<string, unknown>>(
			`locale/${locale}.json`,
			{},
			{
				baseURL: LOCO_JSON_URL,
			}
		);

	helpCategories = (locale: string) =>
		this._http.get<ICategories>(
			`${locale}/categories.json`,
			{},
			{
				baseURL: STATIC_CONTENT_JSON_URL,
			}
		);

	helpSections = (locale: string) =>
		this._http.get<ISections>(
			`${locale}/sections.json`,
			{},
			{
				baseURL: STATIC_CONTENT_JSON_URL,
			}
		);

	helpArticles = (locale: string) =>
		this._http.get<IArticles>(
			`${locale}/articles.json`,
			{},
			{
				baseURL: STATIC_CONTENT_JSON_URL,
			}
		);
}
