import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

export * from "./AwsAmplifyError";
export * from "./GraphQLError";

export const GAME_NAME = process.env.REACT_APP_GAME_NAME || "";
export const GAME_LOCALE = process.env.REACT_APP_GAME_LOCALE || "";

export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const LOCO_JSON_URL = process.env.REACT_APP_LOCO_JSON_URL || "";
export const STATIC_CONTENT_JSON_URL = process.env.REACT_APP_STATIC_CONTENT_JSON_URL || "";
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL || "";
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "uat";
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "development";
export const SITE_URL = window.location.origin || "";
export const SHARE_PATH = process.env.REACT_APP_SHARE_PATH || "";
export const SKIP_REDIRECT_BY_LOCALE = Boolean(
	JSON.parse(process.env.REACT_APP_SKIP_REDIRECT_BY_LOCALE || "false")
);

export const PRIZE_ARTICLE_ID = parseInt(process.env.REACT_APP_PRIZE_ARTICLE_ID || "");

export const GAME_ID = Number(process.env.REACT_APP_GAME_ID || "");
export const COMMON_LOCALE = process.env.REACT_APP_COMMON_LOCALE || "en";

export const SKIP_TRANSLATIONS =
	new URLSearchParams(window.location.search).get("skipTranslation") === "true";

export const CONTEST_ROUTES = [{path: "/contest/:id"}, {path: "/contest/:id/result"}];

export const EMAIL_REGEXP_STR = "\\S+@\\S+\\.\\S+";
export const PASSWORD_REGEXP_STR =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const FORM_VALIDATION_ELEMENT_CLASSNAME = "form-field-validate" as const;
export const COGNITO_CUSTOM_FIELD_NAME = "custom:custom_fields" as const;

type Links = "terms" | "privacy" | "twitter" | "facebook" | "instagram" | "youtube" | "tickToc";

const links = {
	terms: "/help/rules_and_regulations",
	privacy: "https://www.therams.com/privacy-policy",
	twitter: "https://twitter.com/RamsNFL",
	facebook: "https://www.facebook.com/Rams/",
	instagram: "https://www.instagram.com/rams",
	youtube: "https://www.youtube.com/c/LARams/featured",
	tickToc: "https://www.tiktok.com/@rams",
};

export const FOOTER_LINKS: Record<string, Record<Links, string>> = {
	"en-US": links,
	"en-AU": {
		...links,
		instagram: "https://www.instagram.com/laramsaustralia/",
	},
	"es-MX": {
		...links,
		twitter: "https://twitter.com/VamosRams",
		facebook: "https://www.facebook.com/VamosRamsNFL",
		instagram: "https://www.instagram.com/vamosramsnfl/",
	},
};

export const AWS_AMPLIFY_CONFIG = {
	Auth: {
		region: process.env.REACT_APP_AWS_REGION ?? "",
		identityPoolRegion: process.env.REACT_APP_AWS_POOL_REGION ?? "",
		userPoolId: process.env.REACT_APP_AWS_USER_ID_POOL ?? "",
		userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID ?? "",
		authenticationFlowType: "USER_PASSWORD_AUTH" as const,
	},
};

export const SENTRY_CONFIG = {
	dsn: "https://8a6857c7128d42c887b5daf00c65846b@o151969.ingest.sentry.io/6631999",
	integrations: [
		new BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	environment: SENTRY_ENV,
	allowUrls: ["*.geniussports.com", "*.ramspicks.com"],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	sampleRate: 0.1,
};

export const IS_SECRET_ENABLED = Boolean(
	JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false")
);
