import {flatMap, isEqual, sum} from "lodash";
import type {IQuestionWithAnswers} from "data/stores/gameplay/gameplay.store";

type TContestStatusKey = "SCHEDULED" | "ACTIVE" | "COMPLETE";

/**
 * @inheritDoc https://geniussports.atlassian.net/wiki/spaces/GSM/pages/3938484727/Contest+Status+Flow
 * @inheritDoc https://geniussports.atlassian.net/wiki/spaces/GSM/pages/3882942870/F2P+PP+-+Status+flows#Contest-Status
 */
const ContestStatus: Record<TContestStatusKey, ContestStatus> = {
	SCHEDULED: "Scheduled",
	ACTIVE: "Active",
	COMPLETE: "Completed",
};

const {SCHEDULED, ACTIVE, COMPLETE} = ContestStatus;
const oneDay = 1000 * 60 * 60 * 24;
const oneWeek = oneDay * 7;

export abstract class ContestUtils {
	static status = ContestStatus;

	static isLocksInOneDay = (contest: IContestFragment) => {
		const maxDate = Date.now() + oneDay;
		const startDate = new Date(contest.dateStart).getTime();
		return ContestUtils.isScheduled(contest) && startDate <= maxDate;
	};

	static isLocksAfterOneDay = (contest: IContestFragment) => {
		const maxDate = Date.now() + oneDay;
		const startDate = new Date(contest.dateStart).getTime();
		return ContestUtils.isScheduled(contest) && startDate > maxDate;
	};

	/**
	 * All active and scheduled contests that have a start date of TODAY + 7 days
	 */
	static isCurrent = (contest: IContestFragment) => {
		const maxDate = Date.now() + oneWeek;
		const {isScheduled, isActive} = ContestUtils;
		const isPlayable = isScheduled(contest) || isActive(contest);
		const startDate = new Date(contest.dateStart).getTime();
		const isStartDuringWeek = startDate < maxDate;

		return isPlayable && isStartDuringWeek;
	};

	/**
	 * All others scheduled contests that do not fall in CURRENT (all scheduled contests greater than 7 days out)
	 */
	static isUpcoming = (contest: IContestFragment) => {
		const minDate = Date.now() + oneWeek;
		const startDate = new Date(contest.dateStart).getTime();
		const isStartAfterWeek = startDate >= minDate;

		return ContestUtils.isScheduled(contest) && isStartAfterWeek;
	};

	static isPast = ({status}: IContestFragment) => [COMPLETE].includes(status);

	static isActual = ({status}: IContestFragment) => [SCHEDULED, ACTIVE].includes(status);

	static isScheduled = ({status}: IContestFragment) => isEqual(SCHEDULED, status);

	static isActive = ({status}: IContestFragment) => isEqual(ACTIVE, status);

	static isComplete = ({status}: IContestFragment) => isEqual(COMPLETE, status);

	static getTotalPoints = (questions: IQuestionWithAnswers[]) => {
		const allPoints = flatMap(questions, ({answers}) =>
			flatMap(answers, ({points}) => Number(points))
		);

		return sum(allPoints);
	};
}
