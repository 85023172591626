import {ApolloError} from "@apollo/client";
import {RequestState, ModalType, LeagueType} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {RefObject} from "react";
import {throttle} from "lodash";
import {Bindings} from "data/constants/bindings";
import {getIsInViewPort} from "data/utils/scroll";
import {trackSentryErrors} from "data/utils";

interface IInit {
	navigate: ReturnType<typeof useNavigate>;
	loadMoreAnchorRef?: RefObject<HTMLDivElement>;
	code?: string;
}

export interface ILeaguesToJoinController extends ViewController<IInit> {
	i18n: ILocalizationStore;

	get isLoaded(): boolean;
	get isEmptyLeagues(): boolean;
	get leaguesToJoin(): ILeagueFragment[];
	get isLoadMore(): boolean;

	joinWhenNotJoined(leagueCode: string, leagueId: number): Promise<void>;
	goToCreateLeague(): void;
	goToLeague(leagueId: number): void;
	isLeagueJoined(leagueId: number): boolean;
	getLeaguesByQuery(value: string): void;
	getLeagueByCode(code: string): ILeagueFragment | undefined;
	increasePageNumber(): void;
	clearCodeFromQuery(): void;

	calculatePageNumber(): void;
	fetchLeagues(query?: string): Promise<void>;
}

@injectable()
export class LeaguesToJoinController implements ILeaguesToJoinController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _filterByType: LeagueType = LeagueType.ALL;
	private _pageNumber = 0;

	private _pageSize = 20;
	private _query = "";

	private _navigate!: IInit["navigate"];
	private _loadMoreAnchorRef: IInit["loadMoreAnchorRef"];

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoaded() {
		return [RequestState.SUCCESS, RequestState.ERROR].includes(this._requestState);
	}

	get leaguesToJoin() {
		return this._leaguesStore.joinLeagues.leagues;
	}

	get isEmptyLeagues() {
		return !this._leaguesStore.joinLeagues.leagues.length && this.isLoaded;
	}

	get isLoadMore(): boolean {
		return !!this._leaguesStore.joinLeagues?.isNextPage && this.isLoaded;
	}

	public increasePageNumber(): void {
		this._pageNumber = this._pageNumber + 1;
		void this.fetchLeagues(this._query);
	}

	public async fetchLeagues(query?: string) {
		this._requestState = RequestState.PENDING;

		try {
			await this._leaguesStore.fetchLeaguesToJoin({
				query: query || "",
				pageSize: this._pageSize,
				pageNumber: this._pageNumber,
			});

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "leagues to join - fetch leagues");
			this._modalsStore.showModal(ModalType.ERROR, {
				message: (err as ApolloError).message,
			});
			this._requestState = RequestState.ERROR;
		}
	}

	async _join(leagueCode: string) {
		try {
			const league = await this._leaguesStore.joinLeague(leagueCode);
			this._modalsStore.showModal(ModalType.LEAGUE_JOINED, {
				message: league?.name || "",
			});
		} catch (err) {
			trackSentryErrors(err, {}, "leagues to join - join");
			this._modalsStore.showModal(ModalType.ERROR, {
				message: (err as ApolloError).message,
			});
		}
	}

	public async getLeaguesByQuery(value: string) {
		this._leaguesStore.clearJoinLeagues();
		await this.fetchLeagues(value);
	}

	public isLeagueJoined(id: number): boolean {
		return this._leaguesStore.isLeagueJoined(id);
	}

	public goToCreateLeague() {
		this._navigate("/leagues/create");
	}

	public goToLeague(leagueId: number) {
		this._navigate(`/leagues/${leagueId}/about`);
	}

	async joinWhenNotJoined(leagueCode: string, leagueId: number) {
		if (!this.isLeagueJoined(leagueId)) {
			await this._join(leagueCode);
		}
	}

	public getLeagueByCode(leagueCode: string) {
		return this.leaguesToJoin.find((league) => league.code === leagueCode);
	}

	public clearCodeFromQuery() {
		this._navigate("/leagues/join");
	}

	calculatePageNumber() {
		const anchor = this._loadMoreAnchorRef?.current;
		if (this.isLoadMore) {
			const isInViewPort = getIsInViewPort(anchor);
			if (isInViewPort) {
				this.increasePageNumber();
			}
		}
	}

	get _loadMoreDebounce() {
		return throttle(() => this.calculatePageNumber(), 500);
	}

	_addScrollEvent() {
		window.addEventListener("scroll", this._loadMoreDebounce);
	}

	_removeScrollEvent() {
		window.removeEventListener("scroll", this._loadMoreDebounce);
	}

	async init(params: IInit) {
		this._navigate = params.navigate;
		this._loadMoreAnchorRef = params.loadMoreAnchorRef;

		await this.fetchLeagues(params.code);

		this._addScrollEvent();
	}

	dispose(): void {
		this._leaguesStore.clearLeagueUsers();
		this._leaguesStore.clearJoinLeagues();
		this._removeScrollEvent();
	}
}
