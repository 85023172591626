export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum NetworkErrorCodes {
	UNAUTHENTICATED = 401,
	NOT_FOUND = 404,
}

export enum ModalType {
	LOGIN,
	REGISTRATION,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_CONFIRM,
	RESET_PASSWORD,
	ERROR,
	LEAGUE_JOINED,
	LEAGUE_REMOVE_USER,
	LEAGUE_LEAVE,
	LEAGUE_REMOVE,
	PASSWORD_CHANGED,
	ACCOUNT_UPDATED,
}

/**
 * @inheritDoc https://geniussports.atlassian.net/wiki/spaces/GSM/pages/3882942870/F2P+PP+-+Status+flows#Publication-Status
 */
export enum PublishStatus {
	DRAFT = "Draft",
	PUBLISHED = "Published",
	VOIDED = "Voided",
}

export enum SocialNetwork {
	FACEBOOK,
	TWITTER,
	MOBILE,
}

export enum ShareType {
	LEAGUE = "league",
	CONTEST = "contest",
}

export enum LeagueType {
	ALL = "all",
	PRIVATE = "Private",
	PUBLIC = "Public",
}

export enum LeagueStatus {
	SCHEDULED = "Scheduled",
	COMPLETED = "Completed",
	ACTIVE = "Active",
}

export enum HelpTabs {
	HOW_TO_PLAY,
	FAQ,
	CONTACT_US,
	TERMS_AND_CONDITIONS,
}

export enum TranslationJSONMockData {
	ExistKey = "translation.test.mock",
	ExistValue = "Test Translation",
	NonExistKey = "translation.test.mock_not_exist",
	NonExistValue = "Translation that does not exist",
}
