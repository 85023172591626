import {RequestState} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";

interface IInit {
	leagueId: number;
}

export interface ISettingsLeagueController extends ViewController<IInit> {
	i18n: ILocalizationStore;

	get league(): ILeagueFragment | undefined;
	get isCommissioner(): boolean;
	get userId(): string | undefined;
}

@injectable()
export class SettingsLeagueController implements ISettingsLeagueController {
	private _league: ILeagueFragment | undefined;

	@observable _requestStateLeave: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get league(): ILeagueFragment | undefined {
		return this._league;
	}

	get isCommissioner(): boolean {
		return this.userId === this.league?.author?.id;
	}

	get userId(): string | undefined {
		return this._userStore.user?.id;
	}

	init(params: IInit) {
		this._league = this._leaguesStore.getLeagueById(params.leagueId);
	}

	dispose(): void {
		return;
	}
}
