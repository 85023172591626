import game_logo_us from "assets/img/game_logo.png";
import game_logo_au from "assets/img/game_logo_au.png";
import game_logo_mx from "assets/img/game_logo_mx.png";
// import game_logo_inverted_us from "assets/img/game_logo_inverted.png";
import {GAME_NAME} from "data/constants";

export const getLogoByGame = (props?: {inverted: boolean}) => {
	if (props?.inverted) {
		return {
			RAMS: game_logo_us,
			RAMS_MX: game_logo_mx,
			RAMS_AU: game_logo_au,
		}[GAME_NAME];
	}
	return {
		RAMS: game_logo_us,
		RAMS_MX: game_logo_mx,
		RAMS_AU: game_logo_au,
	}[GAME_NAME];
};
