import React, {Fragment, ReactNode} from "react";
import {PagePreloader} from "views/components/preloader/preloader.component";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import {IGameGateController} from "views/components/game_gate/game_gate.controller";
import {Bindings} from "data/constants/bindings";

export const GameGate: React.FC<{children: ReactNode}> = observer(({children}) => {
	const {isSessionChecked} = useViewController<IGameGateController>(Bindings.GameGateController);
	return isSessionChecked ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
