import {ApolloError} from "@apollo/client";
import {RequestState, ModalType} from "data/enums";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IStandingsStore} from "data/stores/standings/standings.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {isEqual, gte, last, first} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ContestUtils} from "data/utils/contest_utils";
import {trackSentryErrors} from "data/utils";

interface IControllerProps {
	leagueId: InputMaybe<number>;
	contestID?: number;
}

export interface IStandingsController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;

	get requestState(): RequestState;

	get selectedContestId(): InputMaybe<number>;

	get ladder(): IRanksFragment;

	get leagueContests(): IContestFragment[];

	get isLoadMore(): boolean;

	get isLoading(): boolean;

	get isPreSeasonState(): boolean;

	get isLoadingLadder(): boolean;

	get isLadderEmpty(): boolean;

	get isOverallPoints(): boolean;

	get userRank(): IRankUserFragment | undefined;

	get league(): ILeagueFragment | undefined;

	get pageSize(): number;

	get userStoreId(): string | undefined;

	get canShowOwnUserRow(): boolean;

	isUserInCurrentList(): boolean;

	isOwnUser(userId?: string): boolean;

	setContestId(contestId: number): Promise<void>;

	increasePageNumber(): Promise<void>;

	getRankData(user?: IRankUserFragment): {points: number; rank: number};
}

@injectable()
export class StandingsController implements IStandingsController {
	@observable private _leagueId: InputMaybe<number> = null;
	@observable private _contestId: InputMaybe<number> = null;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _requestLadderState: RequestState = RequestState.IDLE;
	@observable private _pageNumber = 0;
	private _pageSize = 20;

	get requestState() {
		return this._requestState;
	}

	get pageSize() {
		return this._pageSize;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isLoadingLadder() {
		return [RequestState.PENDING, RequestState.IDLE].includes(this._requestLadderState);
	}

	get isOverallPoints() {
		return !this._contestId;
	}

	get isLadderEmpty() {
		return !this.ladder?.ranks.length && !this.isLoadingLadder && !this.isPreSeasonState;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StandingsStore) public readonly _standingsStore: IStandingsStore,
		@inject(Bindings.ModalsStore) public readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public readonly _userStore: IUserStore,
		@inject(Bindings.GameplayStore) public readonly _gamePlayStore: IGameplayStore,
		@inject(Bindings.LeaguesStore) public readonly _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	@action async setContestId(contestId: number) {
		const isContestOfLeague =
			!contestId || this.leagueContests.find((contest) => contestId === contest.id);

		if (this._contestId !== contestId && isContestOfLeague) {
			this._standingsStore.clear();
			this._pageNumber = 0;
			this._contestId = contestId;
			await this._fetchLadder(this._leagueId, this._contestId);
		}
	}

	get ladder(): IRanksFragment {
		if (!this._leagueHasRanks) {
			return this._buildLadderFromUsers;
		}

		return this._standingsStore.ladder;
	}

	isUserInCurrentList() {
		if (!this.ladder.ranks.length) {
			return false;
		}
		return Boolean(this.ladder.ranks.find((rank) => this.isOwnUser(rank.user.id)));
	}

	get userRank(): IRankUserFragment | undefined {
		if (this.isUserInCurrentList()) {
			return this.ladder.ranks.find((rank) => this.isOwnUser(rank.user.id));
		}

		return this._standingsStore.userRank;
	}

	get gameContests(): IContestFragment[] {
		return [...this._gamePlayStore.pastContests, ...this._gamePlayStore.activeContests];
	}

	get leagueContests() {
		const contests = this.gameContests;
		const league = this.league;

		if (league && league.startContest) {
			return contests.filter((contest) => gte(contest.id, league.startContest?.id));
		}

		return contests;
	}

	get isPreSeasonState(): boolean {
		return !this.leagueContests.length && !this.isLoadingLadder;
	}

	get league(): ILeagueFragment | undefined {
		if (this._leagueId) {
			return this._leaguesStore.getLeagueById(this._leagueId);
		}
	}

	get userStoreId(): string | undefined {
		return this._userStore.user?.id;
	}

	get selectedContestId(): InputMaybe<number> {
		return this._contestId;
	}

	get isLoadMore(): boolean {
		return !!this.ladder?.isNextPage;
	}

	get canShowOwnUserRow(): boolean {
		return Boolean(!this.isUserInCurrentList() && this.userRank);
	}

	private get _defaultContestId() {
		return last(this.leagueContests)?.id ?? null;
	}

	private get _leagueHasRanks() {
		const contest = first(this.leagueContests);

		if (contest) {
			return ContestUtils.isPast(contest);
		}

		return false;
	}

	private get _buildLadderFromUsers(): IRanksFragment {
		return {
			isNextPage: this._leaguesStore.leagueUsers.isNextPage,
			pages: this._leaguesStore.leagueUsers.pages,
			ranks: this._leaguesStore.leagueUsers.users.map((user) => ({
				contestRank: 0,
				contestPoints: 0,
				overallPoints: 0,
				overallRank: 0,
				contest: {id: this._defaultContestId || 0},
				user,
			})),
		};
	}

	private get _isUsersModeEnabled() {
		return this._leagueId !== null && !this._leagueHasRanks;
	}

	isOwnUser(userId?: string): boolean {
		return this.userStoreId === userId;
	}

	@action async increasePageNumber() {
		this._pageNumber = this._pageNumber + 1;

		await this._fetchLadder(this._leagueId, this._contestId);
	}

	@action dispose(): void {
		this._standingsStore.clear();
		this._leaguesStore.clearLeagueUsers();
		this._contestId = this._defaultContestId;
		this._pageNumber = 0;
	}

	@action
	async init({leagueId, contestID}: IControllerProps) {
		this._leagueId = leagueId;

		try {
			this._requestState = RequestState.PENDING;

			await this._gamePlayStore.requestContestsSafety();

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});

			runInAction(() => {
				this._contestId = contestID ?? this._defaultContestId;
			});

			await this._fetchLadder(leagueId, this._contestId);
		} catch (err) {
			trackSentryErrors(err, {}, "fetch standings");
			this._modalsStore.showModal(ModalType.ERROR, {
				message: (err as ApolloError).message,
			});
		}
	}

	@action
	async _fetchLadder(leagueId: IControllerProps["leagueId"], contestId: InputMaybe<number>) {
		this._requestLadderState = RequestState.PENDING;

		try {
			if (this._isUsersModeEnabled) {
				await this._fetchUsers(leagueId!);
			} else {
				await this._standingsStore.getLadder({
					leagueId,
					contestId: contestId === 0 ? null : contestId,
					pageSize: this._pageSize,
					pageNumber: this._pageNumber,
				});

				if (!this.isUserInCurrentList() && this._pageNumber === 0) {
					await this._fetchUserRank(leagueId, contestId);
				}
			}

			runInAction(() => {
				this._requestLadderState = RequestState.SUCCESS;
			});
		} catch (err) {
			trackSentryErrors(err, {}, "standings fetch ladder");
			this._onCatchError(err);
		}
	}

	@action
	async _fetchUsers(leagueId: number) {
		await this._leaguesStore.fetchLeagueUsers(leagueId, this._pageSize, this._pageNumber);
	}

	@action
	async _fetchUserRank(leagueId: IControllerProps["leagueId"], contestId: InputMaybe<number>) {
		await this._standingsStore.getUserRank({
			leagueId,
			contestId: contestId === 0 ? null : contestId,
		});
	}

	@action _onCatchError(err: unknown) {
		trackSentryErrors(err, {}, "standings error handler");
		this._requestLadderState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: (err as ApolloError).message,
		});
	}

	getRankData(user?: IRankUserFragment) {
		if (!user) {
			return {
				points: 0,
				rank: 0,
			};
		}

		if (this.isOverallPoints) {
			return {
				points: user.overallPoints,
				rank: user.overallRank,
			};
		}

		return {
			points: user.contestPoints,
			rank: user.contestRank,
		};
	}
}
