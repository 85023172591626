import {get, isEqual} from "lodash";
import {GraphQLErrors} from "@apollo/client/errors";
import {NetworkErrorCodes} from "data/enums";

export abstract class GraphQLError {
	static codes = NetworkErrorCodes;

	static hasError(errors: GraphQLErrors = [], errorCode: NetworkErrorCodes) {
		return errors?.some((err) => isEqual(get(err.extensions, "debugInfo.code", 0), errorCode));
	}

	static isNotAuthorized(errors: GraphQLErrors = []) {
		return GraphQLError.hasError(errors, GraphQLError.codes.UNAUTHENTICATED);
	}

	static isNotFound(errors: GraphQLErrors = []) {
		return GraphQLError.hasError(errors, GraphQLError.codes.NOT_FOUND);
	}
}
